.pageScreenContainer {
    .myPage {
        .myPaymentHistory {
            .paymentItemWrapper {
                padding-bottom: 25px;

                .paymentHistoryItem {
                    background: #ffffff;
                    border: 1px solid rgba(220, 220, 220, 1);
                    border-radius: 10px;

                    .paymentDesc {
                        padding: 15px 25px;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        @media only screen and (max-width: 639px) {
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: flex-start;
                        }

                        .item {

                            @media only screen and (max-width: 639px) {
                                width: 100%;
                                display: inline-block;
                            }

                            .heading {
                                color: #000080;
                                font-family: "Mulish", Arial, Helvetica, sans-serif;
                                font-weight: 500;
                                font-style: normal;
                                font-size: 20px;
                                line-height: 40px;
                                display: block;

                                @media only screen and (max-width: 639px) {
                                    display: inline-block;
                                    vertical-align: middle;
                                    margin-right: 15px;
                                }

                                @media only screen and (max-width: 576px) {
                                    font-size: 18px;
                                }
                            }

                            .amountHeading {
                                color: #000080;
                                font-family: "Mulish", Arial, Helvetica, sans-serif;
                                font-weight: 500;
                                font-style: normal;
                                font-size: 30px;
                                line-height: 40px;
                                display: block;

                                @media only screen and (max-width: 639px) {
                                    display: inline-block;
                                    vertical-align: middle;
                                    margin-right: 15px;
                                }

                                @media only screen and (max-width: 576px ) {
                                    font-size: 24px;
                                }
                            }

                            .description {
                                color: #707070;
                                font-family: "Mulish", Arial, Helvetica, sans-serif;
                                font-weight: 400;
                                font-style: normal;
                                font-size: 16px;
                                line-height: 26px;
                                display: block;

                                @media only screen and (max-width: 639px) {
                                    display: inline-block;
                                    vertical-align: middle;
                                }

                                @media only screen and (max-width: 639px) 
                                {
                                    display: inline-block;
                                    vertical-align: middle;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}