
.tier{
    cursor: pointer;
            list-style: none;
            list-style-type: none;
            margin: 0px 30px;
            display: inline-block;

    @media only screen and (max-width: 575px) {
        
    }

    ul.tier_list { 
     
        li.item {
            cursor: pointer;
            list-style: none;
            list-style-type: none;
            margin: 0px 20px;
            text-transform: uppercase;
            display: inline-block;
            color: #00898b;
            @media only screen and (max-width: 470px) {
                li.item{
                   cursor: pointer;
                   background-color: #ff9933;
                   border-radius: 10px;
                   color: #ffffff;
                }
                .tiers {
                    padding: 15px;
                }
              }
            @media only screen and (max-width: 767px) {
                margin: 0px 20px;
            }

            @media only screen and (max-width: 660px) {
                margin: 0px 13px;
            }

            @media only screen and (max-width: 575px) {
                margin: 0px 20px;
            }

            &::after {
                content:'';
                display: block;
                width: 0;
                height: 4px;
                background: #ff9933;
                transition: all ease .3s;
                opacity: 0;
                @media only screen and (max-width: 470px) {
                  display: none;
                }
            }

            &:hover {
                &::after {
                    /* width: 100%; */
                }
            }

            &.active {
                &::after {
                    width: 100%;
                    opacity: 1;
                }
            }
              .active span::after {
                display: inline-block;
                text-decoration: none;
                color: #000080;
                font-family: "Mulish", Arial, Helvetica, sans-serif;
                

                @media only screen and (max-width: 991px) {
                    font-size: 20px;
                    line-height: 35px;
                }

                @media only screen and (max-width: 767px) {
                    font-size: 19px;
                    line-height: 35px;
                }

                @media only screen and (max-width: 650px) {
                    font-size: 17px;
                    line-height: 30px;
                }

                @media only screen and (max-width: 575px) {
                    margin: 0px 23px;
                    font-size: 18px;
                    line-height: 50px;
                }
            }
        }
    }
}


.tiers{
    width: 100%;
    margin-top: 50px;
    background-color: #0c2834;
    padding: 24px;
}
.specific{
    text-align: right;
}
.specific button{
    background-color: #ff9933;
}
.specific h1{
    color: #00898b;
    margin-bottom: 0;
    
}
.specific p{
    color: #ffffff;
    margin-top: 24px; 
    font-size: 14px; 
    padding-left: 10px; 
}

.cgl_tier{
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 1px 0px 12px 0px rgba(158, 157, 157, 0.75);
    -webkit-box-shadow: 1px 0px 12px 0px rgba(158, 154, 154, 0.75);
     -moz-box-shadow: 1px 0px 12px 0px rgba(158, 158, 158, 0.75);
}
.cgl_tier p{
 line-height: 10px;
 font-size: 14px;
}


// subject wise
.subject{
    margin-top: 40px;
    padding: 0px 15px;
    border-radius: 10px;
    box-shadow: 1px 0px 12px 0px rgba(158, 157, 157, 0.75);
    -webkit-box-shadow: 1px 0px 12px 0px rgba(158, 154, 154, 0.75);
     -moz-box-shadow: 1px 0px 12px 0px rgba(158, 158, 158, 0.75);
}

.subject h1{
    color: #000080;
    font-weight: bold;
    margin-left: -16px !important;
}
.subject .subject_para {
    font-family: "Mulish", Arial, Helvetica, sans-serif;
    font-size: 19px;
    margin: auto;
    border-left: 2px solid #ff9933;
                     
}
.logical_Reas{
    padding: 0px 20px;
}
.logical_Reas h4{
   color: #000080;
}
.logical_Reas p{
   font-size: 16px;
   line-height: 18px;
}
.subject_wise button{
    background-color: #ff9933;
}

.getstart{
    width: 300px;
    height: 100px;
    margin: 20px;  
}
.getstart h1{
     color: #000080;
}
        .prents{
            height: auto;
            margin: auto;
            flex-wrap: wrap;
            display: flex;
            justify-content: space-between;
        }
        .child{
            width: 168px;
            height: 100px;
            margin: 21px 34px;
            border-radius: 10px;
            box-shadow: 1px 0px 12px 0px rgba(158, 157, 157, 0.75);
            -webkit-box-shadow: 1px 0px 12px 0px rgba(158, 154, 154, 0.75);
             -moz-box-shadow: 1px 0px 12px 0px rgba(158, 158, 158, 0.75);
      
        }
    
    .child h5{
         text-align: center;
         text-transform: uppercase;
         line-height: 20px;
         margin-top: 5px;
     }
     .child p{
         text-transform: capitalize;
         text-align: center;
         line-height: 0px !important;
         font-size: 14px;
     }
     .Landingpage_para{
            width: 35%;
            margin-left: 40px;
     }
     .Landingpage_para p{
         font-size: 12px;
       margin-bottom: 0px;
       line-height: 20px;
       margin-left: 40px;
     }
     
   @media screen and (max-width:768px) {
       .getstart h1{
           font-size: 20px;
           margin-bottom: 10px;
       }
       .Landingpage_para{
        width: 100%;
        margin: 0px;
 }
 .tier_list ul::after{
     display: none;
 }
 .tier_list ul li::after{
     display: none;
 }
   }
   @media screen and (max-width:470px) {
       .tier li.active span {
         cursor: pointer;
         background-color: #ff9933;
         border-radius: 3px;
         color: #ffffff;
         padding: 0px 4px;
       }
       .tier{
        margin: 0px 0px; 
       }
      
       
    .getstart{
        width: auto;
        height: auto;
        display: flex;
        justify-content: center;
    }
    .prents{
        height: auto;
        margin: auto;
        flex-wrap: wrap;
        display: flex;
        justify-content: center;
        
    }
    .subject h1{
     font-size: 22px;
     margin-bottom: 0px;
    }
    .Landingpage_para p{
      margin-left: 0px;
    }
  .tier_list ul li{
      border: 2px solid #ff9933;
      padding: 15px;
  }
  
   }
   @media screen and(max-width:1024px) {
    .subject_wise .subject_para {
        border-left: 4px solid #ff9933;
        margin-bottom: 40px;
    }
   } 
    
@media screen and (max-width:808px) and(min-width:471px){
    .logical_Reas{
  
    } 
}


  @media only screen and (max-width: 480px) {
    .banners {
        width: 100%;
        height: 150px;
       
    }
  }