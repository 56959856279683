.Mocktest_mainscreen .ibps{
    margin-left: 20px;
}
.Mocktest_mainscreen h5{
    color: #000080;
    font-weight: 900;
    line-height: 20px;
    font-family: "Mulish", Arial, Helvetica, sans-serif;
}
.Mocktest_mainscreen span{
    font-size: 14px;
    font-family: "Mulish", Arial, Helvetica, sans-serif;
}

nav{
    box-shadow: 0px 3px 5px -1px rgba(99, 99, 99, 0.71);
    -webkit-box-shadow: 0px 3px 5px -1px rgba(102, 102, 102, 0.71);
    -moz-box-shadow: 0px 3px 5px -1px rgba(85, 85, 85, 0.71);
}
nav ul li{
    padding: 0px 20px;

}
nav ul li select{
   background-color: #ff9933;
   color: #fff;
   border-radius: 5px;
   padding: 0px 5px;
   margin: 0px 5px;
}
nav ul li span{
    border: 3px solid rgb(192, 191, 191);
    border-radius: 50%;
    border-right: 3px solid #ff9933;
    padding: 7px;
}




.center_div {
    padding: 0px 20px;
}

.paragraph{
    border-radius: 10px;
    padding: 0px 10px;
    height: 400px;
    box-shadow: 0px 2px 3px 0px rgba(112,103,103,0.75);
-webkit-box-shadow: 0px 2px 3px 0px rgba(112,103,103,0.75);
-moz-box-shadow: 0px 2px 3px 0px rgba(112,103,103,0.75);

}
.paragraph p{
    font-size: 14px;
    color: black;
    font-family: "Mulish", Arial, Helvetica, sans-serif;
    line-height: 24px;
    
}
 .center_div ul li{ 
     width: 161px;
    box-shadow: 0 0 20px #bac3c3;
    text-align: center;
    border-radius: 5px;
    margin: 10px 15px;
   
 }
 .center_div ul .active{ 
  background-color: #ff9933;
  color: white;
  
}

 
.heading_wraper{
    background: #F6F9FC 0% 0% no-repeat padding-box;
    opacity: 1;
}
.heading_wraper p{
     font-size: 14px;
}
//  .pageScreen.logoBg::after {
//     content: "";
//         background-image: url('../../../assets/images/page/logoBackground.png');
//         background-repeat: no-repeat;
//         background-attachment: fixed;
//         background-position:left;
//         background-color: #F8F8F8;
//         opacity: 0.10;
//         top: 20px;
//         left: 0;
//         bottom: 0;
//         right: 0;
//         position: absolute;
//         z-index: -1;
// }

.imgpop_up{
    width: 150px;
    padding: 1px;
    height: auto;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 30px 60px #00000029;
    border-radius: 8px;
    position: absolute;
    z-index: 1;
 p{
     margin-top: 5px;
     font-size: 14px;
     line-height: 10px;
 }
}

 .first_div{
    background: #F6F9FC 0% 0% no-repeat padding-box;
    padding-top: 10px;
    p{
   
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    padding-left: 10px;
    color: #000000;
    }

    .not_visit{
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        opacity: 1;
        width: 20px;
        height: 20px;
       border-radius: 50%;
    }
    .Marked_Review{
        width: 28px;
        height: 20px;
background: #000080 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #0000804D;
opacity: 1;
border-radius: 50%;
    }
    .Reviewed {
        width: 28px;
        height: 20px;
background: #FF0000 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #0000004D;
opacity: 1;
border-radius: 50%;

    }
 }
 .first_div h6{
     text-decoration: underline;
   margin-bottom: 0px;
   
 }

 .first_div .Answered{
    background: #1E9B38 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #22774C4D;
    opacity: 1;
    width: 20px;
    height: 20px;
    border-radius: 50%;
 }


 .answerWraper{
     padding: 0px 30px;
 }
 .answer{
    width: 34px;
    height: 34px;
    background: #000080 0% 0% no-repeat padding-box;
    opacity: 1;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 21px;
    margin: 5px 7px;
    border: 1px solid #707070;
    font-weight: 500;
    font: normal normal medium 34px/41px Roboto;

 }

 .answerg{
    width: 34px;
    height: 34px;
    background-color: green;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #707070;
    font-weight: 500;
    margin: 5px 7px;
    color: #fff;
    font-size: 21px;
    font: normal normal medium 34px/41px Roboto;
 }

 .answerwhite{
    width: 34px;
    height: 34px;
    background-color: white;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #707070;
    font-weight: 500;
    margin: 5px 7px;
    color: #707070;
    font-size: 21px;
    font: normal normal medium 34px/41px Roboto;
 }


 .answerred{
    width: 34px;
    height: 34px;
    background: #FF0000 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000004D;
    opacity: 1;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #707070;
    font-weight: 500;
    margin: 5px 7px;
    color: #fff;;
    font-size: 21px;
    font: normal normal medium 34px/41px Roboto;
 }
 .instraction button{
    border: 1px solid #FF9933;
    border-radius: 4px;
    opacity: 1;
    text-align: center;
font: normal normal 600;
letter-spacing: 0px;
color: #FF9933;
opacity: 1;
 }
 .sumbit button{
     margin-top: 20px;
    background: #FF9933 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    color: #fff;
    outline: none;
text-transform: uppercase;
 }


//  footers

.markreview{
    margin-top: 10px;
height: 50px;
background: #0A2540 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border-radius: 4px 4px 0px 0px;
opacity: 1;
display: flex;
flex-wrap: wrap;
align-items: center;
padding: 0px 10px;

}
.markreview button{
    text-transform: uppercase;
    font-size: 12px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border-radius: 4px;
color: #FF9933;
opacity: 1;
}
.sumbitandNext button{
    background: #FF9933 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border-radius: 8px;
opacity: 1;
color: #fff;
text-transform: uppercase;
}
.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }
   
  .box {
    position: relative;
    width: 40%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
 
    p{
        font: normal normal normal 18px/30px Mulish;
    }
  }
 
 
  .popup-box .cencel_pop{
    background: #E4E4E4 0% 0% no-repeat padding-box;
    border-radius: 4px;
    font: normal normal normal 16px/20px Mulish;
    letter-spacing: 0px;
    color: #707070;
    margin: 0px 50px;
  }
  .popup-box .sumbit_pop{
    background: #FF9933 0% 0% no-repeat padding-box;
    border-radius: 4px;
    font: normal normal normal 16px/20px Mulish;
    letter-spacing: 0px;
    color: #FFFFFF;
  }
@media screen and (max-width:470px){

    .top_div ul{
     justify-content: center;
    }
    .first_div{

    }
    .top_div ul li{
      display: flex;
      justify-content: center;
    }
        .Mocktest_mainscreen .ibps{
            margin-left: 0px;
        
    }
    nav ul li {
         padding: 0px 0px; 
    }
    .markreview{
        margin-top: 10px;
    height: 100px;
    text-align: center;
    }
    .paragraph {
       
        height: auto;
       
    }
    .answerWraper {
        padding: 0px 18px;
    }
    .markreview{
        justify-content: center;
    }
    .markreview button {
        
        font-size: 10px;
    }
    .top_div .Sections{
        width: 100%;
        text-align: center;
    }
    .top_div ul{
        justify-content: center !important;
     }
    .box p {
        font: normal normal normal 17px/25px Mulish;
    }
    .box {
         width: auto; 
         height: auto;
         max-height: 100vh; 
    }
}

@media screen and(max-width: 768px) and (min-width:471px){
    .first_div .Marked_Review {
        width: 19px;
        height: 20px;
    }
    .answerWraper {
        padding: 0px 50px;
    }
    .markreview{
        margin-top: 10px;
    height: auto;
    text-align: center;
  
    }
    nav ul li {
     padding: 0px 0px; 
    }
    
    .top_div ul{
       justify-content: center !important;
    }
    .top_div .Sections{
        width: 100%;
        text-align: center;
    }
    
}