.pageScreenContainer {
    .myPage {

        &.contactUs {
            align-items: center;
            justify-content: center;
        }

        .myContactRequestWrapper {
            width: 400px;
            background: #ffffff;
            padding: 20px;
            border: 1px solid rgba(255, 255, 255, 1);
            border-radius: 10px;
            -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
            -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
            box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);

            @media only screen and (max-width: 1330px) {
                width: 400px;
            }

            @media only screen and (max-width: 1199px) {
                width: 400px;
            }

            @media only screen and (max-width: 991px) {
                width: 400px;
            }

            @media only screen and (max-width: 767px) {
                width: 400px;
            }

            @media only screen and (max-width: 639px) {
                width: 400px;
            }

            @media only screen and (max-width: 575px) {
                width: 100%;
            }

            h2 {
                @media only screen and (max-width: 575px) {
                    font-size: 20px;
                }
            }

            .sectionHeading {
                color: #000080;
                text-align: center;
            }

            .contactUsForm {
                padding: 20px 0px;

                .formGroup {
                    margin-bottom: 25px;

                    &.inputForm {

                        input[type='text'],
                        textarea {
                            width: 100%;
                            padding: 7px 10px;
                            margin: 0px;
                            background: #ffffff;
                            border: 1px solid #ffffff;
                            border-radius: 5px;
                            -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.5);
                            -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.5);
                            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.5);
                        }

                        textarea {
                            resize: none;

                            &:focus {
                                border: 1px solid #ffffff;
                            }
                        }

                        .error {
                            color: #C61A1A;
                            padding-left: 15px;
                            font-size: 12px;
                            line-height: 20px;
                        }

                    }

                    &.buttonForm {
                        text-align: center;
                        margin: 0px auto;
                    }
                }
            }
        }
    }
}