:root{
    --div-height: 85vh;
    --bg-size: 100% 100%;
}

.Pack_cgl{
    background-color: #0A2540;
    height: calc(var(--div-height) - 0vh);
    display: flex;
    align-items: center;
    justify-content: center;
}

.Pack_cgl h2{
    text-transform: uppercase;
    color: white;
    text-align: center;
}
.Pack_cgl p{
    padding: 0px 25px 0px 107px;
    font-size: 14px;
    font-family: 'Mulish';
    color: white;
}
.Pack_cgl button{
    background-color: #ff9933;
   color: white;
   font-weight: bold;
}
.calender_section{
    border-radius: 10px;
   padding: 20px 0px 20px 0px;
    box-shadow: 1px 6px 12px 0px rgba(158, 157, 157, 0.75);
    -webkit-box-shadow: 1px 6px 12px 0px rgba(158, 154, 154, 0.75);
     -moz-box-shadow: 1px 6px 12px 0px rgba(158, 158, 158, 0.75);
}
.calender_section h5{
    color: #000080;
}
.calender_section button{
    background-color: #ff9933;
    color: white;
    font-weight: bold;
    
}
.tier p{
    font-size: 12px;
    line-height: 16px;
}
.tier1 a:focus{
    color: #ff9933;
    text-decoration:  2px underline;

}
.calendar_all{
    padding: 0px 10px;
}
.calendar{
    position: relative;
  text-align: center;
  text-transform: uppercase;
}
.inner_cals {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0%);
   
  }
  .inner_cals p{
      padding: 0px 10px;
  }
.locks{
margin: 4px;
width: 31px;
height: 30px;
background: #afe0ab 0% 0% no-repeat padding-box;
border-radius: 5px;
display: flex;
justify-content: center;
align-items: center;
}
.locks h3{
    position: relative;
    top: 10px;
}
.locks img{
    position: absolute;
}
.inner_cals h6{
    line-height: 0px;
    font-weight: 900;
}

@media screen and(max-width: 768px) {
  .Pack_cgl{
      height: 100%;
      padding-bottom: 10px;
  }
  .Pack_cgl p{
    padding: 0px 31px;
    
}
.calendar_all {
    margin: 0px 0px 0px 0px;
}
}
@media screen and(max-width:470px){
    .locks{
        width: 55px;
        height: 43px;
    }
    .calender_section button{
       margin: 20px 0px;
        
    }
}

.tierpageScreenContainer {
    .myPage {
        .subscriptionNavigationWrapper {
            display: block;
            width: 100%;
             
            @media only screen and (max-width: 575px) {
                .subscriptionNavigation_para{
                    margin: 0px 17px;
                }
                // display: none;
            }
            p {
                font-size: 14px;  
            }
            ul.subscriptionNavigation {
                display: inline-block;
                font-size: 0;
                flex: 1 1 200px;
                
                li.item {
                    cursor: pointer;
                    list-style: none;
                    list-style-type: none;
                    margin: 0px 83px;
                    display: inline-block;
                    
                   
                    @media only screen and (max-width: 420px) {
                        margin: 0px 0px;
                    }
                    @media only screen and (max-width: 640px ) and (min-width: 421px){
                        margin: 0px 30px;
                    }
                    @media only screen and (max-width: 768px) and(min-width: 640px) {
                        margin: 0px 50px;
                    }
                    &::after {
                        content: '';
                        display: block;
                        width: 0;
                        height: 4px;
                        background: #FF9933;
                        transition: all ease .3s;
                        opacity: 0;
                    }

                    &:hover {
                        &::after .active{
                          
                        }
                    }

                    &.active {
                        &::after {
                            width: 100%;
                            opacity: 1;
                        }
                    }

                    span {
                        display: inline-block;
                        text-decoration: none;
                        color: #0c0c0c;
                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                        font-weight: 400;
                        font-style: normal;
                        font-size: 17px;
                       margin-top: 10px;
                        padding: 0px 15px;
                       & .active{
                           color: #ff9933;
                       }
                        @media only screen and (max-width: 991px) {
                            
                            font-size: 20px;
                            line-height: 35px;
                            
                        }

                        @media only screen and (max-width: 767px) {
                            font-size: 19px;
                            line-height: 35px;
                        }

                        @media only screen and (max-width: 650px) {
                            font-size: 17px;
                            line-height: 30px;
                            
                        }

                        @media only screen and (max-width: 575px) {
                            font-size: 18px;
                            line-height: 50px;
                           
                        }
                    }
                }
            }
        }
         ul.subscriptionNavigation li.item.active span {
            color: #ff9933;
        }
        // .mobileCategoryListings {
        //     padding: 0px 20px 5px;
        //     display: none;

        //     @media only screen and (max-width: 575px) {
        //         display: block;
        //         width: 100%;
        //         padding: 0px;
        //     }

        //     .categoryTitle {
        //         color: #000080;
        //         font-family: "Mulish", Arial, Helvetica, sans-serif;
        //         font-weight: 500;
        //         font-style: normal;
        //         font-size: 20px;
        //         line-height: 40px;
        //         margin: 0px 0px 5px 0px;

        //         @media only screen and (max-width: 1199px) {
        //             font-size: 18px;
        //             line-height: 30px;
        //         }
        //     }
        // }

        .subscriptionListings {
            display: block;
            width: 100%;
            
          
            .courseItemWrapper {
                padding-bottom: 15px;
                padding-top: 15px;
            
                .courseItem  {
                    background: #ffffff;
                    border: 1px solid rgba(255, 255, 255, 1);
                    border-radius: 10px;
                    -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
                    -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
                    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
                    padding: 20px 15px;

                    .courseHeading {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .courseTitle {
                            color: #000080;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 500;
                            font-style: normal;
                            font-size: 30px;
                            line-height: 50px;
                            
                            @media only screen and (max-width: 767px) {
                                font-size: 24px;
                                line-height: normal;
                            }
                            @media only screen and (max-width: 991px) {
                            
                                
                                
                            }
                           
                        }

                        .coursePackage {
                            text-align: right;
                            color: #000080;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 400;
                            font-style: normal;
                            font-size: 14px;
                            line-height: 50px;
                        }
                    }

                    .courseDescription {
                        padding: 10px 0px;

                        p {
                            color: #970c0c;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 400;
                            font-style: normal;
                            font-size: 14px;
                            line-height: 16px;
                        }
                    }

                    .courseLinks {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &.center {
                            text-align: center;
                            justify-content: center;
                        }

                        .exploreLink, .subscribeLink {
                            padding: 0px 15px;
                            width: 115px;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 400;
                            font-style: normal;
                            font-size: 16px;
                            line-height: 40px;
                            text-align: center;
                            border-radius: 20px;
                            cursor: pointer;
                            @media only screen and (max-width: 767px) {
                                width: 108px;
                            }

                            @media only screen and (max-width: 575px) {
                                width: 115px;
                            }
                        }

                        .exploreLink {
                            background-color: #FF9933;
                            border: 1px solid #FF9933;
                            color: #FFFFFF;

                            &:hover {
                                background-color: #FFFFFF;
                                color: #FF9933;
                            }
                        }

                        .subscribeLink {
                            color: #FF9933;
                            background-color: #FFFFFF;
                            border: 1px solid #FF9933;
                            margin-left: 5px;
                           
                            @media only screen and (max-width: 575px) {
                                display: block;
                            }

                            &:hover {
                                color: #FFFFFF;
                                background-color: #FF9933;
                            }
                        }
                    }
                }
            }

            .loadMore {
                padding-top: 25px;
                padding-bottom: 25px;
                text-align: center;

                .noCourse {
                    color: #8A8A8A;
                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                    font-weight: 400;
                    font-style: normal;
                    font-size: 20px;
                    line-height: 40px;
                    display: block;

                    @media only screen and (max-width: 767px) {
                        font-size: 18px;
                        line-height: 40px;
                    }

                    @media only screen and (max-width: 639px) {
                        font-size: 16px;
                        line-height: 36px;
                    }

                    @media only screen and (max-width: 575px) {
                        font-size: 16px;
                        line-height: 34px;
                    }
                }
            }
        }
    }
}