.searchPage {

    .loadMore {
        padding-top: 25px;
        padding-bottom: 25px;
        text-align: center;
    }

    .eventItemWrapper {
        padding-bottom: 15px;
        padding-top: 15px;

        .eventItem {

            .mTitle {
                color: #000080;
                font-family: "Mulish", Arial, Helvetica, sans-serif;
                font-weight: 500;
                font-style: normal;
                font-size: 20px;
                line-height: 30px;
            }

            .mType {
                color: #414141;
                font-family: "Mulish", Arial, Helvetica, sans-serif;
                font-weight: 400;
                font-style: normal;
                font-size: 18px;
                line-height: 24px;
                display: block;
            }

            .mDate {
                color: #414141;
                font-family: "Mulish", Arial, Helvetica, sans-serif;
                font-weight: 400;
                font-style: normal;
                font-size: 18px;
                line-height: 24px;
                display: block;
            }

            .mDesc {
                img {
                    max-width: 30%;
                }

                p {
                    color: #414141;
                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                    font-weight: 400;
                    font-style: normal;
                    font-size: 18px;
                    line-height: 24px;
                }
            }
        }
    }

    .materialItemWrapper {
        padding-bottom: 15px;
        padding-top: 15px;

        .materialItem {

            .mTitle {
                color: #000080;
                font-family: "Mulish", Arial, Helvetica, sans-serif;
                font-weight: 500;
                font-style: normal;
                font-size: 20px;
                line-height: 30px;
                cursor: pointer;

                &:hover {
                    color: #c5902e;
                }
            }

            .mType {
                color: #000080;
                font-family: "Mulish", Arial, Helvetica, sans-serif;
                font-weight: 400;
                font-style: normal;
                font-size: 18px;
                line-height: 30px;
                display: block;
            }

            .mDate {
                color: #000080;
                font-family: "Mulish", Arial, Helvetica, sans-serif;
                font-weight: 00;
                font-style: normal;
                font-size: 18px;
                line-height: 30px;
                display: block;
            }

            .mDesc {
                img {
                    max-width: 30%;
                }

                p {
                    color: #414141;
                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                    font-weight: 400;
                    font-style: normal;
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
    }


    .fileItemWrapper {
        padding-bottom: 15px;
        padding-top: 15px;

        .fileItem {
            background: #ffffff;
            border: 1px solid rgba(220, 220, 220, 1);
            border-radius: 10px;

            .fileThumb {
                height: 150px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background: #ffffff;
                border-bottom: 1px solid rgba(220, 220, 220, 1);
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;

                img {
                    height: auto;
                    width: 85px;
                }
            }

            .fileDesc {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 5px 10px;

                .title {
                    color: #000080;
                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                    font-weight: 400;
                    font-style: normal;
                    font-size: 14px;
                    line-height: 20px;
                    cursor: pointer;

                    &:hover {
                        color: #c5902e;
                    }
                }

                .icon {
                    cursor: pointer;
                    color: #000080;
                    margin-left: 10px;

                    .download {
                        cursor: pointer;
                        color: #000080;

                        &:hover {
                            color: #c5902e;
                        }

                    }
                }
            }
        }
    }
}