.pageScreenContainer {
    .myPage {
        .myPageWrapper {
            &.scoreCardPage {
                min-height: -o-calc(100vh - 90px);
                min-height: calc(100vh - 90px);
                height: auto;

                @media only screen and (max-width:1199px) {
                    height: auto;
                }

                .secRow {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-pack: justify;
                    justify-content: space-between;
                    width: 100%;
                    min-height: -o-calc(100vh - 90px);
                    min-height: calc(100vh - 90px);
                    height: auto;

                    @media only screen and (max-width:1199px) {
                        height: auto;
                    }

                    @media only screen and (max-width:991px) {
                        flex-direction: column;
                    }
                }

                .pointsSec {
                    width: 70%;
                    padding: 20px 30px 0px;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;

                    @media only screen and (max-width:1440px) {
                        padding: 30px 30px 0px;
                    }

                    @media only screen and (max-width:991px) {
                        width: 100%;
                        padding: 30px 30px 0px;
                    }

                    @media only screen and (max-width:767px) {
                        width: 100%;
                        padding: 30px 30px 0px;
                    }

                    @media only screen and (max-width:576px) {
                        width: 100%;
                        padding: 2px 20px 0px;
                    }

                    .totalPoints {
                        text-align: center;
                        padding-top: 7em;

                        @media only screen and (max-width:1440px) {
                            padding-top: 5em;
                        }

                        @media only screen and (max-width:767px) {
                            padding-top: 7em;
                        }

                        @media only screen and (max-width:576px) {
                            padding-top: 3em;
                        }

                        h3 {
                            font-weight: 500;
                            font-size: 40px;
                            line-height: 48px;
                            color: #000080;

                            @media only screen and (max-width:1440px) {
                                font-size: 30px;
                                line-height: 41px;
                            }

                            @media only screen and (max-width:1199px) {
                                font-size: 28px;
                                line-height: 36px;
                            }

                            @media only screen and (max-width:991px) {}

                            @media only screen and (max-width:767px) {
                                font-size: 26px;
                                line-height: 34px;
                            }

                            @media only screen and (max-width:576px) {
                                font-size: 23px;
                                line-height: 30px;
                            }
                        }

                        p {
                            font-size: 30px;
                            line-height: 37px;
                            color: #000080;
                            margin-bottom: 25px;

                            @media only screen and (max-width:1440px) {
                                font-size: 18px;
                                line-height: 26px;
                            }

                            @media only screen and (max-width:991px) {
                                margin-bottom: 20px;
                            }

                            @media only screen and (max-width:767px) {
                                font-size: 16px;
                                line-height: 26px;
                                margin-bottom: 15px;
                            }

                            @media only screen and (max-width:576px) {
                                font-size: 15px;
                                line-height: 20px;
                            }
                        }
                    }

                    .points {
                        font-weight: bold;
                        font-size: 70px;
                        line-height: 37px;
                        color: #000000;

                        @media only screen and (max-width:1440px) {
                            font-size: 56px;
                            line-height: 33px;
                        }

                        @media only screen and (max-width:1199px) {
                            font-size: 50px;
                            line-height: 30px;
                        }

                        @media only screen and (max-width:991px) {
                            font-size: 46px;
                            line-height: 26px;
                        }

                        @media only screen and (max-width:767px) {
                            font-size: 40px;
                            line-height: 28px;
                        }

                        @media only screen and (max-width:576px) {
                            font-size: 36px;
                            line-height: 24px;
                        }

                        span {
                            display: inline-block;
                            width: 100%;
                            font-size: 30px;

                            @media only screen and (max-width:1440px) {
                                font-size: 22px;
                            }

                            @media only screen and (max-width:767px) {
                                font-size: 20px;
                            }
                        }
                    }

                    .pointsDetail {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -ms-flex-pack: justify;
                        justify-content: space-between;
                        width: 80%;
                        margin: 0 auto;
                        padding-top: 7em;

                        @media only screen and (max-width:1440px) {
                            padding-top: 5em;
                        }

                        @media only screen and (max-width:767px) {
                            width: 85%;
                            padding-top: 7em;
                        }

                        @media only screen and (max-width:576px) {
                            width: 100%;
                            padding-top: 3em;
                            flex-direction: column;
                            text-align: center;
                        }

                        .correctAns {
                            p {
                                font-size: 20px;
                                line-height: 30px;
                                color: #484848;

                                @media only screen and (max-width:1440px) {
                                    font-size: 20px;
                                    line-height: 30px;
                                }

                                @media only screen and (max-width:1199px) {
                                    font-size: 18px;
                                    line-height: 26px;
                                }

                                @media only screen and (max-width:991px) {
                                    font-size: 16px;
                                    line-height: 20px;
                                }

                                @media only screen and (max-width:767px) {
                                    font-size: 15px;
                                }

                                @media only screen and (max-width:576px) {
                                    margin-bottom: 20px;
                                }
                            }

                            .number {
                                font-weight: bold;
                                font-size: 50px;
                                line-height: 61px;
                                color: #138808;

                                @media only screen and (max-width:1440px) {
                                    font-size: 40px;
                                    line-height: 48px;
                                }

                                @media only screen and (max-width:1199px) {
                                    font-size: 38px;
                                    line-height: 44px;
                                }

                                @media only screen and (max-width:991px) {
                                    font-size: 36px;
                                    line-height: 40px;
                                }

                                @media only screen and (max-width:767px) {
                                    font-size: 30px;
                                    line-height: 36px;
                                }
                            }
                        }

                        .incorrectAns {
                            p {
                                font-size: 20px;
                                line-height: 30px;
                                color: #484848;

                                @media only screen and (max-width:1440px) {
                                    font-size: 20px;
                                    line-height: 30px;
                                }

                                @media only screen and (max-width:1199px) {
                                    font-size: 18px;
                                    line-height: 26px;
                                }

                                @media only screen and (max-width:991px) {
                                    font-size: 16px;
                                    line-height: 20px;
                                }

                                @media only screen and (max-width:767px) {
                                    font-size: 15px;
                                }

                                @media only screen and (max-width:576px) {
                                    margin-bottom: 20px;
                                }
                            }

                            .number {
                                font-weight: bold;
                                font-size: 50px;
                                line-height: 61px;
                                color: #FF0000;

                                @media only screen and (max-width:1440px) {
                                    font-size: 40px;
                                    line-height: 48px;
                                }

                                @media only screen and (max-width:1199px) {
                                    font-size: 38px;
                                    line-height: 44px;
                                }

                                @media only screen and (max-width:991px) {
                                    font-size: 36px;
                                    line-height: 40px;
                                }

                                @media only screen and (max-width:767px) {
                                    font-size: 30px;
                                    line-height: 36px;
                                }
                            }
                        }

                        .unanswered {
                            p {
                                font-size: 20px;
                                line-height: 30px;
                                color: #484848;

                                @media only screen and (max-width:1440px) {
                                    font-size: 20px;
                                    line-height: 30px;
                                }

                                @media only screen and (max-width:1199px) {
                                    font-size: 18px;
                                    line-height: 26px;
                                }

                                @media only screen and (max-width:991px) {
                                    font-size: 16px;
                                    line-height: 20px;
                                }

                                @media only screen and (max-width:767px) {
                                    font-size: 15px;
                                }

                                @media only screen and (max-width:576px) {
                                    margin-bottom: 20px;
                                }
                            }

                            .number {
                                font-weight: bold;
                                font-size: 50px;
                                line-height: 61px;
                                color: #FFBF00;

                                @media only screen and (max-width:1440px) {
                                    font-size: 40px;
                                    line-height: 48px;
                                }

                                @media only screen and (max-width:1199px) {
                                    font-size: 38px;
                                    line-height: 44px;
                                }

                                @media only screen and (max-width:991px) {
                                    font-size: 36px;
                                    line-height: 40px;
                                }

                                @media only screen and (max-width:767px) {
                                    font-size: 30px;
                                    line-height: 36px;
                                }
                            }
                        }
                    }

                    .redeemRow {
                        background: #444444 0% 0% no-repeat padding-box;
                        box-shadow: 0px 4px 8px #00000029;
                        border-radius: 20px 20px 0px 0px;
                        width: 93%;
                        text-align: center;
                        padding: 15px 30px;

                        @media only screen and (max-width:1199px) {
                            width: 100%;
                            margin-top: 10em;
                            margin-bottom: 20px;
                        }

                        @media only screen and (max-width:991px) {
                            margin-top: 7em;
                        }

                        @media only screen and (max-width:767px) {
                            margin-top: 6em;
                        }

                        @media only screen and (max-width:576px) {
                            margin-top: 3em;
                        }

                        .text {
                            display: inline-block;
                            font-weight: bold;
                            font-size: 30px;
                            line-height: 60px;
                            color: #FFFFFF;
                            vertical-align: middle;

                            @media only screen and (max-width:1199px) {
                                font-size: 24px;
                                line-height: 41px;
                            }

                            @media only screen and (max-width:1440px) {
                                font-size: 26px;
                                line-height: 44px;
                            }

                            @media only screen and (max-width:991px) {
                                font-size: 22px;
                                line-height: 44px;
                            }

                            @media only screen and (max-width:767px) {
                                font-size: 20px;
                                line-height: 39px;
                            }

                            @media only screen and (max-width:576px) {
                                line-height: 30px;
                                display: block;
                            }

                            span {
                                font-weight: normal;
                                font-size: 20px;

                                @media only screen and (max-width:1440px) {
                                    font-size: 18px;
                                }

                                @media only screen and (max-width:991px) {
                                    font-size: 16px;
                                }

                                @media only screen and (max-width:767px) {
                                    font-size: 15px;
                                }
                            }
                        }

                        .redeemNow {
                            float: right;
                            font-size: 24px;
                            line-height: 38px;
                            color: #FFFFFF;
                            background-color: #FF9933;
                            border-radius: 38px;
                            padding: 16px 30px;
                            text-decoration: none;
                            cursor: pointer;

                            @media only screen and (max-width:1440px) {
                                font-size: 20px;
                                line-height: 32px;
                                padding: 6px 24px;
                            }

                            @media only screen and (max-width:991px) {
                                font-size: 18px;
                                line-height: 28px;
                                padding: 8px 22px;
                            }

                            @media only screen and (max-width:767px) {
                                font-size: 16px;
                                line-height: 28px;
                                padding: 6px 16px;
                            }

                            @media only screen and (max-width:576px) {
                                padding: 6px 20px;
                                float: none;
                                display: inline-block;
                                margin-top: 15px;
                            }
                        }
                    }
                }

                .reportSec {
                    width: 30%;
                    position: relative;
                    box-shadow: -2px 0px 4px #00000029;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    padding: 20px 30px 10px;

                    @media only screen and (max-width:991px) {
                        width: 100%;
                    }

                    .questions {
                        display: grid;
                        grid-template-columns: 60px 60px 60px 60px 60px;
                        grid-template-rows: 60px 60px 60px 60px;
                        justify-content: center;
                        align-content: center;
                        text-align: center;
                        padding-top: 2em;

                        @media only screen and (max-width:1440px) {
                            grid-template-columns: 50px 50px 50px 50px 50px;
                            grid-template-rows: 50px 50px 50px 50px;
                            padding-top: 1.5em;
                        }

                        @media only screen and (max-width:1199px) {
                            grid-template-columns: 50px 50px 50px 50px 50px;
                            grid-template-rows: 50px 50px 50px 50px;
                        }

                        @media only screen and (max-width:767px) {
                            grid-template-columns: 45px 45px 45px 45px 45px;
                            grid-template-rows: 45px 45px 45px 45px;
                        }

                        span {
                            height: 48px;
                            width: 48px;
                            border-radius: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-weight: 500;
                            font-size: 24px;
                            line-height: 37px;
                            color: #FFFFFF;
                            align-self: center;
                            justify-self: center;

                            @media only screen and (max-width:1440px) {
                                height: 36px;
                                width: 36px;
                                font-size: 20px;
                                line-height: 30px;
                            }

                            @media only screen and (max-width:1199px) {
                                height: 34px;
                                width: 34px;
                                font-size: 20px;
                                line-height: 28px;
                            }

                            @media only screen and (max-width:767px) {
                                height: 30px;
                                width: 30px;
                                font-size: 18px;
                                line-height: 26px;
                            }

                            &.yellow {
                                background-color: #FF0000;
                            }

                            &.green {
                                background-color: #138808;
                            }

                            &.blue {
                                background-color: #FFBF00;
                            }

                            &.black {
                                background-color: #FFFFFF;
                                color: #000000;
                                border: 1px solid #000000
                            }
                        }
                    }

                    .stage {
                        text-align: center;
                        font-weight: 500;
                        font-size: 40px;
                        line-height: 48px;
                        color: #484848;
                        margin-top: 2em;
                        display: block;

                        @media only screen and (max-width:1440px) {
                            font-size: 30px;
                            line-height: 42px;
                        }

                        @media only screen and (max-width:1199px) {
                            font-size: 28px;
                            line-height: 38px;
                        }

                        @media only screen and (max-width:991px) {
                            font-size: 26px;
                            line-height: 34px;
                        }

                        @media only screen and (max-width:767px) {
                            font-size: 24px;
                            line-height: 30px;
                        }

                        @media only screen and (max-width:576px) {
                            font-size: 22px;
                            margin: 30px 0px 35px;
                        }
                    }

                    .moreDetail {
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 34px;
                        color: #141414;
                        text-align: center;
                        display: block;
                        margin-top: 2em;

                        @media only screen and (max-width:1440px) {
                            font-size: 20px;
                            line-height: 30px;
                        }

                        @media only screen and (max-width:1199px) {
                            font-size: 18px;
                            line-height: 26px;
                        }

                        @media only screen and (max-width:767px) {
                            font-size: 17px;
                            line-height: 24px;
                        }

                        .reportLink {
                            font-weight: 500;
                            font-size: 24px;
                            line-height: 34px;
                            color: #141414;
                            text-align: center;
                            display: block;
                            margin-bottom: 15px;

                            @media only screen and (max-width:1440px) {
                                font-size: 20px;
                                line-height: 30px;
                            }

                            @media only screen and (max-width:1199px) {
                                font-size: 18px;
                                line-height: 26px;
                            }

                            @media only screen and (max-width:767px) {
                                font-size: 17px;
                                line-height: 24px;
                            }
                        }

                        .getReport {
                            color: #141414;
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }

                    .playBtn {
                        position: relative;
                        text-align: center;
                        display: block;
                        width: 100%;
                        margin-top: 2em;

                        @media only screen and (max-width:1440px) {}

                        @media only screen and (max-width:1199px) {
                            position: relative;
                            margin-top: 9em;
                            margin-bottom: 20px;
                        }

                        @media only screen and (max-width:991px) {
                            margin-top: 7em;
                        }

                        @media only screen and (max-width:767px) {
                            margin-top: 6em;
                        }

                        @media only screen and (max-width:576px) {
                            margin-top: 4em;
                        }

                        .playMore {
                            font-size: 24px;
                            line-height: 38px;
                            color: #FFFFFF;
                            background-color: #FF9933;
                            border-radius: 38px;
                            padding: 12px 56px;
                            text-decoration: none;
                            cursor: pointer;
                            display: inline-block;

                            @media only screen and (max-width:1440px) {
                                font-size: 20px;
                                line-height: 32px;
                                padding: 10px 44px;
                            }

                            @media only screen and (max-width:767px) {
                                font-size: 18px;
                                line-height: 30px;
                                padding: 10px 44px;
                            }

                            @media only screen and (max-width:576px) {
                                font-size: 16px;
                                padding: 10px 40px;
                            }
                        }
                    }
                }


            }
        }
    }
}