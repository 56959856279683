.answer_wrap h5{
    font-weight: 700;
 
}
.answer_wrap p{
   color: black;
}
.answer_wrap span{
    color: #138808;
    font-weight: bold;
}