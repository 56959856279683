.Detailed_Analysis{
       
    button{
        text-align: center;
        font: normal normal 600 14px/22px Mulish;
        letter-spacing: 0px;
        color: #FF9933;
        text-transform: capitalize;
        opacity: 1;
    }
    img{
      width: 8%;
    }
      .Detailed{
          background-color: #fff;
        border: 2px solid #FF9933;
        color: #FF9933;
        border-radius: 4px;
        opacity: 1;
        font-size: 14px;
        text-transform: capitalize;
     @media screen and (max-width:768px) {
      .Detailed{
      font-size: 12px;
    }
     }
      }
      .headings h1{
        font: normal normal 600 22px/28px Mulish;
        color: #000080;
        margin-left: 14px;
      }
      .pageScreenContainers .myPages .subscriptionNavigationWrapper ul.subscriptionNavigation li.item {
        cursor: pointer;
        list-style: none;
        list-style-type: none;
         margin: 0px 0px; 
        display: inline-block;
        padding: 3px 0px;
    }
    .pageScreenContainers{
      
    height: 20px;
    padding: 20px;
    }
    
      .pageScreenContainers {
         padding: 0px; 
         height: 100%;
    }
      .pageScreenContainers .myPages 
      .subscriptionNavigationWrapper
       ul.subscriptionNavigation li.item span{
        text-align: center;
        font: normal normal normal 18px/20px Mulish;
        letter-spacing: 0px;
        color: #777575;
        padding: 0px 10px;
      }
      .Detailed_Analysis .pageScreenContainers .myPages .subscriptionNavigationWrapper ul.subscriptionNavigation li.active span {
        color: red;
    }
      .pageScreenContainers .myPages .subscriptionNavigationWrapper ul.subscriptionNavigation {
         display: inline-block; 
         font-size: 0; 
         width: 100%; 
        text-align: start;
      }
      .pageScreenContainers .myPages .subscriptionNavigationWrapper ul.subscriptionNavigation li.item.active::after {
        width: 80%;
        opacity: 1;
        margin: auto;
    }
    .pageScreenContainers .myPages .subscriptionNavigationWrapper ul.subscriptionNavigation li.item::after {
        content: '';
        display: block;
        width: 0;
        height: 4px;
        background: #FF9933;
        transition: all ease .3s;
        opacity: 0;
    }
    .Detailed_Analysis .pageScreenContainers .myPages .subscriptionNavigationWrapper ul.subscriptionNavigation li.item.active span {
        color: #FF9933;
    }
   
    
}


.generalEnglish{
    border: 1px solid gray;
    border-left: none;
    border-right: none;
   
}
.ScoreWraper{

    height: auto;
    margin: auto;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .correct{
        color: green;
    }
    .incorect{
        color: red;
    }
    .unaswered{
        color: #FF9933;
    }
    .text-right{
        border-right: 1px solid gray;
       padding-left: 2px;
    }
}
.scoredetails{
    flex: 1 1 200px;
}
.score span{
    color: #000080;
    
}
  
.circle-container{
  width: 70px;
  height: 70px;
  background: #E1E1E1;
  border-radius: 50%;
}

.circle-container .circle .item,
.circle-container .circle .fill{
  width: 70px;
  height: 70px;
  position: absolute;
  border-radius: 50%;
}

.circle-container .circle .item{
  clip: rect(0px, 70px, 70px, 43px);
}

.circle-container .circle .item .fill{
  clip: rect(0px, 44px, 55px, 0px);
  background-image: linear-gradient(
    to bottom, rgb(235, 54, 41) 0%,
    #ffa600e3 100%);
}

.circle-container .circle .item.layer-1,
.circle-container .circle .fill{
  transform: rotate(100deg);
  animation: fill ease-in-out 3s;
}

@keyframes fill {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(100deg);
  }
}

.inside-content{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fff;
  text-align: center;
  margin-top: 10px;
  margin-left: 10px;
  position: absolute;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  line-height: 60px;
}
.overallPerformance{
padding: 33px 0px;
background: #0A2540 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border-radius: 8px;

}
.overallPerformance h6{
    font: normal normal bold 17px/25px Mulish;
letter-spacing: 0px;
color: #00D4FF;
opacity: 1;
}
.overallPerformance p{
    font: normal normal bold 14px/25px Mulish;
letter-spacing: 0px;
color: #FFFFFF;
}
.overallPerformance p span{
    font: normal normal normal 12px/20px Mulish;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 0.69;
}
.progressdiv{
    height: auto;
    margin: auto;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-around;
        h5{
        font: normal normal bold 18px/20px Raleway;
        letter-spacing: 0px;
        color: #545454;
        margin-bottom: 0px;
    }
    p{
        color: var(--unnamed-color-545454);
font: normal normal normal 14px/27px Roboto;
color: #545454;
margin-bottom: 0px;
    }
}
.progresschilddiv{
    height: 120px;
    margin: 0px 12px;
    flex: 1 1 170px;
}
.progress {
    padding:0;
    width:100%;
    height:30px;
    overflow:hidden;
    background:#e5e5e5;
    border-radius:6px;
  }
  
  .bar {
      position:relative;
    float:left;
    min-width:35%;
    height:100%;
    background: #DA2835 0% 0% no-repeat padding-box;
  }
  
  .percent {
      position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    margin:0;
    font-family:tahoma,arial,helvetica;
    font-size:12px;
    color:white;
  }

  .progresstd {
    padding:0;
    width:90%;
    height:17px;
    overflow:hidden;
    background:#e5e5e5;
    border-radius:6px;
  }
  
  .bartd {
      position:relative;
    float:left;
    min-width:35%;
    height:100%;
    background: #FF9933 0% 0% no-repeat padding-box;
  }
  

  .circle-bg-color-anim {
    animation: circle-bg-color-anim 1.9s normal forwards;
  }
  .simple_interest{
    padding:0;
    width:100%;
    height:17px;
    overflow:hidden;
    background:#e5e5e5;
    border-radius:6px;
  }
  .simple_interest .bar{
    position:relative;
    float:left;
    min-width:95%;
    height:100%;
    background: #1D9A37 0% 0% no-repeat padding-box;
  }
  .Profit_and_Loss{
    padding:0;
    width:100%;
    height:17px;
    overflow:hidden;
    background:#e5e5e5;
    border-radius:6px;
  }
  .Profit_and_Loss .bar{
    position:relative;
    float:left;
    min-width:65%;
    height:100%;
    background: #FF9933 0% 0% no-repeat padding-box;
  }


  .Compare_with_Topper{
      h1{
        color: #000080;
      }
      .toper_wraper{
          h4{
              color: #000080;
              text-transform: uppercase;
          }
      }
    .toper{
        width: 80px;
        height: 80px;
        border-radius: 50%;
        padding: 2px;
        box-shadow: 0px 0px 10px 0px rgba(84,84,84,0.75);
        -webkit-box-shadow: 0px 0px 10px 0px rgba(84,84,84,0.75);
        -moz-box-shadow: 0px 0px 10px 0px rgba(84,84,84,0.75);
        .inner_circle{
            border-top: 2px solid blue;
            border-left: 2px solid blue;
          
            border-radius: 50%;
            width: 76px;
           height: 76px;
           justify-content: center;
           display: flex;
           align-items: center;
           color: #000080;

        }
       

    }
    .average_wraper h4{
        color: #FF9933;
        text-transform: uppercase;
    }
    .average{
        width: 80px;
        height: 80px;
        border-radius: 50%;
        padding: 2px;
        box-shadow: 0px 0px 10px 0px rgba(84,84,84,0.75);
        -webkit-box-shadow: 0px 0px 10px 0px rgba(84,84,84,0.75);
        -moz-box-shadow: 0px 0px 10px 0px rgba(84,84,84,0.75);
        .inner_circle{
            border-top: 2px solid blue;
            border-left: 2px solid blue;
          
            border-radius: 50%;
            width: 76px;
           height: 76px;
           justify-content: center;
           display: flex;
           align-items: center;
           color: #FF9933;
        }
    }
    .you_wraper {
        h4{
            color: #1D9A37;
            text-transform: uppercase;
        }
        span{
            color: #1D9A37;
        }
    }
    
  }
  
  @keyframes circle-bg-color-anim {
    0% {background: rgb(255, 255, 255);}
    50% {background: rgb(255, 255, 255);}
    100% {background: rgb(252, 252, 252);}
  }
  
  
  .circular{
    height:100px;
    width: 100px;
    position: relative;
    border-radius: 100%;

    }
 
  .circular .inner{
    position: absolute;
    z-index: 11;
    top: 50%;
    left: 50%;
    height: 80px;
    width: 80px;
    margin: -40px 0 0 -40px;
    border-radius: 100%;

  
  
  }
  .circular .number{
    position: absolute;
    top:42%;
    left:50%;
    transform: translate(-50%, -50%);

    font-family: sans-serif;
    z-index:11;
    font-size:29px;
    font-weight:600;
  
  }

  .circular .bar{
    position: absolute;
    height: 100%;
    width: 100%;
    background: #eafaf1;
    -webkit-border-radius: 100%;
    clip: rect(0px, 100px, 100px, 50px);
    z-index:9;
  
  }
  
  .circle .bar .progress{
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    clip: rect(0px, 50px, 100px, 0px);
    z-index:-8;
    background: #FF9933;
  }
  
  .left .progress{
    animation: left 0.8s linear both;
  }
  @keyframes left{
    100%{
      transform: rotate(180deg);
    }
  }
  .right {
    transform: rotate(180deg); 
  }
.details{
  margin-top: 100px;
  margin-left: -18px;
}