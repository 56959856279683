.popupWrapper {
    &.VideoNotAvailable {
        .popUpInner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .featuredIconWrapper {
                padding: 0px 0px 20px 0px;

                .featuredIcon {
                    height: 100px;
                    width: auto;
                }
            }

            .modal-heading {
                color: #000080;
                font-family: 'Mulish', sans-serif;
                font-size: 18px;
                font-weight: 600;
                padding: 25px 0px;
                margin: 0px;
                text-align: center;
            }

            .modalContent {
                text-align: center;
            }

            p {
                margin: 0px 0px 0px 0px;
                font-family: 'Mulish', sans-serif;
                font-weight: 400;
                font-style: normal;
                font-size: 20px;
                line-height: 40px;
                color: #000000;
                text-align: center;
            }
        }
    }
}
