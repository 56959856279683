.pageScreenContainer {

    .examPage {
        width: 100%;
        height: 100%;
        min-height: calc(100vh - 90px);
        display: flex;
        align-items: flex-start;

        @media only screen and (max-width: 1600px) {}

        @media only screen and (max-width: 1440px) {}

        @media only screen and (max-width: 1330px) {}

        @media only screen and (max-width: 1199px) {}

        @media only screen and (max-width: 991px) {}

        @media only screen and (max-width: 767px) {}

        @media only screen and (max-width: 639px) {}

        @media only screen and (max-width: 575px) {
            min-height: calc(100vh - 60px);
            display: block;
        }

        .pageTitle {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: calc(100% - 70px);
            position: fixed;
            padding: 10px 20px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            z-index: 1;
            min-height: 80px;

            @media only screen and (max-width: 639px) {
                width: calc(100%);
                position: relative;
            }

            .pageHeading {
                h3 {
                    color: #000080;
                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                    font-weight: 500;
                    font-style: normal;
                    font-size: 20px;
                    line-height: 30px;
                    margin: 0px;

                    @media only screen and (max-width: 1199px) {
                        font-size: 20px;
                        line-height: 30px;
                    }
                }
            }

            .categoryAccuracy {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .accuracyChart {
                    .quizTime {
                        color: #000080;
                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                        font-weight: 500;
                        font-style: normal;
                        font-size: 20px;
                        line-height: 30px;
                    }
                }
            }
        }

        .examQuestionsListing {
            padding: 20px;
            margin-top: 100px;
            width: 100%;
            height: auto;
            min-height: calc(100vh - 190px);

            @media only screen and (max-width: 639px) {
                margin-top: 0px;
            }

            .questionItem {
                .question {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    padding-bottom: 15px;

                    .sequenceNumber {
                        width: 35px;
                        font-weight: 500;
                        color: #000000;
                        font-size: 20px;

                        p {
                            margin-bottom: 0px;
                            color: #000000;
                            font-weight: 500;
                            font-size: 20px;
                        }
                    }

                    .questionDescription {
                        flex: 1;
                        font-weight: 500;
                        color: #000000;
                        font-size: 20px;

                        p {
                            margin-bottom: 0px;
                            color: #000000;
                            font-weight: 500;
                            font-size: 20px;
                        }
                    }

                    .questionContent {
                        flex: 1;
                        font-weight: 500;
                        color: #000000;
                        font-size: 20px;

                        p {
                            margin-bottom: 0px;
                            color: #000000;
                            font-weight: 500;
                            font-size: 20px;
                        }
                    }
                }

                .option {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding-bottom: 10px;

                    &:last-child {
                        padding-bottom: 0px;
                    }


                    .sequenceNumber {
                        width: 35px;
                        font-size: 18px;

                        p {
                            margin-bottom: 0px;
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }

                    .optionContent {
                        flex: 1;
                        font-size: 18px;
                        color: #444444;
                        cursor: pointer;

                        p {
                            margin-bottom: 0px;
                            font-size: 16px;
                            line-height: 24px;
                            color: #444444;
                        }
                    }
                }
            }

            .questionAnswer {
                height: 100%;
                text-align: center;
                padding: 15px;
                border-radius: 10px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;

                .answerHeading {
                    margin-bottom: 5px;
                }

                .answerOption {
                    margin-bottom: 5px;

                    &.Correct {
                        color: #138808;
                    }

                    &.Wrong {
                        color: #FF431C;
                    }
                }

                .justification {
                    font-size: 16px;
                    line-height: 24px;
                    margin-bottom: 0px;

                    p {
                        font-size: 16px;
                        line-height: 24px;
                        margin-bottom: 0px;
                    }
                }
            }

            .loadMore {
                padding: 15px 0px 10px;
                text-align: center;
            }
        }

    }
}
