.aceGameCertificate {
    background-color: #FFFFFF;
    width: 75%;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    position: relative;
    padding: 25px 40px;
    z-index: 1;

    @media only screen and (max-width:1440px) {
        padding: 25px 30px;
    }

    @media only screen and (max-width:991px) {
        width: 100%;
    }

    @media only screen and (max-width:576px) {
        padding: 20px 15px;
    }

    &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 60%;
        background-image: url(../../assets/images/certificate/CertificateBackground.png);
        right: 0;
        top: 0;
        background-position: center left;
        background-repeat: no-repeat;
        z-index: -1;
        background-size: 100%;

        @media only screen and (max-width:767px) {
            background-position: 5% 45%;
        }
    }

    .logo {
        margin-bottom: 30px;

        img {
            max-width: 160px;

            @media only screen and (max-width:1440px) {
                max-width: 160px;
            }

            @media only screen and (max-width:1199px) {
                max-width: 150px;
            }

            @media only screen and (max-width:767px) {
                max-width: 130px;
            }

            @media only screen and (max-width:576px) {
                max-width: 100px;
            }
        }
    }

    h2 {
        font-family: 'BaroqueTextJF';
        text-align: center;
        font-size: 88px;
        line-height: 140px;
        margin: 0px;

        @media only screen and (max-width:1440px) {
            font-size: 60px;
            line-height: 85px;
        }

        @media only screen and (max-width:1199px) {
            font-size: 50px;
            line-height: 82px;
        }

        @media only screen and (max-width:767px) {
            font-size: 40px;
            line-height: 70px;
        }

        @media only screen and (max-width:576px) {
            font-size: 38px;
            line-height: 60px;
        }
    }

    p {
        font-size: 20px;
        line-height: 30px;
        color: #414141;
        text-align: center;
        font-weight: 500;
        margin: 0px;

        @media only screen and (max-width:1440px) {
            font-size: 18px;
            line-height: 30px;
        }

        @media only screen and (max-width:767px) {
            font-size: 16px;
            line-height: 22px;
        }

        @media only screen and (max-width:576px) {
            font-size: 14px;
            line-height: 18px;
        }
    }

    h3 {
        font-size: 44px;
        line-height: 56px;
        font-weight: 500;
        color: #000080;
        text-align: center;
        margin: 25px 0px 15px;

        @media only screen and (max-width:1440px) {
            font-size: 40px;
            line-height: 52px;
            margin: 20px 0px 10px;
        }

        @media only screen and (max-width:1199px) {
            font-size: 36px;
            line-height: 46px;
        }

        @media only screen and (max-width:767px) {
            font-size: 30px;
            line-height: 40px;
        }

        @media only screen and (max-width:576px) {
            font-size: 28px;
            line-height: 36px;
            margin: 15px 0px 10px;
        }
    }

    .acePointDetail {
        p {
            font-size: 20px;
            line-height: 30px;
            color: #414141;
            font-weight: 400;
            margin: 0px;

            @media only screen and (max-width:1440px) {
                font-size: 18px;
                line-height: 26px;
            }

            @media only screen and (max-width:1199px) {
                font-size: 16px;
                line-height: 24px;
            }

            @media only screen and (max-width:767px) {
                font-size: 15px;
                line-height: 22px;
            }

            @media only screen and (max-width:576px) {
                font-size: 14px;
                line-height: 20px;
            }

            span {
                color: #000;
                font-weight: bold;
            }
        }

        .level {
            font-size: 30px;
            line-height: 42px;
            font-weight: bold;
            color: #138808;
            text-align: center;
            margin-top: 15px;

            @media only screen and (max-width:1440px) {
                font-size: 26px;
                line-height: 32px;
            }

            @media only screen and (max-width:1199px) {
                font-size: 24px;
                line-height: 28px;
                margin-top: 12px;
            }

            @media only screen and (max-width:767px) {
                font-size: 22px;
                margin-top: 10px;
            }
        }
    }

    .extraInfo {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        @media only screen and (max-width:767px) {
            align-items: center;
            flex-direction: column;
        }

        .awardIcon {
            margin-top: -40px;

            @media only screen and (max-width:1199px) {
                margin-top: -20px;
            }

            @media only screen and (max-width:767px) {
                margin-top: 30px;
            }

            img {
                max-width: 200px;

                @media only screen and (max-width:1440px) {
                    max-width: 180px;
                }

                @media only screen and (max-width:1199px) {
                    max-width: 150px;
                }

                @media only screen and (max-width:767px) {
                    max-width: 120px;
                }
            }

            p {
                margin: 15px 0px;
                font-size: 18px;
                line-height: 26px;
                color: #000080;
                text-decoration: underline;

                @media only screen and (max-width:1440px) {
                    font-size: 16px;
                    line-height: 24px;
                }

                @media only screen and (max-width:1199px) {
                    font-size: 15px;
                    line-height: 20px;
                }

                @media only screen and (max-width:767px) {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }

    .siteInfo {
        p {
            font-size: 18px;
            line-height: 26px;
            font-weight: 400;
            color: #414141;
            margin: 0px;

            @media only screen and (max-width:1440px) {
                font-size: 16px;
                line-height: 22px;
            }

            @media only screen and (max-width:1199px) {
                font-size: 15px;
                line-height: 20px;
            }

            @media only screen and (max-width:767px) {
                font-size: 14px;
                line-height: 20px;
            }

            a {
                color: #414141;
            }

            span {
                font-weight: bold;
            }
        }
    }

    .copyRightSection {
        margin-top: 20px;

        @media only screen and (max-width:767px) {
            margin-top: 30px;
        }

        ul.socialIcons {
            float: left;
            padding: 0px;

            @media only screen and (max-width:767px) {
                float: none;
                text-align: center;
                margin-bottom: 10px;
            }

            li {
                display: inline;
                margin-right: 30px;

                @media only screen and (max-width:1440px) {
                    margin-right: 20px;
                }

                img {
                    width: 25px;
                    cursor: pointer;
                }
            }
        }

        p {
            float: right;
            font-size: 18px;
            margin: 0px;

            @media only screen and (max-width:1440px) {
                font-size: 16px;
            }

            @media only screen and (max-width:1199px) {
                font-size: 15px;
            }

            @media only screen and (max-width:767px) {
                float: none;
                font-size: 14px;
            }
        }
    }
}