.pageScreenContainer {
    .myPage {
        .myPageWrapper {
            &.SubjectGameReport {
                .examQuestionsListing {
                    .questionItem {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: flex-start;

                        .sequenceNumber {
                            color: #000080;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 500;
                            font-style: normal;
                            font-size: 20px;
                            line-height: 30px;
                            width: 30px;

                            p {
                                color: #000080;
                                font-family: "Mulish", Arial, Helvetica, sans-serif;
                                font-weight: 500;
                                font-style: normal;
                                font-size: 20px;
                                line-height: 30px;
                                margin: 0px 10px 0px 0px;
                            }
                        }

                        .questionContentWrapper {
                            flex: 1;

                            .questionContentDescription {
                                font-family: "Mulish", Arial, Helvetica, sans-serif;
                                font-weight: 400;
                                font-style: normal;
                                font-size: 20px;
                                line-height: 30px;
                                color: #141414;

                                p {
                                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                                    font-weight: 400;
                                    font-style: normal;
                                    font-size: 20px;
                                    line-height: 30px;
                                    color: #141414;
                                }
                            }

                            .questionContent {
                                font-family: "Mulish", Arial, Helvetica, sans-serif;
                                font-weight: 400;
                                font-style: normal;
                                font-size: 20px;
                                line-height: 30px;
                                color: #141414;

                                p {
                                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                                    font-weight: 400;
                                    font-style: normal;
                                    font-size: 20px;
                                    line-height: 30px;
                                    color: #141414;
                                }
                            }

                            .myAnswerView {
                                color: #414141;
                                font-family: "Mulish", Arial, Helvetica, sans-serif;
                                font-weight: 500;
                                font-style: normal;
                                font-size: 20px;
                                line-height: 30px;
                                display: block;

                                .myContent {
                                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                                    font-weight: 400;
                                    font-style: normal;
                                    font-size: 20px;
                                    line-height: 30px;
                                    color: #141414;

                                    p {
                                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                                        font-weight: 400;
                                        font-style: normal;
                                        font-size: 20px;
                                        line-height: 30px;
                                        color: #141414;
                                    }
                                }

                                &.Wrong {
                                    color: #FF431C;

                                    .myContent {
                                        color: #FF431C;
                                    }
                                }

                                &.Correct {
                                    color: #138808;

                                    .myContent {
                                        color: #138808;
                                    }
                                }
                            }

                            .correctAnswerView {
                                color: #414141;
                                font-family: "Mulish", Arial, Helvetica, sans-serif;
                                font-weight: 500;
                                font-style: normal;
                                font-size: 20px;
                                line-height: 30px;
                                display: block;

                                .myContent {
                                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                                    font-weight: 400;
                                    font-style: normal;
                                    font-size: 20px;
                                    line-height: 30px;
                                    color: #141414;

                                    p {
                                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                                        font-weight: 400;
                                        font-style: normal;
                                        font-size: 20px;
                                        line-height: 30px;
                                        color: #141414;
                                    }
                                }

                                &.Wrong {
                                    color: #FF431C;

                                    .myContent {
                                        color: #FF431C;
                                    }
                                }

                                &.Correct {
                                    color: #138808;

                                    .myContent {
                                        color: #138808;
                                    }
                                }
                            }

                            .justificationView {
                                color: #000080;
                                font-family: "Mulish", Arial, Helvetica, sans-serif;
                                font-weight: 500;
                                font-style: normal;
                                font-size: 20px;
                                line-height: 30px;
                            }

                            .questionContentJustification {
                                font-family: "Mulish", Arial, Helvetica, sans-serif;
                                font-weight: 400;
                                font-style: normal;
                                font-size: 20px;
                                line-height: 30px;
                                color: #141414;

                                p {
                                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                                    font-weight: 400;
                                    font-style: normal;
                                    font-size: 20px;
                                    line-height: 30px;
                                    color: #141414;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}