.examLayout {
    flex: 1 1;
    -ms-flex: 1 0 0px;
    flex-direction: column !important;
    display: flex !important;
    background-color: #ffffff;

    .examLayoutContainer {
        display: flex !important;
        flex-direction: row !important;
        flex: 1 0 auto;

        .examPageContainer {
            flex: 1 1 auto;
            -ms-flex: 1 0 0px;
            min-width: 0;
            flex-direction: column !important;
            display: block;
            width: -moz-calc(100%);
            width: -webkit-calc(100%);
            width: -o-calc(100%);
            width: calc(100%);
            height: auto;
            min-height: 100vh;
            z-index: 20;

            .examPage {
                width: 100%;
                height: -moz-calc(100%);
                height: -webkit-calc(100%);
                height: -o-calc(100%);
                height: calc(100%);
            }
        }
    }
}