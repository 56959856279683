.WithoutAuthFooterWrapper {
  position: fixed;
  height: 40px;
  width: 100%;
  bottom: 0px;
  left: 0px;
  right: 0px;
  font-size: 0px;
  -webkit-box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.15);
  display: none;
  z-index: 10001;

  @media only screen and (max-width: 639px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .footerNavigationBar {
    position: fixed;
    height: 40px;
    width: 100%;
    bottom: 0px;
    left: 0px;
    right: 0px;
    font-size: 0px;
    -webkit-box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.15);
    display: none;
    z-index: 10001;

    @media only screen and (max-width: 639px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .nItem {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      text-align: center;
      background-color: #FFFFFF;
      height: 40px;

      color: #000080;
      font-family: "Mulish", Arial, Helvetica, sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: 26px;
      line-height: 36px;
      z-index: 10000;

      &.between {
        border-left: 1px solid #CDCDCD;
        border-right: 1px solid #CDCDCD;
      }

      .nIcon {
        max-height: 28px;
        padding: 0px 0px;
        z-index: 9999;
      }
    }
  }
}

/*
.auth-layout {
  width: 100%;
  height: 100vh;
  margin-right: auto;
  margin-left: auto;
  overflow-x: scroll;

  .authMainContent {
    width: 100%;
    height: 100vh;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;

    .authSubContent {
      position: relative;
      height: 100%;
      text-align: center;
      margin: auto;
      width: 100%;

      .login-logo {
        height: 58px;
        width: auto;
        margin: auto;
        margin-top: 100px;
        margin-bottom: 50px;
      }
    }
  }
}

@media (max-width: 1024px) {
}

@media (max-width: 991px) {
  .auth-layout .authMainContent .authSubContent .login-logo {
    margin-top: 120px;
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .auth-layout .authMainContent .authSubContent .login-logo {
    margin-top: 110px;
    margin-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .auth-layout .authMainContent .authSubContent h1 {
    margin-left: 0;
    text-align: center;
  }

  .auth-layout .authMainContent .authSubContent .login-logo {
    margin-top: 100px;
    margin-bottom: 30px;
  }
}
*/