.pageScreenContainer {
    .quizPage {
        width: 100%;
        height: 100%;
        min-height: calc(100vh - 90px);
        display: flex;
        align-items: center;

        @media only screen and (max-width: 1600px) {}

        @media only screen and (max-width: 1440px) {}

        @media only screen and (max-width: 1330px) {}

        @media only screen and (max-width: 1199px) {}

        @media only screen and (max-width: 991px) {}

        @media only screen and (max-width: 767px) {}

        @media only screen and (max-width: 639px) {}

        @media only screen and (max-width: 575px) {
            min-height: calc(100vh - 60px);
            display: block;
        }

        .topicLists {
            position: fixed;
            top: 90px;
            left: 70px;
            width: 20%;
            height: calc(100vh - 90px);
            -webkit-box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.15);
            -moz-box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.15);
            box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.15);
            padding: 0px 0px 0px 0px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            overflow: auto;

            @media only screen and (max-width: 991px) {
                left: 0px;
                top: 60px;
                height: calc(100vh - 60px);
            }

            @media only screen and (max-width: 575px) {
                position: relative;
                width: 100%;
                left: 0px;
                top: 0px;
                height: auto;
                min-height: auto;
            }

            h3.mainCategoryName {
                font-family: "Mulish", Arial, Helvetica, sans-serif;
                font-weight: 500;
                font-style: normal;
                font-size: 22px;
                line-height: 30px;
                margin: 0px;
                padding: 15px 20px;
                width: 100%;
                cursor: pointer;

                &:hover {
                    color: #FF9933;
                }

                .closeDrawerIcon {
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 30px;
                    margin-right: 10px;

                    &:hover {
                        color: #FF9933;
                    }
                }
            }

            .topicItems {
                list-style: none;
                list-style-type: none;
                padding: 10px 20px;

                @media only screen and (max-width: 575px) {
                    display: none;
                }

                .topicItem {
                    padding: 5px 0px;
                    color: #707070;
                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                    font-weight: 400;
                    font-style: normal;
                    font-size: 16px;
                    line-height: 20px;
                    cursor: pointer;
                    margin-bottom: 15px;
                    text-align: left;

                    @media only screen and (max-width: 1600px) {}

                    @media only screen and (max-width: 1440px) {}

                    @media only screen and (max-width: 1330px) {}

                    @media only screen and (max-width: 1199px) {
                        padding: 10px 10px;
                        font-size: 16px;
                        line-height: 20px;
                    }

                    @media only screen and (max-width: 991px) {}

                    @media only screen and (max-width: 767px) {}

                    @media only screen and (max-width: 639px) {}

                    @media only screen and (max-width: 575px) {}

                    &.active {
                        color: #FF9933;
                        font-weight: 500;
                    }

                    &:hover {
                        color: #FF9933;
                        font-weight: 500;
                    }
                }
            }
        }

        .mobileCategoryListings {
            padding: 10px 20px 5px;
            display: none;

            @media only screen and (max-width: 575px) {
                display: block;
            }

            .categoryTitle {
                color: #000080;
                font-family: "Mulish", Arial, Helvetica, sans-serif;
                font-weight: 500;
                font-style: normal;
                font-size: 20px;
                line-height: 40px;
                margin: 0px 0px 5px 0px;

                @media only screen and (max-width: 1199px) {
                    font-size: 18px;
                    line-height: 30px;
                }
            }
        }

        .quizItemLists {
            margin-left: calc(20% + 18px);
            height: 100%;
            min-height: calc(100vh - 90px);
            padding: 0px 0px 20px 0px;
            width: 100%;

            @media only screen and (max-width: 575px) {
                margin-left: 0px;
                min-height: auto;
            }

            .pageTitle {
                width: 100%;
                padding: 15px 20px;
                color: #000080;
                font-family: "Mulish", Arial, Helvetica, sans-serif;
                font-weight: 500;
                font-style: normal;
                font-size: 22px;
                line-height: 30px;
                margin: 0px;

                @media only screen and (max-width: 1199px) {
                    font-size: 24px;
                    line-height: 30px;
                }
            }

            .quizItemListing {
                padding: 0px 20px;

                .loadMore {
                    padding-top: 25px;
                    padding-bottom: 25px;
                    text-align: center;
                }

                .quizItemWrapper {
                    padding-top: 15px;
                    padding-bottom: 15px;

                    .quizItemBox {
                        background-color: #FFFFFF;
                        -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.20);
                        -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.20);
                        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.20);
                        padding: 15px;
                        border-radius: 10px;
                        color: #707070;
                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                        font-size: 14px;
                        line-height: 20px;
                        @media only screen and (max-width: 1199px) {
                            height: 100%;
                            justify-content: space-between;
                            display: flex;
                            flex-direction: column;
                        }
                        &.Completed {
                            cursor: text;
                            pointer-events: none;
                        }

                        .quizItemTitle {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            flex-direction: row;

                            .qTitle {
                                color: #000080;
                                font-family: "Mulish", Arial, Helvetica, sans-serif;
                                font-weight: 500;
                                font-style: normal;
                                font-size: 20px;
                                line-height: 30px;
                                cursor: pointer;

                                @media only screen and (max-width: 991px) {
                                    font-size: 16px;
                                    line-height: 20px;
                                }
                            }

                            .qDate {
                                color: #707070;
                            }
                        }

                        .qPoints {
                            display: block;
                            color: #707070;
                            padding: 5px 0px 0px 0px;
                        }

                        .qStatus {
                            display: block;
                            color: #707070;
                            padding: 15px 0px 0px 0px;
                            text-transform: capitalize;

                            &.Completed {
                                color: #138808;
                            }

                            &.Pending {
                                color: #DA2835;
                            }
                        }

                        h4 {
                            text-align: center;
                            color: #FFFFFF;
                            margin: 0px;
                            font-size: 22px;
                            line-height: 32px;
                        }

                        h3 {
                            text-align: right;
                            color: #FFFFFF;
                            margin: 0px;
                            font-size: 30px;
                            line-height: 30px;
                        }

                        p {
                            margin: 0px;
                        }
                    }
                }
            }
        }
    }
}
