.notification-section {
    display: block;
    margin-top: 50px;
    margin-bottom: 71px;

    .container {
        max-width: 1640px;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;

        @media screen and (max-width:1699px) {
            max-width: 1400px;
        }
    }

    .some-text-notification {
        display: block;

        @media screen and (max-width: 575px) {
            padding-bottom: 20px;
        }

        h4 {
            font-size: 42px;
            line-height: 50px;
            color: #FF9933;
            font-weight: bold;
            margin: 0px;
            font-family: "Mulish", Arial, Helvetica, sans-serif;

            @media screen and (max-width: 1199px) {
                font-size: 34px;
                line-height: 40px;
            }

            @media screen and (max-width: 767px) {
                font-size: 30px;
                line-height: 40px;
            }

            @media screen and (max-width: 575px) {
                font-size: 24px;
                line-height: 30px;
            }
        }

        h3 {
            font-size: 50px;
            line-height: 60px;
            color: #000080;
            font-weight: bold;
            font-family: "Mulish", Arial, Helvetica, sans-serif;
            margin-bottom: 0px;

            @media screen and (max-width: 1199px) {
                font-size: 42px;
            }

            @media screen and (max-width: 767px) {
                font-size: 35px;
            }

            @media screen and (max-width: 575px) {
                font-size: 30px;
            }
        }
    }
}
