.popupWrapper {
    &.ACEGameCertificate {
        height: 300px;
        max-height: 100%;
        width: 350px;

        .popUpInner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .modal-heading {
                color: #000080;
                font-family: 'Mulish', sans-serif;
                font-size: 18px;
                font-weight: 600;
                padding: 25px 0px;
                margin: 0px;
                text-align: center;
            }

            .modalContent {
                text-align: center;

                p {
                    margin: 0px 0px 10px 0px;
                    font-family: 'Mulish', sans-serif;
                    font-weight: 400;
                    font-style: normal;
                    font-size: 16px;
                    line-height: 22px;
                    color: #505050;
                    text-align: center;
                }

                h3 {
                    margin: 0px 0px 10px 0px;
                    font-family: 'Mulish', sans-serif;
                    font-size: 18px;
                    line-height: 30px;
                    font-weight: 500;
                    text-align: center;
                }
            }
        }
    }
}
