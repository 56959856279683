.popupWrapper {
    &.Auth {
        &.SignUp {
            .popUpInner {
                .lTab {
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }

                    &.Active {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
