.Button {
  cursor: pointer;
  height: 73px;
  width: 93%;
  opacity: 1;
  background-color: blue;
  border: 2px solid #ee332e;
  border-radius: 50px 50px;
  margin: 5px 0px 0 23px;
  color: #f2f2f2;
  font-size: 19px;
  line-height: 50px;
  outline: none;
  font-family: "Raleway", Arial, Helvetica, sans-serif;

  .loading-con {
    margin-left: 5px;
  }
}

.Button:hover {
  background-color: #4090ce;
  border: 2px solid #4090ce;
}

.Button:disabled {
  cursor: not-allowed;
}

.Button:disabled:hover {
  background-color: #ee332e;
  border: 2px solid #ee332e;
  color: #f2f2f2;
  cursor: not-allowed;
}

.Success {
  color: #5c9210;
}

.Danger {
  color: #944317;
}

/* --- Responsive Styles Starts --- */

@media (max-width: 991px) {
  .Button {
    width: 309px;
  }
}

@media (max-width: 767px) {
  .Button {
    width: 326px;
    margin: 5px 0px 0 8px;
  }
}

@media (max-width: 575px) {
  .Button {
    width: 276px;
    height: 62px;
    margin: 5px 0px 0 10px;
  }
}
