.Instruction h4{
    color: rgb(165, 163, 163);
    margin-bottom: 0px;
}
.Instruction p{
    font-size: 13px;
    line-height: 20px;
    display: flex;
}

.Instruction .fixed-bottom{
    display: flex;
    padding: 11px 78px;
    justify-content: space-between;
    background-color: #fff;
    align-items: center;
}
.Instruction a{
    color: #ff9933;
    margin-left: 10px;
}
.Instruction img{
 max-width: 13px;
 width: 100%;
}
.Instruction button{
    background-color: #ff9933;
    color: #fff;
    font-weight: 500;
}
@media screen and(max-width:640px) {
    .Instruction .fixed-bottom{
        margin-bottom: 38px;
        padding: 6px 15px;
    }
}