.leftSideMenu {
    width: 70px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    flex: 0 0 auto;
    flex-direction: column !important;
    display: flex !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    -webkit-box-shadow: 3px 1px 6px 0px rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 3px 1px 6px 0px rgba(0, 0, 0, 0.19);
    box-shadow: 3px 1px 6px 0px rgba(0, 0, 0, 0.19);
    z-index: 30;
    outline: none;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;

    &.open {
        width: 250px;
        overflow: visible;
    }

    @media only screen and (max-width: 991px) {
        width: 0px;
        overflow: hidden;

        &.open {
            width: 250px;
            overflow: visible;
        }
    }

    .hamburgerIcon {
        height: 90px;
        width: 100%;
        padding: 25px 15px;
        display: block;
        text-align: center;

        @media only screen and (max-width: 991px) {
            //display: none;
            height: 60px;
            padding: 10px 15px;
        }

        .hamburgerImage {
            height: 35px;
            width: 35px;
            cursor: pointer;

            @media only screen and (max-width: 991px) {
                height: 25px;
                width: 25px;
            }
        }

        .closeIconWrapper {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .closeDrawerIcon {
                color: #000080;
                margin-left: 5px;
                cursor: pointer;
            }

            .bharatCETIcon {
                height: 40px;
                width: auto;
                margin-left: 20px;
            }
        }
    }

    .sideMenusContainer {
        height: auto;
        min-height: -moz-calc(100vh - 90px);
        min-height: -webkit-calc(100vh - 90px);
        min-height: -o-calc(100vh - 90px);
        min-height: calc(100vh - 90px);
        padding: 15px 15px;
        overflow: auto;

        li {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            list-style: none;
            list-style-type: none;
            margin: 0px 0px 40px 0px;
            min-height: min-content;

            &.show {
                justify-content: flex-start;
            }

            @media only screen and (max-width: 991px) {
                margin: 0px 0px 20px 0px;
            }

            .menuIcon {
                height: 35px;
                width: 35px;
                cursor: pointer;

                @media only screen and (max-width: 1330px) {
                    height: 30px;
                    width: 30px;
                }

                @media only screen and (max-width: 1199px) {
                    height: 25px;
                    width: 25px;
                }

                @media only screen and (max-width: 991px) {
                    height: 25px;
                    width: 25px;
                }
            }

            .menuTitle {
                &.hide {
                    display: none;
                }

                cursor: pointer;
                position: relative;
                text-decoration: none;
                -webkit-transition: all .1s linear;
                transition: all .1s linear;
                color: #000080;
                font-family: 'Mulish',
                Arial,
                Helvetica,
                sans-serif;
                font-size: 18px;
                line-height: 22px;
                margin-left: 20px;
                outline: none;

                &:hover {
                    color: #FF9933;
                }
            }
            
            .active {
                color: #FF9933;
            }
        }
    }

}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}