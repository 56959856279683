.popupWrapper {
    &.ACEGameTimeUp {
        height: 300px;
        min-height: 300px;
        max-height: 100%;
        width: 300px;

        .popUpInner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .featuredIconWrapper {
                padding: 0px 0px 20px 0px;

                .featuredIcon {
                    height: 120px;
                    width: auto;
                }
            }

            .modal-heading {
                color: #000080;
                font-family: 'Mulish', sans-serif;
                font-size: 30px;
                font-weight: 500;
                padding: 25px 0px;
                margin: 0px;
                text-align: center;
            }

            .modalContent {
                text-align: center;
            }
        }
    }
}
