.certificatePage {
    background-color: #FFFFFF;

    .certificateRow {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        align-items: center;
        justify-content: center;
        padding: 20px;

        @media only screen and (max-width:991px) {
            flex-direction: column;
        }

        @media only screen and (max-width:576px) {
            padding: 15px;
        }

        .certificate {
            background-color: #FFFFFF;
            width: 75%;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 8px;
            position: relative;
            padding: 25px 40px;
            z-index: 1;

            @media only screen and (max-width:1440px) {
                padding: 25px 30px;
            }

            @media only screen and (max-width:991px) {
                width: 100%;
            }

            @media only screen and (max-width:576px) {
                padding: 20px 15px;
            }

            &::before {
                content: '';
                position: absolute;
                height: 100%;
                width: 60%;
                background-image: url(../../../../assets/images/certificate/dispersedScholrshipBG.png);
                right: 0;
                top: 0;
                background-position: center left;
                background-repeat: no-repeat;
                z-index: -1;

                @media only screen and (max-width:767px) {
                    background-position: 5% 45%;
                }
            }

            .logo {
                margin-bottom: 30px;

                img {
                    max-width: 180px;

                    @media only screen and (max-width:1440px) {
                        max-width: 160px;
                    }

                    @media only screen and (max-width:1199px) {
                        max-width: 150px;
                    }

                    @media only screen and (max-width:767px) {
                        max-width: 130px;
                    }

                    @media only screen and (max-width:576px) {
                        max-width: 100px;
                    }
                }
            }

            h2 {
                font-family: 'BaroqueTextJF';
                text-align: center;
                font-size: 88px;
                line-height: 140px;

                @media only screen and (max-width:1440px) {
                    font-size: 60px;
                    line-height: 85px;
                }

                @media only screen and (max-width:1199px) {
                    font-size: 50px;
                    line-height: 82px;
                }

                @media only screen and (max-width:767px) {
                    font-size: 40px;
                    line-height: 70px;
                }

                @media only screen and (max-width:576px) {
                    font-size: 38px;
                    line-height: 60px;
                }
            }

            p {
                font-size: 20px;
                line-height: 30px;
                color: #414141;
                text-align: center;
                font-weight: 500;
                margin: 0px;

                @media only screen and (max-width:1440px) {
                    font-size: 18px;
                    line-height: 30px;
                }

                @media only screen and (max-width:767px) {
                    font-size: 16px;
                    line-height: 22px;
                }

                @media only screen and (max-width:576px) {
                    font-size: 14px;
                    line-height: 18px;
                }
            }

            h3 {
                font-size: 44px;
                line-height: 56px;
                font-weight: 500;
                color: #000080;
                text-align: center;
                margin: 25px 0px 15px;

                @media only screen and (max-width:1440px) {
                    font-size: 40px;
                    line-height: 52px;
                    margin: 20px 0px 10px;
                }

                @media only screen and (max-width:1199px) {
                    font-size: 36px;
                    line-height: 46px;
                }

                @media only screen and (max-width:767px) {
                    font-size: 30px;
                    line-height: 40px;
                }

                @media only screen and (max-width:576px) {
                    font-size: 28px;
                    line-height: 36px;
                    margin: 15px 0px 10px;
                }
            }

            .acePointDetail {
                p {
                    font-size: 20px;
                    line-height: 30px;
                    color: #414141;
                    font-weight: 400;
                    margin: 0px;

                    @media only screen and (max-width:1440px) {
                        font-size: 18px;
                        line-height: 26px;
                    }

                    @media only screen and (max-width:1199px) {
                        font-size: 16px;
                        line-height: 24px;
                    }

                    @media only screen and (max-width:767px) {
                        font-size: 15px;
                        line-height: 22px;
                    }

                    @media only screen and (max-width:576px) {
                        font-size: 14px;
                        line-height: 20px;
                    }

                    span {
                        color: #000;
                        font-weight: bold;
                    }
                }

                .level {
                    font-size: 30px;
                    line-height: 42px;
                    font-weight: bold;
                    color: #138808;
                    text-align: center;
                    margin-top: 15px;

                    @media only screen and (max-width:1440px) {
                        font-size: 26px;
                        line-height: 32px;
                    }

                    @media only screen and (max-width:1199px) {
                        font-size: 24px;
                        line-height: 28px;
                        margin-top: 12px;
                    }

                    @media only screen and (max-width:767px) {
                        font-size: 22px;
                        margin-top: 10px;
                    }
                }
            }

            .extraInfo {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;

                @media only screen and (max-width:767px) {
                    align-items: center;
                    flex-direction: column;
                }

                .awardIcon {
                    margin-top: -40px;

                    @media only screen and (max-width:1199px) {
                        margin-top: -20px;
                    }

                    @media only screen and (max-width:767px) {
                        margin-top: 30px;
                    }

                    img {
                        max-width: 200px;

                        @media only screen and (max-width:1440px) {
                            max-width: 180px;
                        }

                        @media only screen and (max-width:1199px) {
                            max-width: 150px;
                        }

                        @media only screen and (max-width:767px) {
                            max-width: 120px;
                        }
                    }

                    p {
                        margin: 15px 0px;
                        font-size: 18px;
                        line-height: 26px;
                        color: #000080;
                        text-decoration: underline;

                        @media only screen and (max-width:1440px) {
                            font-size: 16px;
                            line-height: 24px;
                        }

                        @media only screen and (max-width:1199px) {
                            font-size: 15px;
                            line-height: 20px;
                        }

                        @media only screen and (max-width:767px) {
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }
                }
            }

            .siteInfo {
                p {
                    font-size: 18px;
                    line-height: 26px;
                    font-weight: 400;
                    color: #414141;
                    margin: 0px;

                    @media only screen and (max-width:1440px) {
                        font-size: 16px;
                        line-height: 22px;
                    }

                    @media only screen and (max-width:1199px) {
                        font-size: 15px;
                        line-height: 20px;
                    }

                    @media only screen and (max-width:767px) {
                        font-size: 14px;
                        line-height: 20px;
                    }

                    a {
                        color: #414141;
                    }

                    span {
                        font-weight: bold;
                    }
                }
            }

            .copyRightSection {
                margin-top: 20px;

                @media only screen and (max-width:767px) {
                    margin-top: 30px;
                }

                ul.socialIcons {
                    float: left;

                    @media only screen and (max-width:767px) {
                        float: none;
                        text-align: center;
                        margin-bottom: 10px;
                    }

                    li {
                        display: inline;
                        margin-right: 30px;

                        @media only screen and (max-width:1440px) {
                            margin-right: 20px;
                        }
                    }
                }

                p {
                    float: right;
                    font-size: 18px;
                    margin: 0px;

                    @media only screen and (max-width:1440px) {
                        font-size: 16px;
                    }

                    @media only screen and (max-width:1199px) {
                        font-size: 15px;
                    }

                    @media only screen and (max-width:767px) {
                        float: none;
                        font-size: 14px;
                    }
                }
            }
        }

        .actions {
            width: 25%;
            padding: 20px 50px;

            @media only screen and (max-width:1440px) {
                padding: 20px 33px;
            }

            @media only screen and (max-width:1199px) {
                padding: 20px 20px;
            }

            @media only screen and (max-width:991px) {
                width: 100%;
                text-align: center;
            }

            p {
                font-size: 26px;
                line-height: 36px;
                color: #414141;
                padding-top: 5em;
                margin: 0px;

                @media only screen and (max-width:1440px) {
                    font-size: 24px;
                    line-height: 36px;
                    padding-top: 3em;
                }

                @media only screen and (max-width:1199px) {
                    font-size: 22px;
                    line-height: 34px;
                }

                @media only screen and (max-width:991px) {
                    padding-top: 2em;
                }

                @media only screen and (max-width:767px) {
                    font-size: 20px;
                    line-height: 30px;
                }

                @media only screen and (max-width:576px) {
                    font-size: 18px;
                    line-height: 26px;
                }
            }

            .saveBtn {
                text-align: center;

                a {
                    font-size: 30px;
                    line-height: 40px;
                    color: #FFFFFF;
                    background-color: #FF9933;
                    border-radius: 38px;
                    padding: 12px 60px;
                    display: inline-block;
                    cursor: pointer;

                    @media only screen and (max-width:1440px) {
                        font-size: 22px;
                        line-height: 30px;
                        padding: 10px 48px;
                    }

                    @media only screen and (max-width:1199px) {
                        font-size: 20px;
                        line-height: 26px;
                        padding: 10px 46px;
                    }

                    @media only screen and (max-width:576px) {
                        font-size: 18px;
                        line-height: 24px;
                        padding: 10px 40px;
                    }
                }


            }

            ul.socialIcons {
                margin-top: 20px;
                padding: 0px;
                list-style: none;

                @media only screen and (max-width:1440px) {
                    margin-top: 15px;
                }

                @media only screen and (max-width:1199px) {
                    margin-right: 15px;
                }

                li {
                    display: inline-block;
                    margin-right: 25px;
                    height: 30px;
                    width: 30px;

                    @media only screen and (max-width:1440px) {
                        margin-right: 20px;
                        height: 36px;
                        width: 36px;
                    }

                    @media only screen and (max-width:1199px) {
                        margin-right: 16px;
                        height: 32px;
                        width: 32px;
                    }

                    @media only screen and (max-width:576px) {
                        margin-right: 15px;
                        height: 28px;
                        width: 28px;
                    }

                    span {
                        img {
                            max-width: 30px;
                        }
                    }

                    &:last-child {
                        margin-right: 0px;
                    }
                }
            }
        }
    }
}