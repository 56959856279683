.btn-container {
  cursor: pointer;
  border-radius: 54px 54px;
  height: 54px;
  width: 100%;
  padding: 0px;
  margin: auto;

  .Button {
    background: #FFFFFF;
    color: #FF9933;
    border: 1px solid #FF9933;
    border-radius: 54px 54px;
    font-size: 15px;
    font-weight: 900;
    height: 54px;
    margin: 0px;
    padding: 0px;
    outline: none;
    text-align: center;
    text-transform: uppercase;
    font-family: "Mulish", Arial, Helvetica, sans-serif;
    width: 100%;

    &.Orange {
      background: #FF9933;
      color: #FFFFFF;
      border: 1px solid #FF9933;

      &:hover {
        background-color: #f77c00;
        border: 1px solid #f77c00;
      }

      &:disabled {
        cursor: not-allowed;

        &:hover {
          background-color: #f77c00;
          border: 1px solid #f77c00;
          color: #FFFFFF;
          cursor: not-allowed;
        }
      }
    }

    &.White {
      background: #FFFFFF;
      color: #FF9933;
      border: 1px solid #FF9933;
    }

    &.Success {
      color: #5c9210;
    }

    &.Danger {
      color: #944317;
    }

    .loading-con {
      margin-left: 5px;
    }
  }
}

/* --- Responsive Styles Starts --- */

@media (max-width: 991px) {
  .btn-container {
    width: 86%;

    .Button {
      width: 99%;
      height: 99%;
    }
  }
}

@media (max-width: 767px) {
  .btn-container {
    width: 90%;

    .Button {
      width: 99%;
      height: 99%;
    }
  }
}

@media (max-width: 575px) {
  .btn-container {
    width: 90%;

    .Button {
      width: 99%;
      height: 99%;
    }
  }
}
