.claimOffer {
    min-height: calc(100vh);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .loginOnClaim {
        padding: 5px 0px;
        width: 360px;
        margin: 0px auto;
        text-align: center;            

        h3 {
            text-align: center;
            padding: 20px 0px;
        }

        .nfLogo {
            width: 150px;
            margin: 0px auto;
            display: block;
        }

        .celebrationIcon{
            margin-top: 40px;
        }

        .claim-info {
            display: block;
            margin: 10px 0px;
            color: #414141;
                font-family: "Mulish", Arial, Helvetica, sans-serif;
                font-weight: 400;
                font-style: normal;
                font-size: 20px;
                line-height: 30px;
                margin: 15px 0px 20px 0px;
        }

        .claim-go {
            display: block;
            margin: 10px 0px;
            background-color: #FF9933;
                color: #FFFFFF;
                font-family: "Mulish", Arial, Helvetica, sans-serif;
                font-weight: 500;
                font-style: normal;
                font-size: 20px;
                line-height: 40px;
                padding: 0px 20px;
                border: 1px solid #ff9933;
                border-radius: 20px;
                cursor: pointer;
                &:hover {
                    border: 1px solid #d8832e;
                    background-color: #d8832e;
                }
        }

    }
}



.claimOfferBtn{
    background-color: #FF9933;
    padding: 8px;
    border-radius: 18px;
    margin-top: 15px;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
}