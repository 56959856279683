.pageScreenContainer {
    .myPage {
        .myPageWrapper {
            &.myEvents {
                .calendarMonthWrapper {
                    display: -webkit-flex;
                    display: -ms-flexbox;
                    display: flex;
                    /*
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-start;
                    */

                    .calenderMonthItem {
                        width: 100%;
                        margin: 0px 10px;
                        flex: 1;

                        .monthView {
                            background: #ffffff;
                            border: 1px solid #ffffff;
                            border-radius: 5px;
                            -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.5);
                            -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.5);
                            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.5);
                        }

                        .monthListing {
                            background: #ffffff;
                            border: 1px solid #ffffff;
                            border-radius: 5px;
                            -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.5);
                            -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.5);
                            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.5);
                            padding: 10px 6px;
                            height: 100%;

                            .listingItem {
                                margin: 0px 0px 15px 0px;
                                padding: 0px 3px;

                                .heading {
                                    color: #000080;
                                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                                    font-weight: 500;
                                    font-style: normal;
                                    font-size: 16px;
                                    line-height: 30px;

                                    &.dates {
                                        margin-right: 20px;
                                    }

                                    &.events {
                                        margin-left: 0px;
                                    }
                                }

                                .eventDate {
                                    margin-right: 11px;
                                    color: #FF9933;
                                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                                    font-weight: 400;
                                    font-style: normal;
                                    font-size: 16px;
                                    line-height: 30px;
                                }

                                .eventTitle {
                                    margin-left: 0px;
                                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                                    font-weight: 400;
                                    font-style: normal;
                                    font-size: 16px;
                                    line-height: 30px;
                                }

                                &.active {
                                    background-color: #FF9933;
                                    border: 1px solid #FF9933;
                                    border-radius: 5px;

                                    .eventDate {
                                        color: #FFFFFF;
                                    }

                                    .eventTitle {
                                        color: #FFFFFF;
                                    }
                                }

                                &:last-child {
                                    margin-bottom: 0px;
                                }
                            }
                        }
                    }
                }

                .loadMore {
                    padding-top: 25px;
                    padding-bottom: 25px;
                    text-align: center;
                }

                .fileItem {
                    background: #ffffff;
                    border: 1px solid rgba(220, 220, 220, 1);
                    border-radius: 10px;

                    .fileThumb {
                        height: 150px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        background: #ffffff;
                        border-bottom: 1px solid rgba(220, 220, 220, 1);
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;

                        img {
                            height: auto;
                            width: 85px;
                        }
                    }

                    .fileDesc {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        padding: 5px 10px;

                        .title {
                            color: #000080;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 400;
                            font-style: normal;
                            font-size: 14px;
                            line-height: 20px;
                            cursor: pointer;

                            &:hover {
                                color: #c5902e;
                            }
                        }

                        .icon {
                            cursor: pointer;
                            color: #000080;
                            margin-left: 10px;

                            .download {
                                cursor: pointer;
                                color: #000080;

                                &:hover {
                                    color: #c5902e;
                                }

                            }
                        }
                    }
                }
            }
        }

        .myContactRequestWrapper {
            width: 400px;
            padding: 20px;

            @media only screen and (max-width: 1330px) {
                width: 400px;
            }

            @media only screen and (max-width: 1199px) {
                width: 400px;
            }

            @media only screen and (max-width: 991px) {
                width: 400px;
            }

            @media only screen and (max-width: 767px) {
                width: 400px;
            }

            @media only screen and (max-width: 639px) {
                width: 400px;
            }

            @media only screen and (max-width: 575px) {
                width: 100%;
            }

        }
    }
}