.popupWrapper {
    &.freeTrialExpiredACEGame {
        .popUpInner {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .modal-heading {
                color: #000080;
                font-family: 'Mulish', sans-serif;
                font-size: 22px;
                font-weight: 400;
                padding: 25px 0px;
                margin: 0px;
                text-align: center;
            }

            .featuredIconWrapper {
                padding: 0px 0px 20px 0px;

                .featuredIcon {
                    height: 100px;
                    width: auto;
                }
            }

            .modalContent {
                text-align: center;
                padding: 15px 0px 15px;

                .feature {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: flex-start;
                    padding: 10px 0px;

                    .featureDesc {
                        flex: 1;

                        p {
                            margin: 0px 0px 0px 0px;
                            font-family: 'Mulish', sans-serif;
                            font-weight: 400;
                            font-style: normal;
                            font-size: 18px;
                            line-height: 22px;
                            color: #414141;
                            text-align: center;
                        }
                    }
                }
            }

            .buttonContainer {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .btn-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    align-items: center;

                    .SmallButton {
                        flex: 1;

                        &.Orange {
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }
}
