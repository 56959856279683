.pageScreenContainer {
    .myPage {
        &.ScoreBoard {

            .titleRow {
                display: flex;
                display: -webkit-box;
                display: -ms-flexbox;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                @media only screen and (max-width:576px) {
                    display: block;
                }

                h1 {
                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                    font-weight: 500;
                    font-style: normal;
                    font-size: 30px;
                    line-height: 61px;
                    color: #000080;
                    width: 30%;

                    @media only screen and (max-width:1440px) {
                        font-size: 30px;
                        line-height: 52px;
                    }

                    @media only screen and (max-width:991px) {
                        font-size: 30px;
                        line-height: 48px;
                    }

                    @media only screen and (max-width:767px) {
                        font-size: 28px;
                        line-height: 40px;
                    }

                    @media only screen and (max-width:576px) {
                        width: 100%;
                        margin-bottom: 10px;
                        font-size: 18px;
                        line-height: 28px;
                    }
                }

                .actionLinks {
                    width: 70%;
                    text-align: right;

                    @media only screen and (max-width:576px) {
                        width: 100%;
                        text-align: start;
                    }

                    span {
                        display: inline-block;
                        text-align: center;
                        background-color: #f93;
                        border: 1px solid #f93;
                        font-family: 'Mulish', sans-serif;
                        font-weight: 400;
                        font-size: 22px;
                        color: #fff;
                        line-height: 24px;
                        padding: 14px 64px;
                        border-radius: 100px;
                        text-decoration: none;
                        cursor: pointer;

                        @media only screen and (max-width:1440px) {
                            font-size: 18px;
                            line-height: 24px;
                            padding: 10px 50px;
                        }

                        @media only screen and (max-width:991px) {
                            font-size: 16px;
                            line-height: 24px;
                            padding: 8px 46px;
                        }

                        @media only screen and (max-width:767px) {
                            font-size: 15px;
                            line-height: 24px;
                            padding: 8px 30px;
                        }

                        &.redeemLink {
                            background-color: #fff;
                            border: 1px solid #f93;
                            color: #f93;
                            font-weight: 500;
                        }

                        &.playLink {
                            margin-right: 60px;

                            @media only screen and (max-width:1440px) {
                                margin-right: 40px;
                            }

                            @media only screen and (max-width:991px) {
                                margin-right: 34px;
                            }

                            @media only screen and (max-width:767px) {
                                margin-right: 15px;
                            }
                        }
                    }
                }
            }

            .myPageWrapper {
                &.LeaderBoard {
                    .pointsSection {

                        .custom-container {
                            width: 100%;
                        }

                        .title {
                            h3 {
                                font-size: 28px;
                                font-style: normal;
                                line-height: 28px;
                                color: #151515;
                                font-weight: 500;
                                margin-bottom: 10px;

                                @media only screen and (max-width:1440px) {
                                    font-size: 22px;
                                    line-height: 28px;
                                }

                                @media only screen and (max-width:576px) {
                                    font-size: 16px;
                                    line-height: 26px;
                                    margin-top: 13px;
                                    margin-bottom: 13px;
                                }
                            }
                        }

                        .pointsArea {
                            padding: 10px 0px 60px;

                            @media only screen and (max-width:576px) {
                                padding: 5px 0px 18px;
                            }

                            .level {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                margin-bottom: 15px;

                                @media only screen and (max-width:767px) {
                                    flex-direction: column;
                                    margin-bottom: 25px;
                                }

                                @media only screen and (max-width:576px) {
                                    flex-direction: column;
                                    margin-bottom: 10px;
                                }

                                .studentNo {
                                    width: 275px;
                                    text-align: right;

                                    @media only screen and (max-width:1440px) {
                                        width: 218px;
                                    }

                                    @media only screen and (max-width:991px) {
                                        width: 207px;
                                    }

                                    @media only screen and (max-width:767px) {
                                        width: 100%;
                                        text-align: right;
                                        padding: 3px 0px 10px;
                                    }

                                    @media only screen and (max-width:576px) {
                                        width: 100%;
                                        text-align: right;
                                        padding: 3px 0px 3px;
                                    }

                                    .studentText {
                                        font-weight: bold;
                                        display: none;
                                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                                        font-weight: 500;
                                        font-style: normal;

                                        @media only screen and (max-width:767px) {
                                            font-size: 22px;
                                            line-height: 22px;
                                            display: inline-block;
                                        }

                                        @media only screen and (max-width:576px) {
                                            font-size: 16px;
                                            line-height: 20px;
                                        }
                                    }

                                    p {
                                        color: #414141;
                                        font-size: 18px;
                                        line-height: 17px;
                                        margin-bottom: 5px;

                                        @media only screen and (max-width:1440px) {
                                            font-size: 14px;
                                        }

                                        @media only screen and (max-width:576px) {
                                            font-size: 13px;
                                        }
                                    }

                                    h4 {
                                        width: 180px;
                                        font-size: 30px;
                                        line-height: 37px;
                                        font-weight: bold;
                                        opacity: 1;
                                        margin-bottom: 0px;
                                        padding-top: 32px;

                                        @media only screen and (max-width:1440px) {
                                            font-size: 28px;
                                            line-height: 34px;
                                            width: 150px;
                                        }

                                        @media only screen and (max-width:991px) {
                                            font-size: 24px;
                                            line-height: 24px;
                                            width: 135px;
                                        }

                                        @media only screen and (max-width:767px) {
                                            font-size: 22px;
                                            line-height: 22px;
                                            width: 100%;
                                            padding-top: 0px;
                                        }

                                        @media only screen and (max-width:576px) {
                                            font-size: 20px;
                                            line-height: 20px;
                                        }

                                    }

                                }

                                .pointsDesc {
                                    box-shadow: 0px 3px 6px #00000029;
                                    border-radius: 8px;
                                    opacity: 1;
                                    padding: 5px 8px;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;

                                    @media only screen and (max-width:1440px) {
                                        padding: 8px 8px;
                                    }

                                    @media only screen and (max-width:991px) {}
                                }

                                .details {
                                    @media only screen and (max-width:767px) {
                                        margin-left: auto;
                                    }

                                    .pointsDesc {
                                        .points {
                                            font-size: 22px;
                                            line-height: 28px;
                                            color: #141414;

                                            @media only screen and (max-width:1440px) {
                                                font-size: 20px;
                                                line-height: 24px;
                                            }

                                            @media only screen and (max-width:991px) {
                                                font-size: 16px;
                                                line-height: 20px;
                                            }

                                            @media only screen and (max-width:767px) {
                                                font-size: 14px;
                                                line-height: 18px;
                                            }

                                            @media only screen and (max-width:576px) {
                                                font-size: 13px;
                                                line-height: 16px;
                                            }
                                        }

                                        .price {
                                            font-size: 30px;
                                            line-height: 40px;
                                            font-weight: 500;
                                            color: #0C0C0C;

                                            @media only screen and (max-width:1440px) {
                                                font-size: 26px;
                                                line-height: 36px;
                                            }

                                            @media only screen and (max-width:991px) {
                                                font-size: 22px;
                                                line-height: 32px;
                                            }

                                            @media only screen and (max-width:767px) {
                                                font-size: 22px;
                                                line-height: 32px;
                                            }

                                            @media only screen and (max-width:576px) {
                                                font-size: 20px;
                                                line-height: 20px;
                                            }
                                        }

                                        .student {
                                            .User {
                                                font-size: 24px;
                                                line-height: 30px;
                                                padding-left: 15px;
                                                width: auto !important;

                                                @media only screen and (max-width:1440px) {
                                                    font-size: 24px;
                                                    line-height: 30px;
                                                    padding-left: 10px;
                                                }

                                                @media only screen and (max-width:991px) {
                                                    font-size: 24px;
                                                    line-height: 30px;
                                                    padding-left: 5px;
                                                }

                                                @media only screen and (max-width:576px) {
                                                    font-size: 20px;
                                                    line-height: 26px;
                                                }
                                            }

                                            .name {
                                                font-size: 24px;
                                                line-height: 30px;
                                                font-weight: 500;
                                                color: #0C0C0C;
                                                margin-left: 15px;

                                                @media only screen and (max-width:1440px) {
                                                    font-size: 24px;
                                                    line-height: 30px;
                                                }

                                                @media only screen and (max-width:991px) {
                                                    font-size: 20px;
                                                    line-height: 24px;
                                                }

                                                @media only screen and (max-width:576px) {
                                                    font-size: 18px;
                                                    line-height: 20px;
                                                }
                                            }
                                        }
                                    }

                                    .levelName {
                                        font-size: 26px;
                                        line-height: 36px;
                                        font-weight: 500;
                                        margin-bottom: 3px;

                                        @media only screen and (max-width:1440px) {
                                            font-size: 24px;
                                            line-height: 32px;
                                        }

                                        @media only screen and (max-width:991px) {
                                            font-size: 20px;
                                            line-height: 20px;
                                        }

                                        @media only screen and (max-width:767px) {
                                            font-size: 18px;
                                            line-height: 18px;
                                            display: none;
                                        }

                                        @media only screen and (max-width:576px) {
                                            font-size: 16px;
                                            line-height: 16px;
                                        }
                                    }
                                }


                                &.masterLevel {
                                    .details {
                                        width: 400px;
                                        text-align: start;

                                        @media only screen and (max-width:1440px) {
                                            width: 300px;
                                        }

                                        @media only screen and (max-width:1199px) {
                                            width: 290px;
                                        }

                                        @media only screen and (max-width:991px) {
                                            width: 230px;
                                        }

                                        @media only screen and (max-width:767px) {
                                            width: 35%;
                                        }

                                        @media only screen and (max-width:576px) {
                                            width: 50%;
                                        }

                                        .levelName {
                                            color: #6E5AF6;
                                        }
                                    }

                                    .pointsDesc {
                                        background-color: #B1A6FA;
                                    }

                                    .studentNo {
                                        h4 {
                                            color: #6E5AF6;
                                        }
                                    }
                                }

                                &.expertLevel {
                                    .details {
                                        width: 555px;
                                        text-align: start;

                                        @media only screen and (max-width:1440px) {
                                            width: 430px;
                                        }

                                        @media only screen and (max-width:1199px) {
                                            width: 385px;
                                        }

                                        @media only screen and (max-width:991px) {
                                            width: 280px;
                                        }

                                        @media only screen and (max-width:767px) {
                                            width: 43%;
                                        }

                                        @media only screen and (max-width:576px) {
                                            width: 57%;
                                        }

                                        .levelName {
                                            color: #B5D591;
                                        }
                                    }

                                    .pointsDesc {
                                        background-color: #B5D591;
                                    }

                                    .studentNo {
                                        h4 {
                                            color: #B5D591;
                                        }
                                    }
                                }

                                &.advanceLevel {
                                    .details {
                                        width: 720px;
                                        text-align: start;

                                        @media only screen and (max-width:1440px) {
                                            width: 580px;
                                        }

                                        @media only screen and (max-width:1199px) {
                                            width: 485px;
                                        }

                                        @media only screen and (max-width:991px) {
                                            width: 350px;
                                        }

                                        @media only screen and (max-width:767px) {
                                            width: 53%;
                                        }

                                        @media only screen and (max-width:576px) {
                                            width: 68%;
                                        }

                                        .levelName {
                                            color: #AACBF7;
                                        }
                                    }

                                    .pointsDesc {
                                        background-color: #AACBF7;
                                    }

                                    .studentNo {
                                        h4 {
                                            color: #AACBF7;
                                        }
                                    }
                                }

                                &.intermediateLevel {
                                    .details {
                                        width: 905px;
                                        text-align: start;

                                        @media only screen and (max-width:1440px) {
                                            width: 750px;
                                        }

                                        @media only screen and (max-width:1199px) {
                                            width: 635px;
                                        }

                                        @media only screen and (max-width:991px) {
                                            width: 445px;
                                        }

                                        @media only screen and (max-width:767px) {
                                            width: 68%;
                                        }

                                        @media only screen and (max-width:576px) {
                                            width: 78%;
                                        }

                                        .levelName {
                                            color: #D9C3DB;
                                        }
                                    }

                                    .pointsDesc {
                                        background-color: #D9C3DB;
                                    }

                                    .studentNo {
                                        h4 {
                                            color: #D9C3DB;
                                        }
                                    }
                                }

                                &.basicLevel {
                                    .details {
                                        width: 1050px;
                                        text-align: start;

                                        @media only screen and (max-width:1440px) {
                                            width: 870px;
                                        }

                                        @media only screen and (max-width:1199px) {
                                            width: 705px;
                                        }

                                        @media only screen and (max-width:991px) {
                                            width: 485px;
                                        }

                                        @media only screen and (max-width:767px) {
                                            width: 78%;
                                        }

                                        @media only screen and (max-width:576px) {
                                            width: 90%;
                                        }

                                        .levelName {
                                            color: #FF967F;
                                        }
                                    }

                                    .pointsDesc {
                                        background-color: #FF967F;
                                    }

                                    .studentNo {
                                        h4 {
                                            color: #FF967F;
                                        }
                                    }
                                }

                                &.beginnerLevel {
                                    .details {
                                        width: 1210px;
                                        text-align: start;

                                        @media only screen and (max-width:1440px) {
                                            width: 990px;
                                        }

                                        @media only screen and (max-width:1199px) {
                                            width: 925px;
                                        }

                                        @media only screen and (max-width:991px) {
                                            width: 680px;
                                        }

                                        @media only screen and (max-width:767px) {
                                            width: 92%;
                                        }

                                        @media only screen and (max-width:576px) {
                                            width: 98%;
                                        }

                                        .levelName {
                                            color: #C4C402;
                                        }
                                    }

                                    .pointsDesc {
                                        background-color: #FFFF9B;
                                    }

                                    .studentNo {
                                        h4 {
                                            color: #C4C402;
                                        }
                                    }
                                }

                            }
                        }
                    }
                }
            }


        }
    }
}