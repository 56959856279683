.pageScreenContainer {
    .myPage {
        .myPageWrapper {
            &.myPackagesDesktopView {
                padding-right: 50px;
                padding-left: 20px;
                
                @media only screen and (max-width: 991px) {
                    display: none;
                }

                .fileItemWrapper {
                    padding-bottom: 15px;
                    padding-top: 15px;
                }

                .packageHeader {
                    padding: 10px 0px;

                    .PackageDetails {
                        
                        color: #140e6e;
                        border-radius: 20px;
                        padding: 5px 20px;
                        cursor: pointer;

                        .prePackagePrice {
                            font-family: 'Mulish', sans-serif;
                            font-weight: bold;
                            font-style: normal;
                            font-size: 14px;
                            line-height: 30px;
                            color: #707070;
                            position: relative;
                            width: max-content;
                            margin: 0 auto;

                            &::before {
                                position: absolute;
                                content: "";
                                left: 0;
                                top: 38%;
                                right: 0;
                                border-top: 3px solid #f93;
                                                       
                                -webkit-transform: rotate(-5deg);
                                -moz-transform: rotate(-5deg);
                                -ms-transform: rotate(-5deg);
                                -o-transform: rotate(-5deg);
                                transform: rotate(-5deg);
                            }
                        }

                        .packageMonth {
                            font-family: 'Mulish', sans-serif;
                            font-weight: bold;
                            font-style: normal;
                            font-size: 26px;
                            line-height: 26px;
                            display: block;
                            color: #000080;
                            margin: 0px 0px 11px 0px;

                            @media only screen and (max-width: 1440px) {
                                font-size: 18px;
                                line-height: 24px;
                            }

                            @media only screen and (max-width: 1199px) {}

                            @media only screen and (max-width: 991px) {}

                            @media only screen and (max-width: 767px) {}

                            @media only screen and (max-width: 639px) {}

                            @media only screen and (max-width: 575px) {}
                        }

                        .packagePrice {
                            font-family: 'Mulish', sans-serif;
                            font-weight: bold;
                            font-style: normal;
                            font-size: 23px;
                            line-height: 20px;
                            display: block;
                            color: #000;

                            span{
                                font-size: 12px;
                            }

                            @media only screen and (max-width: 1440px) {
                                font-size: 14px;
                                line-height: 20px;
                            }

                            @media only screen and (max-width: 1199px) {}

                            @media only screen and (max-width: 991px) {}

                            @media only screen and (max-width: 767px) {}

                            @media only screen and (max-width: 639px) {}

                            @media only screen and (max-width: 575px) {}
                        }                     
                    }
                }

                .packageInfo {
                    padding: 0px;
                    font-family: 'Mulish', sans-serif;
                    position: relative;
                    
                    .packageFeatureNew {
                        padding: 10px 15px;
                    }
                
                    .featureAllowNotAllow {
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;

                        &::after {
                            content: "";
                            position: absolute;
                            top: 0;
                            width: 30px;
                            height: 100%;
                            z-index: 0;
                        }
                        &.leftShadow::after {
                            left: -29px;
                            background-image: url(../../../assets/images/v2/pricing/shadow-left.png);
                        }

                        &.rightShadow::after {
                            right: -29px;
                            background-image: url(../../../assets/images/v2/pricing/shadow-right.png);
                        }
                    }
                    
                    .subscriptionTitleContainer{
                        background-color: #f93;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-left: 1px solid #d7d3c0;

                        .subscribeNowTitle{
                            color: #ffffff;
                            cursor: pointer;

                            &:hover{
                                text-decoration: underline;
                            }
                        }
                    }

                    .PackageDesc {
                        color: #606060;
                        font-family: 'Mulish', sans-serif;
                        font-weight: 500;
                        font-style: normal;
                        font-size: 18px;
                        line-height: 24px;

                        @media only screen and (max-width: 1440px) {
                            font-size: 16px;
                            line-height: 26px;
                        }

                        @media only screen and (max-width: 1199px) {}

                        @media only screen and (max-width: 991px) {}

                        @media only screen and (max-width: 767px) {}

                        @media only screen and (max-width: 639px) {}

                        @media only screen and (max-width: 575px) {}
                    }

                    .PackageFeature {
                        color: #535353;
                        font-family: 'Mulish', sans-serif;
                        font-weight: bold;
                        font-style: normal;
                        font-size: 24px;
                        line-height: 34px;
                        margin: auto 0;

                        @media only screen and (max-width: 1440px) {
                            font-size: 20px;
                            line-height: 30px;
                        }

                        @media only screen and (max-width: 1199px) {}

                        @media only screen and (max-width: 991px) {}

                        @media only screen and (max-width: 767px) {}

                        @media only screen and (max-width: 639px) {}

                        @media only screen and (max-width: 575px) {}
                    }

                    .PackageStatus {
                        .Check {
                            color: #138808;
                            font-size: 30px;
                            line-height: 40px;
                            font-weight: 400;
                        }

                        .Times {
                            color: #FF0000;
                            font-size: 30px;
                            line-height: 40px;
                            font-weight: 400;
                        }
                    }
                }

                .noPadding{
                    padding: 0;
                }

                .oddCls {
                    background-color: #EFF6F9;
                }
            }

            &.myPackagesMobileView {
                display: none;

                @media only screen and (max-width: 991px) {
                    display: block;
                }

                .packageInfoMobile {
                    max-width: 150px;
                    margin: 0px auto;

                    .subscriptionTitleContainer{
                        background-color: #f93;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-left: 1px solid #d7d3c0;
                        height: 40px;

                        @media screen and (min-width: 320px){
                            border-bottom: 1px solid #fff;
                        }

                        .subscribeNowTitle{
                            font-family: 'Mulish', sans-serif;
                            color: #ffffff;
                            cursor: pointer;

                            &:hover{
                                text-decoration: underline;
                            }
                        }
                    }
                }

                .packageHeader {
                    padding: 0px 0px 20px 0px;

                    .PackageButton {
                        font-family: 'Mulish', sans-serif;
                        color: #140e6e;
                        border-radius: 20px;
                        padding: 5px 20px;
                        cursor: pointer;

                        .prePackagePrice {
                            font-family: 'Mulish', sans-serif;
                            font-weight: bold;
                            font-style: normal;
                            font-size: 14px;
                            line-height: 30px;
                            color: #707070;
                            position: relative;
                            width: max-content;
                            margin: 0 auto;

                            &::before {
                                position: absolute;
                                content: "";
                                left: 0;
                                top: 38%;
                                right: 0;
                                border-top: 3px solid #f93;
                                                       
                                -webkit-transform: rotate(-5deg);
                                -moz-transform: rotate(-5deg);
                                -ms-transform: rotate(-5deg);
                                -o-transform: rotate(-5deg);
                                transform: rotate(-5deg);
                            }
                        }

                        .packageMonth {
                            font-family: 'Mulish', sans-serif;
                            font-weight: bold;
                            font-style: normal;
                            font-size: 26px;
                            line-height: 26px;
                            display: block;
                            color: #000080;
                            margin: 0px 0px 11px 0px;

                            @media only screen and (max-width: 1440px) {
                                font-size: 18px;
                                line-height: 24px;
                            }

                            @media only screen and (max-width: 1199px) {}

                            @media only screen and (max-width: 991px) {}

                            @media only screen and (max-width: 767px) {}

                            @media only screen and (max-width: 639px) {}

                            @media only screen and (max-width: 575px) {}
                        }

                        .packagePrice {
                            font-family: 'Mulish', sans-serif;
                            font-weight: bold;
                            font-style: normal;
                            font-size: 23px;
                            line-height: 20px;
                            display: block;
                            color: #000;

                            span{
                                font-size: 12px;
                            }

                            @media only screen and (max-width: 1440px) {
                                font-size: 14px;
                                line-height: 20px;
                            }

                            @media only screen and (max-width: 1199px) {}

                            @media only screen and (max-width: 991px) {}

                            @media only screen and (max-width: 767px) {}

                            @media only screen and (max-width: 639px) {}

                            @media only screen and (max-width: 575px) {}
                        }
                    }
                }

                .packageInfoItem {
                    -webkit-box-shadow: 0px 0px 8px 1px rgba(170, 170, 170, 0.7);
                    -moz-box-shadow: 0px 0px 8px 1px rgba(170, 170, 170, 0.7);
                    box-shadow: 0px 0px 8px 1px rgba(170, 170, 170, 0.7);
                    border-radius: 10px;
                    padding: 15px;
                    margin-bottom: 20px;

                    .packageFeatureWrapper {
                        padding-bottom: 7px;
                        margin-bottom: 7px;
                        border-bottom: 1px solid rgba(170, 170, 170, 0.5);

                        .packageFeature {
                            color: #535353;
                            font-family: 'Mulish', sans-serif;
                            font-weight: 500;
                            font-style: normal;
                            font-size: 25px;
                            line-height: 35px;

                            @media only screen and (max-width: 1440px) {
                                font-size: 20px;
                                line-height: 30px;
                            }
                        }
                    }

                    .packageDescWrapper {
                        padding-bottom: 7px;

                        .packageDesc {
                            color: #606060;
                            font-family: 'Mulish', sans-serif;
                            font-weight: 400;
                            font-style: normal;
                            font-size: 20px;
                            line-height: 30px;

                            @media only screen and (max-width: 1440px) {
                                font-size: 16px;
                                line-height: 26px;
                            }

                        }
                    }


                    .packageNameDesc {
                        display: flex;
                        justify-content: flex-start;
                        flex-direction: row;
                        width: 100%;
                        align-items: center;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        border-top: 1px solid rgba(170, 170, 170, 0.2);

                        .packageName {
                            color: #535353;
                            font-family: 'Mulish', sans-serif;
                            font-weight: 500;
                            font-style: normal;
                            font-size: 26px;
                            line-height: 50px;
                            min-width: 200px;

                            @media only screen and (max-width: 1440px) {
                                font-size: 20px;
                                line-height: 30px;
                            }
                        }

                        .packageStatus {
                            .Check {
                                color: #138808;
                                font-size: 30px;
                                line-height: 40px;
                                font-weight: 400;
                            }

                            .Times {
                                color: #C61A1A;
                                font-size: 30px;
                                line-height: 40px;
                                font-weight: 400;
                            }
                        }
                    }
                }

            }
        }
    }
}