.input-container {
  margin-bottom: 15px;

  .input-error {
    margin-right: 0px;
    margin-left: 20px;
    margin-top: 5px;
    margin-bottom: 0px;
    color: red;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    font-family: "Mulish", Arial, Helvetica, sans-serif;
  }
}

.TextInput {
  position: relative;
  height: 54px;
  width: 100%;
  opacity: 1;
  background-color: #FFFFFF;
  border: 1px solid#E5E5E5;
  border-radius: 50px 50px;
  -webkit-border-radius: 50px 50px;
  -moz-border-radius: 50px 50px;
  -o-border-radius: 50px 50px;
  margin: auto;
  font-family: "Mulish", Arial, Helvetica, sans-serif;

  &.Disabled {
    border: 1px solid#E5E5E5;
  }

  .inputIcon {
    position: absolute;
    right: 20px;
    top: 15px;
    font-size: 20px;
    line-height: 20px;
    color: #8A8A8A;
    cursor: pointer;
  }
}

.InputElement {
  width: 100%;
  height: 52px;
  margin-top: 0px;
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
  border-radius: 52px 52px;
  padding: 0px 20px;
  font-size: 15px;
  line-height: 15px;
  color: #0d1624;
  font-weight: 400;
  font-family: "Mulish", Arial, Helvetica, sans-serif;

  &.Disabled {
    border: 1px solid #FFFFFF;
  }
}

.InputElement::placeholder {
  font-size: 15px;
  line-height: 20px;
  color: #5f5f83;
  font-weight: 400;
  font-family: "Mulish", Arial, Helvetica, sans-serif;
}

.InputElement::-moz-placeholder {
  /* Firefox 19+ */
  line-height: 40px;
}

.Invalid {
  color: red;
}

/* --- Responsive Styles Starts --- */

@media (max-width: 1200px) {
  .TextInput {
    width: 92%;
    margin-bottom: 15px;
  }

  .InputElement {
    width: 90%;
  }
}

@media (max-width: 991px) {
  .TextInput {
    width: 92%;
    margin-bottom: 15px;
  }

  .InputElement {
    width: 90%;
  }
}

@media (max-width: 767px) {
  .TextInput {
    width: 94%;
    margin-bottom: 15px;
  }

  .InputElement {
    width: 92%;
  }
}

@media (max-width: 575px) {
  .TextInput {
    width: 93%;
    height: 54px;
    margin-bottom: 15px;
  }

  .InputElement {
    width: 91%;
    height: 52px;
    font-size: 14px;
  }

  .InputElement::placeholder {
    font-size: 14px;
  }

  .InputElement::-moz-placeholder {
    /* Firefox 19+ */
    line-height: 52px;
  }
}
