.popupWrapper {

    &.AceQuizResult {
        height: auto;
        min-height: 400px;
        max-height: 100%;
        width: 300px;

        .popUpInner {
            text-align: center;

            .successActionIcon {
                max-width: 150px;
                padding: 30px 0px;
            }
        }
    }
}
