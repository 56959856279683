.verifyCertificatePage {
    background-color: #FFFFFF;
    min-height: 100vh;
    height: auto;

    .certificateRow {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        align-items: center;
        justify-content: center;
        padding: 20px;
        min-height: 100vh;
        height: auto;

        @media only screen and (max-width:991px) {
            flex-direction: column;
        }

        @media only screen and (max-width:576px) {
            padding: 15px;
        }

        .notFoundCertificate {
            text-align: center;

            img {
                max-width: 150px;
                padding: 0px 0px 20px 0px;
            }
        }

        .actions {
            width: 25%;
            padding: 20px 50px;

            @media only screen and (max-width:1440px) {
                padding: 20px 33px;
            }

            @media only screen and (max-width:1199px) {
                padding: 20px 20px;
            }

            @media only screen and (max-width:991px) {
                width: 100%;
                text-align: center;
            }

            p {
                font-size: 30px;
                line-height: 37px;
                color: #444444;
                padding-top: 5em;
                margin: 0px;

                @media only screen and (max-width:1440px) {
                    font-size: 24px;
                    line-height: 36px;
                    padding-top: 3em;
                }

                @media only screen and (max-width:1199px) {
                    font-size: 22px;
                    line-height: 34px;
                }

                @media only screen and (max-width:991px) {
                    padding-top: 2em;
                }

                @media only screen and (max-width:767px) {
                    font-size: 20px;
                    line-height: 30px;
                }

                @media only screen and (max-width:576px) {
                    font-size: 18px;
                    line-height: 26px;
                }
            }

            .saveBtn {
                text-align: center;

                a {
                    font-size: 30px;
                    line-height: 40px;
                    color: #FFFFFF;
                    background-color: #FF9933;
                    border-radius: 38px;
                    padding: 12px 60px;
                    display: inline-block;
                    cursor: pointer;

                    @media only screen and (max-width:1440px) {
                        font-size: 22px;
                        line-height: 30px;
                        padding: 10px 48px;
                    }

                    @media only screen and (max-width:1199px) {
                        font-size: 20px;
                        line-height: 26px;
                        padding: 10px 46px;
                    }

                    @media only screen and (max-width:576px) {
                        font-size: 18px;
                        line-height: 24px;
                        padding: 10px 40px;
                    }
                }


            }

            ul.socialIcons {
                margin-top: 20px;
                list-style: none;

                @media only screen and (max-width:1440px) {
                    margin-top: 15px;
                }

                @media only screen and (max-width:1199px) {
                    margin-right: 15px;
                }

                li {
                    display: inline-block;
                    margin-right: 25px;
                    height: 30px;
                    width: 30px;

                    @media only screen and (max-width:1440px) {
                        margin-right: 20px;
                        height: 36px;
                        width: 36px;
                    }

                    @media only screen and (max-width:1199px) {
                        margin-right: 16px;
                        height: 32px;
                        width: 32px;
                    }

                    @media only screen and (max-width:576px) {
                        margin-right: 15px;
                        height: 28px;
                        width: 28px;
                    }

                    span {
                        img {
                            max-width: 30px;
                        }
                    }

                    &:last-child {
                        margin-right: 0px;
                    }
                }
            }
        }
    }
}