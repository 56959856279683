.popupWrapper {
    &.PlayACEGame {
        height: 300px;
        max-height: 100%;

        .popUpInner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .modalContent {
                text-align: center;
            }

            p {
                margin: 0px 0px 20px 0px;
                font-family: 'Mulish', sans-serif;
                font-weight: 400;
                font-style: normal;
                font-size: 20px;
                line-height: 30px;
                color: #000080;
                text-align: center;
            }
        }
    }
}
