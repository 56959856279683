.pageScreenContainer {
    .myPage {
        .myPageWrapper {
            &.myHome {

                .pageTitle {
                    h1, h3 {
                        margin: 0px 0px 5px 0px;
                    }

                    h1 {
                        @media only screen and (max-width: 575px) {
                            font-size: 20px;
                            line-height: 30px;
                        }
                    }

                    h3 {
                        @media only screen and (max-width: 575px) {
                            font-size: 16px;
                            line-height: 26px;
                        }
                    }
                }

                .bannerAds {
                    background: #476ECB;
                    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                    -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                    padding: 20px;
                    border-radius: 10px;
                    margin: 0px 0px 15px 0px;

                    h2 {
                        color: #FFFFFF;
                        margin: 0px 0px 10px 0px;
                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                        font-weight: 400;
                        font-style: normal;
                        font-size: 40px;
                        line-height: 50px;

                        @media only screen and (max-width: 767px) {
                            font-size: 30px;
                            line-height: 40px;
                        }

                        @media only screen and (max-width: 575px) {
                            font-size: 20px;
                            line-height: 30px;
                        }
                    }

                    h4 {
                        color: #FFFFFF;
                        margin: 0px 0px 10px 0px;
                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                        font-weight: 400;
                        font-style: normal;
                        font-size: 20px;
                        line-height: 30px;

                        @media only screen and (max-width: 575px) {
                            font-size: 16px;
                            line-height: 26px;
                        }
                    }

                    .actionBtn {
                        color: #476ECB;
                        background: #FFFFFF;
                        border: 1px solid #FFFFFF;
                        border-radius: 20px;
                        margin: 0px 0px 10px 0px;
                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                        font-weight: 400;
                        font-style: normal;
                        font-size: 18px;
                        line-height: 28px;
                        cursor: pointer;
                        display: inline-block;
                        padding: 10px 15px;

                        @media only screen and (max-width: 575px) {
                            font-size: 16px;
                            line-height: 26px;
                            padding: 7px 15px;
                        }

                        &:hover {
                            color: #FFFFFF;
                            background: #476ECB;
                            border: 1px solid #FFFFFF;
                        }
                    }

                    .adIcon {
                        position: relative;

                        .adImage {
                            position: relative;
                            max-height: 250px;
                            margin-top: -90px;

                            @media only screen and (max-width: 767px) {
                                margin-top: 10px;
                                max-height: 250px;
                            }

                            @media only screen and (max-width: 639px) {
                                margin-top: 15px;
                                max-height: 160px;
                            }
                        }
                    }
                }

                .SubjectWiseContainer {
                    background: #071024;
                    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                    -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                    padding: 20px;
                    margin: 0px 0px 15px 0px;

                    .catListWrapper{
                        width: 100%;
                        overflow-y: auto;
                        height: 250px;
                        padding: 10px 20px 10px;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        border-radius: 5px;
                        background: #FFFFFF;
                        -webkit-box-shadow: 0px 2px 5px 0px rgba(255, 255, 255, 0.15);
                        -moz-box-shadow: 0px 2px 5px 0px rgba(255, 255, 255, 0.15);
                        box-shadow: 0px 2px 5px 0px rgba(255, 255, 255, 0.15);

                        @media only screen and (max-width: 991px) {
                            height: 220px;
                        }

                        @media only screen and (max-width: 639px) {
                            padding: 10px 10px 10px;
                        }

                        h4 {
                            color: #000080;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 500;
                            font-style: normal;
                            font-size: 20px;
                            line-height: 30px;
                            margin: 0px 0px 5px;

                            @media only screen and (max-width: 639px) {
                                display: none;
                            }
    
                            @media only screen and (max-width: 575px) {
                                font-size: 16px;
                                line-height: 26px;
                            }
                        }

                        .catList {
                            margin: 0px;
                            padding: 0px;
    
                            li {
                                border: 1px solid #000080;
                                padding: 5px 7px 5px 5px;
                                color: #000080;
                                font-family: "Mulish", Arial, Helvetica, sans-serif;
                                font-style: normal;
                                font-size: 16px;
                                line-height: 18px;
                                cursor: pointer;
                                display: inline-block;
                                margin-right: 5px;
                                margin-bottom: 3px;
                                border-radius: 5px;

                                .arrowRightTopic {
                                    border: solid #000080;
                                    border-width: 0 2px 2px 0;
                                    display: inline-block;
                                    padding: 3px;
                                    transform: rotate(-45deg);
                                    -webkit-transform: rotate(-45deg);
                                }

                                &:hover {
                                    color: #F8820E;

                                    .arrowRightTopic {
                                        border: solid #F8820E;
                                        border-width: 0 2px 2px 0;
                                        display: inline-block;
                                        padding: 3px;
                                        transform: rotate(-45deg);
                                        -webkit-transform: rotate(-45deg);
                                    }
                                }
                            }
                        }
                    }

                    p {
                        margin: 0px 0px 20px 0px;
                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                        font-weight: 400;
                        font-style: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: #ffffff;
                    }

                    h2 {
                        color: #FFFFFF;
                        margin: 0px 0px 10px 0px;
                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                        font-weight: 600;
                        font-style: normal;
                        font-size: 30px;
                        line-height: 50px;

                        @media only screen and (max-width: 767px) {
                            font-size: 30px;
                            line-height: 40px;
                        }

                        @media only screen and (max-width: 575px) {
                            font-size: 20px;
                            line-height: 30px;
                        }
                    }

                    .actionBtn {
                        color: #476ECB;
                        background: #FFFFFF;
                        border: 1px solid #FFFFFF;
                        border-radius: 20px;
                        margin: 0px 0px 10px 0px;
                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                        font-weight: 400;
                        font-style: normal;
                        font-size: 18px;
                        line-height: 28px;
                        cursor: pointer;
                        display: inline-block;
                        padding: 10px 15px;

                        @media only screen and (max-width: 575px) {
                            font-size: 16px;
                            line-height: 26px;
                            padding: 7px 15px;
                        }

                        &:hover {
                            color: #FFFFFF;
                            background: #476ECB;
                            border: 1px solid #FFFFFF;
                        }
                    }

                    .adIcon {
                        position: relative;

                        .adImage {
                            position: relative;
                            max-height: 250px;
                            margin-top: -90px;

                            @media only screen and (max-width: 767px) {
                                margin-top: 10px;
                                max-height: 250px;
                            }

                            @media only screen and (max-width: 639px) {
                                margin-top: 15px;
                                max-height: 160px;
                            }
                        }
                    }
                }

                .categoryLists {
                    width: 100%;
                    padding: 10px 20px 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
       
                    @media only screen and (max-width: 639px) {
                        display: none;
                    }
        
                    @media only screen and (max-width: 575px) {
                        position: relative;
                        width: 100%;
                        left: 0px;
                        top: 0px;
                        height: auto;
                        min-height: auto;
                    }
                    
                    ul {
                        margin-top: 0;
                        margin-bottom: 0;
                    }

                    .categoryItems {
                        list-style: none;
                        list-style-type: none;
                        padding: 0px;
        
                        @media only screen and (max-width: 991px) {
                            margin: 0px;
                            padding: 0px 10px;
                        }
    
                        @media only screen and (max-width: 767px) {
                            margin: 0px;
                            padding: 0px 0px;
                        }
        
                        .categoryItem {
                            padding: 12px 15px;
                            color: #000080;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 500;
                            font-style: normal;
                            font-size: 16px;
                            line-height: 10px;
                            border: 1px solid transparent;
                            border-radius: 20px;
                            color: #FFFFFF;
                            cursor: pointer;
                            text-align: center;
                            display: inline-block;
                            margin-right: 5px;
    
                            @media only screen and (max-width: 767px) {
                                margin-right: 15px;
                            }
    
                            -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.15);
                            -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.15);
                            box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.15);
       
                            @media only screen and (max-width: 1199px) {
                                padding: 10px 10px;
                                font-size: 16px;
                                line-height: 20px;
                            }
        
                            &.active {
                                background-color: #F8820E;
                                color: #FFFFFF;
                            }
        
                            &:hover {
                                background-color: #F8820E;
                                color: #FFFFFF;
                            }
        
                            &:last-child {
                                margin-right: 0px;
                            }
                        }
                    }
                }

                .mobileCategoryListings {
                    margin-bottom: 15px;

                    h4 {
                        color: #F8820E;
                        margin: 0px 0px 10px 0px;
                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                        font-weight: 400;
                        font-style: normal;
                        font-size: 24px;
                        line-height: 30px;

                        @media only screen and (max-width: 767px) {
                            font-size: 20px;
                            line-height: 26px;
                        }

                        @media only screen and (max-width: 575px) {
                            font-size: 18px;
                            line-height: 24px;
                        }
                    }
                }

                .topicList{
                    padding: 10px;
                    width: 100%;
                    background-color: #fff;

                    .topicItemsContainer{
                        list-style: none;
                        list-style-type: none;
                        padding: 0px;
                        padding-left: 16px;
                        padding-right: 16px;
                        
                        h4 {
                            color: #000080;
                            font-weight: 500;
                        }
                        .ulItems {
                            overflow: auto;
                            height: 145px;
                            padding: 0;
                        }
                        .topicItems {
                            padding: 10px 0px 5px 4px;
                            color: #000080;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-style: normal;
                            font-size: 16px;
                            line-height: 10px;
                            border: 1px solid transparent;
                            border-radius: 20px;
                            cursor: pointer;
                            display: inline-block;
                            margin-right: 5px;
                        }
                    }
                }

                .homeDetails {
                    .aceGameView {
                        -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                        -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                        padding: 20px;
                        border-radius: 10px;
                        margin: 0px;

                        @media only screen and (max-width: 767px) {
                            padding: 20px 0px;
                            -webkit-box-shadow: unset;
                            -moz-box-shadow: unset;
                            box-shadow: unset;
                        }

                        @media only screen and (max-width: 575px) {
                            padding: 10px 0px;
                        }

                        .sTitle {
                            color: #000080;
                            margin: 0px 0px 10px 0px;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 500;
                            font-style: normal;
                            font-size: 26px;
                            line-height: 36px;

                            @media only screen and (max-width: 575px) {
                                font-size: 16px;
                                line-height: 26px;
                            }
                        }

                        .carousel-action {
                            position: absolute;
                            z-index: 10;  
                            top: 50%;
                            transform: translateY(-50%);
                            width: 25px;
                            padding: 0px 0px;
                            cursor: pointer;
                            background: #FFFFFF;
                            outline: 1px solid transparent;
                            border: 1px solid transparent;
                            color: #F8820E;
                            -webkit-box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.15);
                            -moz-box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.15);
                            box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.15);

                            @media only screen and (max-width: 767px) {
                                width: 20px;
                                padding: 0px 0px;
                            }
                          
                            &:first-child {
                              left: -30px;

                              @media only screen and (max-width: 767px) {
                                left: -30px;
                              }
                            }
                          
                            &:nth-child(2) {
                              right: -30px;

                              @media only screen and (max-width: 767px) {
                                right: -30px;
                              }
                            }
                          }
                          
                          .gameRangeWrapper {
                              position: relative;
                              margin: 0px 0px 15px 0px;
                              width: -moz-calc(100% - 60px);
                              width: -webkit-calc(100% - 60px);
                              width: -o-calc(100% - 60px);
                              width: calc(100% - 60px);
                              margin-left: 30px;
                            
                            .slick-slider {

                                .slick-arrow, .slick-prev {

                                    @media only screen and (min-width: 1199px) and (max-width: 1269px) {
                                        top: 35%;
                                    }
                                    @media only screen and (min-width: 992px) and (max-width: 1036px) {
                                        top: 35%;
                                    }

                                    
                                    @media only screen and (max-width: 320px) {
                                        top: 35%;
                                    }

                                    &::before {
                                        font-family: 'slick';
                                        font-size: 20px;
                                        line-height: 1;
                                        opacity: .75;
                                        color: #ff9933;
                                    }
                                }

                                .slick-list{
                                    text-align: center;

                                    .slick-slide{
                                        padding: 10px;
                                        cursor: pointer;

                                        &.slick-current{
                                            
                                            .rangeItem{
                                                background-color: #F8820E;
                                                color: #FFFFFF;
                                            }
                                        }

                                        .rangeItem {
                                            margin: 4px 2px;
                                            border: 1px solid transparent;
                                            border-radius: 5px;
                                            background-color: #FFFFFF;
                                            color: #000080;
                                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                                            font-weight: 500;
                                            font-style: normal;
                                            font-size: 22px;
                                            line-height: 50px;
                                            width: 100%;
                                            height: 50px;
                                            -webkit-box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.15);
                                            -moz-box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.15);
                                            box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.15);

                                            &.active {
                                                background-color: #F8820E;
                                                color: #FFFFFF;
                                            }
                            
                                            &:hover {
                                                background-color: #F8820E;
                                                color: #FFFFFF;
                                            }

                                            .rangeSingleItem{
                                                font-size: 18px;
                                            }
                                        }
                                    }
                                }
                            }
                          }
                          
                        .aceGameLists {
                            display: grid;
                            grid-template-columns: 90px 90px 90px 90px 90px;
                            grid-template-rows: 90px 90px 90px 90px;
                            grid-gap: 20px;
                            justify-content: space-between;
                            align-content: center;
                            text-align: center;
                            padding: 0px;
                            margin: 0px;

                            @media only screen and (max-width: 1330px) {
                                grid-template-columns: 70px 70px 70px 70px 70px;
                                grid-template-rows: 70px 70px 70px 70px;
                                grid-gap: 20px;
                            }

                            @media only screen and (min-width: 992px) and (max-width: 1070px) {
                                grid-template-columns: 63px 63px 63px 63px 63px;
                                grid-template-rows: 65px 65px 65px 65px;
                                grid-gap: 20px;
                            }

                            @media only screen and (max-width: 575px) {
                                grid-template-columns: 40px 40px 40px 40px 40px;
                                grid-template-rows: 40px 40px 40px 40px;
                                grid-gap: 20px;
                            }

                            @media only screen and (max-width: 379px) {
                                grid-template-columns: 40px 40px 40px 40px;
                                grid-template-rows: 40px 40px 40px 40px 40px;
                                grid-gap: 20px;
                            }

                            li {
                                height: 90px;
                                width: 90px;
                                border-radius: 10px;
                                background: #FFFFFF 0% 0% no-repeat padding-box;
                                color: #707070;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-weight: 500;
                                font-size: 24px;
                                line-height: 37px;
                                cursor: pointer;
                                align-self: center;
                                justify-self: center;
                                -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                                -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                                box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);

                                @media only screen and (max-width: 1330px) {
                                    height: 70px;
                                    width: 70px;
                                }

                                @media only screen and (max-width: 575px) {
                                    height: 40px;
                                    width: 40px;
                                }


                                &.completed {
                                    background-color: #FFFFFF;
                                    .aceItem {
                                        .examReport {
                                            max-height: 50px;
                                            width: auto;

                                            @media only screen and (max-width: 1330px) {
                                                max-height: 40px;
                                            }

                                            @media only screen and (max-width: 575px) {
                                                max-height: 24px;
                                            }
                                        }
                                    }
                                }

                                &.pending {
                                    background-color: #B0D7AC;
                                    .aceItem {
                                        .lockedExam {
                                            max-height: 35px;
                                            width: auto;

                                            @media only screen and (max-width: 1330px) {
                                                max-height: 40px;
                                            }

                                            @media only screen and (max-width: 575px) {
                                                max-height: 24px;
                                            }
                                        }
                                    }
                                }

                                &.current {
                                    background-color: #219B3B;
                                    .aceItem {
                                        .examNo {
                                            color: #FFFFFF;
                                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                                            font-weight: 500;
                                            font-style: normal;
                                            font-size: 26px;
                                            line-height: 36px;

                                            @media only screen and (max-width: 575px) {
                                                font-size: 20px;
                                                line-height: 30px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }


                    .categoryView {
                        -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                        -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                        padding: 20px;
                        border-radius: 10px;
                        margin: 0px;

                        @media only screen and (max-width: 639px) {
                            -webkit-box-shadow: unset;
                            -moz-box-shadow: unset;
                            box-shadow: unset;
                            padding: 5px 0px;
                            margin-top: 10px;
                        }

                        .sTitle {
                            color: #000080;
                            margin: 0px 0px 10px 0px;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 500;
                            font-style: normal;
                            font-size: 26px;
                            line-height: 36px;

                            @media only screen and (max-width: 639px) {
                                font-size: 22px;
                                line-height: 32px;
                            }

                            @media only screen and (max-width: 575px) {
                                font-size: 16px;
                                line-height: 26px;
                            }
                        }

                        .categoryItemWrapper {
                            padding-bottom: 15px;

                            .categoryItemView {
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-start;
                                cursor: pointer;
                                align-items: center;

                                @media only screen and (max-width: 639px) {
                                    flex-direction: column;
                                    justify-content: center;
                                }

                                @media only screen and (max-width: 1450px)  {
                                    flex-direction: column;
                                    justify-content: center;
                                }

                                .categoryTitleView {
                                    text-align: left;

                                    @media only screen and (min-width: 992px) and (max-width: 1085px) {
                                        text-align: center;
                                    }

                                    @media only screen and (min-width: 1199px) and (max-width: 1245px) {
                                        text-align: center;
                                    }

                                    @media only screen and (max-width: 639px) {
                                        text-align: center;
                                    }

                                    @media only screen and (max-width: 639px) {
                                        font-size: 18px;
                                        line-height: 28px;
                                    }

                                    @media only screen and (max-width: 575px) {
                                        font-size: 16px;
                                        line-height: 26px;
                                    }

                                    span {
                                        @media only screen and (max-width: 639px) {
                                            font-size: 18px;
                                            line-height: 28px;
                                        }

                                        @media only screen and (max-width: 575px) {
                                            font-size: 16px;
                                            line-height: 26px;
                                        }
                                        @media only screen and (min-width: 1199px) and (max-width: 1245px) {
                                            font-size: 20px;
                                            line-height: 25px;
                                        }
                                    }
                                }
                            }
                        }
                    } 
                    .quickAccessView {
                            -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                            -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                            box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                            padding: 5px 20px 10px 20px;
                            border-radius: 10px;
                            margin-top: 20px;

                            @media only screen and (max-width: 639px) {
                                -webkit-box-shadow: unset;
                                -moz-box-shadow: unset;
                                box-shadow: unset;
                                padding: 5px 0px;
                                margin-top: 10px;
                            }

                            @media only screen and (max-width: 575px) {
                                padding: 5px 0px;
                                margin-top: 0px;
                            }

                            .sTitle {
                                color: #000080;
                                margin: 0px 0px 10px 0px;
                                font-family: "Mulish", Arial, Helvetica, sans-serif;
                                font-weight: 500;
                                font-style: normal;
                                font-size: 26px;
                                line-height: 36px;

                                @media only screen and (max-width: 639px) {
                                    font-size: 22px;
                                    line-height: 32px;
                                }

                                @media only screen and (max-width: 575px) {
                                    font-size: 16px;
                                    line-height: 26px;
                                }
                            }

                            .featureLink {
                                -webkit-box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.15);
                                -moz-box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.15);
                                box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.15);
                                padding: 15px;
                                border-radius: 10px;
                                text-align: center;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                cursor: pointer;
                                margin-bottom: 20px;

                                .fIcon {
                                    display: block;
                                    max-height: 50px;
                                    width: auto;
                                    margin-bottom: 15px;
                                    cursor: pointer;
                                }

                                .fLink {
                                    display: block;
                                    cursor: pointer;
                                    color: #141414;
                                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                                    font-weight: 500;
                                    font-style: normal;
                                    font-size: 20px;
                                    line-height: 24px;

                                    @media only screen and (max-width: 575px) {
                                        font-size: 16px;
                                        line-height: 26px;
                                    }

                                    @media only screen and (min-width: 992px) and (max-width: 1071px) {
                                        font-size: 13px;
                                    }

                                    @media only screen and (min-width: 1057px) and (max-width: 1200px) {
                                        font-size: 15px;
                                    }

                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }
                            }
                    }
                }
            }
        }
    }
}