.pageScreenContainer {
    .categoryPage {
        width: 100%;
        height: 100%;
        min-height: calc(100vh - 90px);
        display: flex;
        align-items: center;

        @media only screen and (max-width: 1600px) {}

        @media only screen and (max-width: 1440px) {}

        @media only screen and (max-width: 1330px) {}

        @media only screen and (max-width: 1199px) {}

        @media only screen and (max-width: 991px) {}

        @media only screen and (max-width: 767px) {}

        @media only screen and (max-width: 639px) {}

        @media only screen and (max-width: 575px) {
            min-height: calc(100vh - 60px);
            display: block;
        }

        .topicLists {
            height: 100%;
            min-height: calc(100vh - 90px);
            padding: 0px 0px 20px 0px;
            width: 100%;

            @media only screen and (max-width: 575px) {
                margin-left: 0px;
                min-height: auto;
            }

            .pageTitle {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                padding: 0px 20px;

                @media only screen and (max-width: 639px) {
                    display: block;
                    padding: 20px 20px;
                }

                .pageHeading {

                    @media only screen and (max-width: 639px) {
                        display: block;
                    }

                    h3 {
                        color: #000080;
                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                        font-weight: 500;
                        font-style: normal;
                        font-size: 30px;
                        line-height: 40px;
                        margin: 0px;

                        @media only screen and (max-width: 1199px) {
                            font-size: 24px;
                            line-height: 30px;
                        }

                        @media only screen and (max-width: 576px) {
                            font-size: 18px;
                            line-height: 28px;
                        }
                    }
                }

                .categoryAccuracy {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    .accuracyTitle {
                        h4 {
                            color: #000080;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 400;
                            font-style: normal;
                            font-size: 20px;
                            line-height: 40px;
                            margin: 0px;

                            @media only screen and (max-width: 1199px) {
                                font-size: 18px;
                                line-height: 30px;
                            }
                        }
                    }

                    .accuracyChart {}
                }
            }

            .mobileCategoryListings {
                padding: 0px 20px 5px;
                display: none;

                @media only screen and (max-width: 639px) {
                    display: block;
                }

                .categoryTitle {
                    color: #000080;
                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                    font-weight: 500;
                    font-style: normal;
                    font-size: 20px;
                    line-height: 40px;
                    margin: 0px 0px 5px 0px;

                    @media only screen and (max-width: 1199px) {
                        font-size: 18px;
                        line-height: 30px;
                    }

                    @media only screen and (max-width: 576px) {
                        font-size: 18px;
                        line-height: 28px;
                    }
                }
            }

            .categoryLists {
                width: 100%;
                padding: 10px 20px 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
   
                @media only screen and (max-width: 639px) {
                    display: none;
                }
    
                @media only screen and (max-width: 575px) {
                    position: relative;
                    width: 100%;
                    left: 0px;
                    top: 0px;
                    height: auto;
                    min-height: auto;
                }
    
                .categoryItems {
                    list-style: none;
                    list-style-type: none;
                    padding: 0px;
    
                    @media only screen and (max-width: 991px) {
                        margin: 0px;
                        padding: 0px 10px;
                    }

                    @media only screen and (max-width: 767px) {
                        margin: 0px;
                        padding: 0px 0px;
                    }
    
                    .categoryItem {
                        padding: 15px 25px;
                        color: #000080;
                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                        font-weight: 500;
                        font-style: normal;
                        font-size: 24px;
                        line-height: 30px;
                        border: 1px solid transparent;
                        border-radius: 5px;
                        background-color: #FFFFFF;
                        cursor: pointer;
                        text-align: center;
                        display: inline-block;
                        margin-right: 30px;

                        @media only screen and (max-width: 767px) {
                            margin-right: 15px;
                        }

                        -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.15);
                        -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.15);
                        box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.15);
   
                        @media only screen and (max-width: 1199px) {
                            padding: 10px 10px;
                            font-size: 16px;
                            line-height: 20px;
                        }
    
                        &.active {
                            background-color: #F8820E;
                            color: #FFFFFF;
                        }
    
                        &:hover {
                            background-color: #F8820E;
                            color: #FFFFFF;
                        }
    
                        &:last-child {
                            margin-right: 0px;
                        }
                    }
                }
            }

            .topicItemListing {
                padding: 0px 20px;

                .legendItem {
                    font-size: 18px;
                    line-height: 18px;
                    display: inline-block;
                    font-weight: 400;
                    color: #414141;
                    padding: 5px 0px 15px;

                    @media only screen and (max-width: 576px) {
                        font-size: 15px;
                        padding: 5px 0px 8px;
                    }

                    .legendTile {
                        width: 15px;
                        height: 18px;
                        display: inline-block;
                        margin-right: 10px;

                        @media only screen and (max-width: 576px) {
                            width: 12px;
                            height: 15px;
                            margin-right: 5px;
                        }

                        &.blue {
                            background-color: #000080;
                            border: 1px solid #000080;
                        }
                        &.red {
                            background-color: #DA2835;
                            border: 1px solid #DA2835;
                        }
                        &.orange {
                            background-color: #F8820E;
                            border: 1px solid #F8820E;
                        }
                        &.green {
                            background-color: #219B3B;
                            border: 1px solid #219B3B;
                        }
                    }
                }

                .loadMore {
                    text-align: center;
                    padding: 20px 0px;
                }

                .topicItemWrapper {
                    padding-top: 15px;
                    padding-bottom: 15px;

                    @media only screen and (max-width: 575px) {
                        padding-top: 10px;
                        padding-bottom: 10px;    
                    }

                    .topicItem {
                        -webkit-box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.15);
                        -moz-box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.15);
                        box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.15);
                        padding: 0px;
                        border-radius: 10px;
                        color: #FFFFFF;
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;

                        .topicScoreCount {
                            width: 100px;
                            border-top-left-radius: 10px;
                            border-bottom-left-radius: 10px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;

                            @media only screen and (max-width: 767px) {
                                width: 80px;
                            }

                            @media only screen and (max-width: 575px) {
                                width: 70px;
                            }

                            span {
                                color: #FFFFFF;
                                margin: 0px;
                                font-size: 30px;
                                line-height: 42px;
                                font-weight: bold;

                                @media only screen and (max-width: 767px) {
                                    font-size: 26px;
                                    line-height: 30px;
                                }

                                @media only screen and (max-width: 575px) {
                                    font-size: 20px;
                                    line-height: 30px;
                                }
                            }

                            &.High {
                                background-color: #219B3B;
                                border: 1px solid #219B3B;
                            }
    
                            &.Low {
                                background-color: #DA2835;
                                border: 1px solid #DA2835;
                            }
    
                            &.Medium {
                                background-color: #F8820E;
                                border: 1px solid #F8820E;
                            }
    
                            &.NoData {
                                background-color: #000080;
                                border: 1px solid #000080;
                            }
                        }

                        .topicDesc {
                            flex: 1;
                            border-top-right-radius: 10px;
                            border-bottom-right-radius: 10px;
                            padding: 15px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: flex-start;

                            @media only screen and (max-width: 575px) {
                                padding: 10px;
                            }

                            .topicTitleSec {
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-start;
                                align-items: flex-start;
                                padding-bottom: 20px;
                                min-height: 35px;

                                .topicTitle {
                                    flex: 1;
                                    padding-right: 20px;
                                    word-break: break-word;

                                    @media only screen and (max-width: 767px) {
                                        padding-right: 0px;
                                    }

                                    h4 {
                                        text-align: left;
                                        color: #000080;
                                        margin: 0px;
                                        font-size: 22px;
                                        line-height: normal;

                                        @media only screen and (max-width: 1365px) {
                                            font-size: 16px;
                                        }

                                        @media only screen and (max-width: 1199px) {
                                            font-size: 22px;
                                        }

                                        @media only screen and (max-width: 991px) {
                                            font-size: 16px;
                                        }

                                        @media only screen and (max-width: 767px) {
                                            font-size: 18px;
                                        }
            
                                        @media only screen and (max-width: 575px) {
                                            font-size: 17px;
                                        }
                                    }
                                }
                                .youTubeLink {
                                    width: 50px;
                                    cursor: pointer;

                                    @media only screen and (max-width: 767px) {
                                        width: 30px;
                                    }

                                    .topicLinkItem {
                                        cursor: pointer;

                                        img {
                                            height: 35px;
                                            width: auto;

                                            @media only screen and (max-width: 767px) {
                                                height: 30px;
                                            }
                                        }
                                    }
                                }
                            }

                            .topicLinks {
                                display: block;
                                padding: 20px 0px 0px;
                                width: 100%;
                                
                                @media only screen and (max-width: 576px) {
                                    padding: 0px 0px 0px;
                                }
    
                                .topicLinkItem {
                                    cursor: pointer;
                                    width: 50%;
                                    display: inline-block;
                                    text-align: center;
                                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                                    font-weight: 400;
                                    font-style: normal;
                                    font-size: 16px;
                                    border: 1px solid #F8820E;
                                    border-radius: 5px;
                                    padding: 10px;
                                    position: relative;
                                    text-decoration: none;
                                    overflow: hidden;
                                    max-width: 150px;
                                    line-height: normal;

                                    @media only screen and (max-width: 1365px) { 
                                        font-size: 13px;
                                        padding: 10px 5px;
                                    }

                                    @media only screen and (max-width: 1199px) { 
                                        font-size: 16px;
                                        padding: 10px;
                                    }

                                    @media only screen and (max-width: 991px) { 
                                        font-size: 13px;
                                        padding: 10px 5px;
                                    }

                                    @media only screen and (max-width: 767px) {
                                        width: 100%;
                                        display: block;
                                        font-size: 16px;
                                        padding: 10px;
                                    }

                                    @media only screen and (max-width: 575px) {
                                        width: 50%;
                                        display: inline-block;
                                    }
                                    @media only screen and (max-width: 425px) {
                                        width: 100%;
                                        display: block;
                                    }

                                    &.left {
                                        transition: all 0.2s linear 0s;
                                        background-color: #FFFFFF;
                                        color: #212121;

                                        &:hover {
                                            text-decoration: underline;
                                        }
                                    }
    
                                    &.right {
                                        transition: all 0.2s linear 0s;
                                        background-color: #F8820E;
                                        color: #FFFFFF;

                                        &:hover {
                                            text-decoration: underline;
                                        }

                                        @media only screen and (max-width: 767px) {
                                            margin-top: 5px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
