.pageScreenContainer {
    .myPage {
        .pageTitle {
            .goBackViewFile {
                font-size: 20px;
                line-height: 40px;
                margin-right: 15px;
                cursor: pointer;
                vertical-align: middle;
            }
        }

        .myPageWrapper {
            &.fileView {

                .reactPdfDocument {
                    height: auto;
                    text-align: center;
                }
            }
        }
    }
}