.pageScreenContainer {
    .myPage {
        .myPageWrapper {
            &.myLibrary {

                .ExamCategoryTabs {
                    display: block;
                    width: 100%;
                
                    ul.NavTabs {
                        display: inline-block;
                        width: 100%;
                        padding-left: 20px;
                        margin-bottom: 2rem;

                        @media only screen and (max-width: 767px) {
                            padding-left: 4px;
                        }
                                
                        li.item {
                            cursor: pointer;
                            list-style: none;
                            list-style-type: none;
                            margin: 0px 30px 5px 0px;
                            display: inline-block;
        
                            @media only screen and (max-width: 767px) {
                                margin: 0px 20px;
                            }
        
                            @media only screen and (max-width: 660px) {
                                margin: 0px 13px;
                            }
        
                            @media only screen and (max-width: 575px) {
                                margin: 0px 10px;
                            }
        
                            &::after {
                                content: '';
                                display: block;
                                width: 0;
                                height: 4px;
                                background: #FF9933;;
                                transition: all ease .3s;
                                opacity: 0;
                            }
        
                            &:hover {
                                &::after {
                                    /* width: 100%; */
                                }
                            }
        
                            &.active {
                                &::after {
                                    width: 100%;
                                    opacity: 1;
                                }
                            }
        
                            span {
                                display: inline-block;
                                text-decoration: none;
                                color: #141414;
                                font-family: "Mulish", Arial, Helvetica, sans-serif;
                                font-weight: 600;
                                font-style: normal;
                                font-size: 18px;
                                line-height: 30px;
        
                                @media only screen and (max-width: 991px) {
                                    font-size: 20px;
                                    line-height: 35px;
                                }
        
                                @media only screen and (max-width: 767px) {
                                    font-size: 17px;
                                    line-height: 30px;
                                }
        
                                @media only screen and (max-width: 575px) {
                                    font-size: 15px;
                                    line-height: 35px;
                                }
                            }
                        }
                    }
                }
                
                .cards {
                    margin: 0 auto;
                    display: grid;
                    grid-gap: 1rem;

                    @media (min-width: 320px) {
                     grid-template-columns: repeat(1, 1fr); 
                    }

                    @media (min-width: 360px) {
                        grid-template-columns: repeat(2, 1fr); 
                    }

                    @media (min-width: 575px) {
                        grid-template-columns: repeat(3, 1fr); 
                    }
                    
                    @media (min-width: 767px) {
                        grid-template-columns: repeat(4, 1fr); 
                    }
                    
                    /* Screen larger than 991px? 4 columns */
                    @media (min-width: 991px) {
                     grid-template-columns: repeat(5, 1fr); 
                    }

                    /* Screen larger than 900px? 5 columns */
                    @media (min-width: 1199px) {
                        grid-template-columns: repeat(6, 1fr); 
                    }

                    .card {
                        color: #232020;
                        padding: 1rem;
                        height: 160px;
                        background: #FFFFFF 0% 0% no-repeat padding-box;
                        box-shadow: 0px 20px 30px #93939359;
                        border: 1px solid #FFFFFF;
                        border-radius: 8px;
                        opacity: 1;
                        text-align: center;
                        letter-spacing: 0px;
                        justify-content: center;
                        cursor: pointer;

                        @media (max-width: 1500px) {
                            height: 125px;
                        }
                        @media (max-width: 1199px) {
                            height: 115px;
                        }
                        @media (max-width: 991px) {
                            height: 110px;
                        }
                        @media (max-width: 767px) {
                            height: 105px;
                        }
                        @media (max-width: 575px) {
                            height: 100px;
                        }
                        

                        &:hover{
                            background: #FF9933;
                            color: #ffffff;
                        }

                        span {
                            font-size: 18px;
                            font-weight: 600;

                            @media (max-width: 1200px) {
                                font-size: 16px;
                            }

                            @media (max-width: 991px) {
                                font-size: 15px;
                            }

                            @media (max-width: 767px) {
                                font-size: 14px;
                            }

                            @media (max-width: 360px) {
                                font-size: 13px;
                            }
                        }
                      }

                }
                
                  
                  

                .fileItemWrapper {
                    padding-bottom: 15px;
                    padding-top: 15px;
                }

                .loadMore {
                    padding-top: 25px;
                    padding-bottom: 25px;
                    text-align: center;
                }

                .fileItem {
                    background: #ffffff;
                    border: 1px solid rgba(220, 220, 220, 1);
                    border-radius: 10px;

                    .fileThumb {
                        height: 150px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        background: #ffffff;
                        border-bottom: 1px solid rgba(220, 220, 220, 1);
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;

                        img {
                            height: auto;
                            width: 85px;
                        }
                    }

                    .fileDesc {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        padding: 5px 10px;

                        .title {
                            color: #000080;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 400;
                            font-style: normal;
                            font-size: 14px;
                            line-height: 20px;
                            cursor: pointer;

                            &:hover {
                                color: #c5902e;
                            }
                        }

                        .icon {
                            cursor: pointer;
                            color: #000080;
                            margin-left: 10px;

                            .download {
                                cursor: pointer;
                                color: #000080;

                                &:hover {
                                    color: #c5902e;
                                }

                            }
                        }
                    }
                }
            }
        }
    }
}