.userLayout {
  flex: 1 1;
  -ms-flex: 1 0 0px;
  flex-direction: column !important;
  display: flex !important;
  background-color: #ffffff;

  .userLayoutContainer {
    display: flex !important;
    flex-direction: row !important;
    flex: 1 0 auto;

    .userPageContainer {
      padding-left: 70px;
      flex: 1 1 auto;
      -ms-flex: 1 0 0px;
      min-width: 0;
      flex-direction: column !important;
      display: block;
      width: -moz-calc(100% - 70px);
      width: -webkit-calc(100% - 70px);
      width: -o-calc(100% - 70px);
      width: calc(100% - 70px);
      height: auto;
      min-height: 100vh;
      z-index: 20;

      @media only screen and (max-width: 1600px) {
        padding-left: 70px;
        width: -moz-calc(100% - 70px);
        width: -webkit-calc(100% - 70px);
        width: -o-calc(100% - 70px);
        width: calc(100% - 70px);
      }

      @media only screen and (max-width: 991px) {
        padding-left: 0px;
        width: -moz-calc(100% - 0px);
        width: -webkit-calc(100% - 0px);
        width: -o-calc(100% - 0px);
        width: calc(100% - 0px);
      }

      .userPage {
        width: 100%;
        margin-top: 90px;
        height: -moz-calc(100% - 90px);
        height: -webkit-calc(100% - 90px);
        height: -o-calc(100% - 90px);
        height: calc(100% - 90px);

        @media only screen and (max-width: 991px) {
          margin-top: 60px;
          height: -moz-calc(100% - 60px);
          height: -webkit-calc(100% - 60px);
          height: -o-calc(100% - 60px);
          height: calc(100% - 60px);
        }

        @media only screen and (max-width: 639px) {
          height: -moz-calc(100% - 100px);
          height: -webkit-calc(100% - 100px);
          height: -o-calc(100% - 100px);
          height: calc(100% - 100px);
          margin-bottom: 40px;
        }
      }

      .footerNavigationBar {
        position: fixed;
        height: 40px;
        width: 100%;
        bottom: 0px;
        left: 0px;
        right: 0px;
        font-size: 0px;
        -webkit-box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.15);
        box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.15);
        display: none;
        z-index: 10001;

        @media only screen and (max-width: 639px) {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }

        .nItem {
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 1;
          text-align: center;
          background-color: #FFFFFF;
          height: 40px;

          color: #000080;
          font-family: "Mulish", Arial, Helvetica, sans-serif;
          font-weight: 500;
          font-style: normal;
          font-size: 26px;
          line-height: 36px;
          z-index: 10000;

          &.between {
            border-left: 1px solid #CDCDCD;
            border-right: 1px solid #CDCDCD;
          }

          .nIcon {
            max-height: 28px;
            padding: 0px 0px;
            z-index: 9999;
          }
        }
      }
    }
  }
}
