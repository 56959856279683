/* Dynamic Dashboard Start Here  */
.useful-feature {
    overflow: hidden;

    .container {
        max-width: 1640px;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;

        @media screen and (max-width:1699px) {
            max-width: 1400px;
        }
    }

    .feature {
        margin: 70px 0px 70px;

        @media screen and (max-width: 1199px) {
            margin: 40px 0px 40px;
        }

        .featuresWrapper {
            @media screen and (max-width: 575px) {
                padding: 0px 0px 0px 0px;
            }
        }

        h2 {
            font-family: "Raleway", Arial, Helvetica, sans-serif;
            font-size: 80px;
            line-height: 90px;
            font-weight: bold;
            color: #000000;
            margin-bottom: 25px;
            padding-right: 22%;

            @media screen and (max-width: 1199px) {
                padding-right: 20%;
                font-size: 50px;
                line-height: 60px;
            }

            @media screen and (max-width: 991px) {
                font-size: 54px;
                padding-right: 0px;
                line-height: normal;
            }

            @media screen and (max-width: 575px) {
                font-size: 35px;
                line-height: 45px;
                margin: 0px;
            }
        }

        .dynamicDashboard {
            display: flex;
            width: 990px;

            @media screen and (max-width: 1400px) {
                width: 800px;
            }

            @media screen and (max-width: 991px) {
                width: 100%;
            }

            @media screen and (max-width: 575px) {
                padding: 15px;
                text-align: center;
            }

            .dashboardImg {
                display: inline-block;
                vertical-align: middle;
                box-shadow: 0px 30px 90px #00000029;
                margin-top: 50px;
                margin-bottom: 100px;
                cursor: pointer;

                @media screen and (max-width: 991px) {
                    box-shadow: 0px 20px 50px #00000029;
                    margin-bottom: 80px;
                }

                @media screen and (max-width: 991px) {
                    box-shadow: 0px 10px 20px #00000019;
                    margin-bottom: 20px;
                    margin-top: 20px;
                }

                img {
                    height: 330px;
                    max-width: 450px;

                    @media screen and (max-width: 1400px) {
                        height: 300px;
                        max-width: 400px;
                    }

                    @media screen and (max-width: 1366px) {
                        height: 270px;
                        max-width: 360px;
                    }

                    @media screen and (max-width: 1199px) {
                        height: 200px;
                        max-width: 300px;
                    }

                    @media screen and (max-width: 991px) {
                        height: 160px;
                        max-width: 270px;
                    }

                    @media screen and (max-width: 575px) {
                        max-width: 100%;
                        height: 200px;
                    }
                }
            }

            .dynamic-content {
                display: inline-block;
                margin-left: 50px;
                min-width: 500px;
                max-width: 600px;
                vertical-align: middle;
                padding-right: 20px;

                @media screen and (max-width: 1400px) {
                    min-width: 440px;
                    max-width: 540px;
                }

                @media screen and (max-width: 1366px) {
                    min-width: 410px;
                    max-width: 500px;
                }

                @media screen and (max-width: 1199px) {
                    min-width: 310px;
                    max-width: 400px;
                }

                @media screen and (max-width: 991px) {
                    min-width: 220px;
                    max-width: 290px;
                }

                @media screen and (max-width: 575px) {
                    margin: 0px auto;
                    padding: 0px;
                    min-width: 280px;
                    max-width: 100%;
                }

                h4 {
                    font-size: 42px;
                    line-height: 53px;
                    color: #000080;
                    font-weight: bold;
                    margin: 53px 0px 24px;
                    font-family: "Mulish", Arial, Helvetica, sans-serif;

                    @media screen and (max-width: 1366px) {
                        font-size: 32px;
                        line-height: 42px;
                    }

                    @media screen and (max-width: 1199px) {
                        font-size: 24px;
                        line-height: 34px;
                        margin: 40px 0px 15px;
                    }

                    @media screen and (max-width: 991px) {
                        font-size: 24px;
                        line-height: 34px;
                        margin: 40px 0px 15px;
                    }

                    @media screen and (max-width: 575px) {
                        font-size: 20px;
                        line-height: 30px;
                        margin: 20px 0px 15px;
                    }
                }

                p {
                    font-size: 18px;
                    line-height: 23px;
                    color: #545454;
                    font-weight: normal;
                    padding-right: 10px;
                    font-family: "Mulish", Arial, Helvetica, sans-serif;

                    @media screen and (max-width: 1366px) {
                        font-size: 16px;
                        line-height: 20px;
                    }

                    @media screen and (max-width: 1199px) {
                        font-size: 14px;
                        line-height: 18px;
                    }

                    @media screen and (max-width: 991px) {
                        font-size: 14px;
                        line-height: 18px;
                    }

                    @media screen and (max-width: 575px) {
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
            }
        }
    }
}

/* Select Plan Section Start Here  */
.select-plan {
    display: block;
    margin-bottom: 150px;

    @media screen and (max-width: 1199px) {
        margin-bottom: 135px;
        display: block;
    }

    @media screen and (max-width: 991px) {
        margin-bottom: 120px;
        display: block;
    }

    @media screen and (max-width: 575px) {
        margin-bottom: 50px;
        display: block;
    }
}

.plan .inner-container {
    width: 1040px;
    margin: 0px auto;
}

.flex-plan {
    display: flex;
    justify-content: space-between;

    .PlanDesc {
        width: 50%;
        position: relative;
        z-index: 1;
        max-height: 413px;

        @media screen and (max-width: 575px) {
            width: 100%;
            position: relative;
            z-index: 1;
            max-height: 413px;
            display: block;
            height: 413px;
        }

        .monthly-plan {
            width: 100%;
            box-shadow: 0px 3px 60px #00000029;
            border-radius: 8px;
            background-color: #0a2540;
            position: absolute;
            z-index: 1;
            padding: 18px 23px 28px 23px;
            max-height: 413px;

            &::before {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 55%;
                content: "";
                background-color: #edf1f5;
                z-index: -1;
                border-radius: 8px 8px 0px 0px;
            }

            .package {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 15px;

                h4 {
                    font-size: 28px;
                    line-height: 30px;
                    font-weight: 700;
                    color: #ff9933;
                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                    margin: 0px;
                    padding: 0px;

                    @media screen and (max-width: 991px) {
                        font-size: 26px;
                        line-height: 30px;
                    }

                    @media screen and (max-width: 767px) {
                        font-size: 22px;
                        line-height: 26px;
                    }

                    @media screen and (max-width: 575px) {
                        font-size: 18px;
                        line-height: 24px;
                    }
                }

                p {
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: normal;
                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                    margin: 0px;
                    padding: 0px;

                    @media screen and (max-width: 991px) {
                        font-size: 16px;
                        line-height: 20px;
                    }

                    @media screen and (max-width: 767px) {
                        font-size: 14px;
                        line-height: 18px;
                    }

                    @media screen and (max-width: 575px) {
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }

            .monthly-title {
                text-align: center;

                p {
                    font-size: 22px;
                    line-height: 30px;
                    font-weight: 600;
                    color: #000000;
                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                    margin: 0px 0px 5px 0px;
                    padding: 0px;
                }

                h4 {
                    color: #f89938;
                    font-size: 44px;
                    line-height: 50px;
                    font-weight: 700;
                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                    margin: 0px 0px 5px 0px;
                    padding: 0px;
                    cursor: pointer;
                }
            }

            .monthly-feature {
                background: #ffffff;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 8px;
                width: 460px;
                margin: 0px auto;
                padding: 21px 30px;
                margin-bottom: 18px;

                @media screen and (max-width: 575px) {
                    width: 100%;
                }

                ul {
                    display: flex;
                    width: 100%;
                    flex-wrap: wrap;
                    list-style: none;
                    list-style-type: none;
                    padding: 0px;
                    margin: 0px;

                    @media screen and (max-width: 575px) {
                        max-height: 120px;
                        overflow: auto;
                    }

                    li {
                        font-size: 16px;
                        font-weight: bold;
                        width: 33.33%;
                        margin-bottom: 9px;
                        line-height: 20px;
                        position: relative;
                        font-family: "Mulish", Arial, Helvetica, sans-serif;

                        @media screen and (max-width: 575px) {
                            width: 100%;
                        }
                    }
                }
            }

            .explore {
                display: block;
                text-align: center;

                @media screen and (max-width: 575px) {
                    margin: 0px;
                    padding: 0px;
                }

                span {
                    font-size: 18px;
                    line-height: 20px;
                    font-weight: 600;
                    color: #f89938;
                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                    cursor: pointer;
                    transition: all 0.3s ease;
                    display: block;

                    &:hover {
                        text-decoration: underline;
                        letter-spacing: 1px;
                    }
                }
            }
        }
    }

    .plan-details {
        width: 46%;
        text-align: right;

        h4 {
            color: #000080;
            font-size: 40px;
            line-height: 50px;
            font-weight: bold;
            margin-bottom: 47px;
            font-family: "Mulish", Arial, Helvetica, sans-serif;

            @media screen and (max-width: 991px) {
                color: #000080;
                font-size: 34px;
                line-height: 40px;
                font-weight: bold;
                margin-bottom: 30px;
            }

            @media screen and (max-width: 575px) {
                font-size: 26px;
                line-height: 32px;
            }
        }

        p {
            color: #000000;
            font-size: 18px;
            line-height: 20px;
            font-weight: normal;
            margin-bottom: 15px;
            font-family: "Mulish", Arial, Helvetica, sans-serif;

            @media screen and (max-width: 991px) {}

            @media screen and (max-width: 575px) {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
}


/* Select Plan Section Ends Here  */
.notification-section {
    @media screen and (max-width: 575px) {
        margin-bottom: 20px;
    }
}

/* Asked Questions Section Start Here  */
.AskedQuestion {
    display: block;
    width: 100%;
    margin-bottom: 50px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    margin-top: 100px;

    .FlexQuestion {
        display: flex;

        @media screen and (max-width: 991px) {
            flex-direction: column;
        }

        .frequentlyContent {
            width: 58%;
            height: 500px;

            @media screen and (max-width: 991px) {
                width: 100%;
                padding-left: 10px;
            }

            @media screen and (max-width: 767px) {
                width: 100%;
                margin-top: 0px;
                padding: 0px;
            }

            @media screen and (max-width: 767px) {
                margin-top: 10px;
            }

            p {
                width: 60%;
                margin-top: 0px;
                margin-bottom: 25px;
                padding: 25px 25px;
                background: #ffffff;
                box-shadow: 0px 3px 60px #00000029;
                border-radius: 8px;
                font-size: 16px;
                line-height: 20px;
                font-weight: 600;
                color: #121212;
                font-family: "Mulish", Arial, Helvetica, sans-serif;
                cursor: pointer;
                transition: all 0.3s ease;

                @media screen and (max-width: 991px) {
                    width: 640px;
                    padding: 25px 30px;
                }

                @media screen and (max-width: 767px) {
                    width: 75%;
                    padding: 25px 30px;
                }

                @media screen and (max-width: 575px) {
                    padding: 20px 20px;
                    font-size: 14px;
                    line-height: 18px;
                    width: 100%;
                }

                &:hover {
                    width: 96%;
                    font-size: 18px;
                    line-height: 20px;
                    background-color: #ff9933;
                    color: #ffffff;
                    font-family: "Mulish", Arial, Helvetica, sans-serif;

                    @media screen and (max-width: 575px) {}
                }
            }

            .slick-slider {
                height: 500px;

                .slick-list {
                    padding-top: 30px;
                    padding-left: 40px;
                    height: 500px !important;
                    padding-right: 40px;
                    width: 100%;

                    @media screen and (max-width: 575px) {
                        padding-left: 20px;
                        padding-right: 20px;
                    }

                    .slick-slide {
                        width: 100% !important;

                        &.slick-current {
                            p {
                                width: 96%;
                                margin-top: 0px;
                                margin-bottom: 25px;
                                padding: 25px 25px;
                                box-shadow: 0px 3px 60px #00000029;
                                border-radius: 8px;
                                font-size: 20px;
                                line-height: 20px;
                                font-weight: 600;
                                font-family: "Mulish", Arial, Helvetica, sans-serif;
                                cursor: pointer;
                                transition: all 0.3s ease;
                                background-color: #ff9933;
                                color: #ffffff;

                                @media screen and (max-width: 991px) {
                                    width: 640px;
                                    padding: 25px 30px;
                                }

                                @media screen and (max-width: 767px) {
                                    width: 75%;
                                    padding: 25px 30px;
                                }

                                @media screen and (max-width: 575px) {
                                    padding: 20px 20px;
                                    font-size: 14px;
                                    line-height: 18px;
                                    width: 100%;
                                }

                            }
                        }
                    }
                }
            }
        }

        .frequently-asked {
            width: 49%;
            max-width: 600px;
            padding: 47px 0px 44px 68px;
            position: relative;
            z-index: 1;

            &::before {
                background-color: #0a2540;
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0px;
                left: -85px;
                transform: skew(-16deg);
                -webkit-transform: skew(-16deg);
                -moz-transform: skew(-16deg);
                -o-transform: skew(-16deg);
                overflow: hidden;
                z-index: -1;

                @media screen and (max-width: 991px) {
                    left: 0px;
                    transform: skew(0deg);
                    -webkit-transform: skew(0deg);
                    -moz-transform: skew(0deg);
                    -o-transform: skew(0deg);
                }

                @media screen and (max-width: 767px) {
                    left: 0px;
                    transform: skew(0deg);
                    -webkit-transform: skew(0deg);
                    -moz-transform: skew(0deg);
                    -o-transform: skew(0deg);
                }
            }

            @media screen and (max-width: 991px) {
                width: 100%;
                margin-bottom: 100px;
                max-width: 100%;
            }

            @media screen and (max-width: 767px) {
                max-width: 100%;
                padding: 47px 0px 44px 50px;
            }

            @media screen and (max-width: 575px) {
                margin-bottom: 0px;
                padding: 30px;
            }

            h6 {
                font-size: 18px;
                line-height: 24px;
                font-weight: bold;
                color: #ff9933;
                font-family: "Mulish", Arial, Helvetica, sans-serif;

                @media screen and (max-width: 575px) {
                    font-size: 16px;
                    line-height: 24px;
                }
            }

            h4 {
                font-size: 50px;
                line-height: 60px;
                font-weight: bold;
                color: #ffffff;
                margin-bottom: 25px;
                font-family: "Mulish", Arial, Helvetica, sans-serif;

                @media screen and (max-width: 575px) {
                    font-size: 28px;
                    line-height: 42px;
                    padding-right: 0px;
                    margin-bottom: 15px;
                }
            }

            p {
                font-size: 16px;
                line-height: 20px;
                font-weight: normal;
                color: #ffffff;
                margin-bottom: 8px;
                padding-right: 150px;
                font-family: "Mulish", Arial, Helvetica, sans-serif;

                @media screen and (max-width: 767px) {
                    padding-right: 40px;
                }

                @media screen and (max-width: 575px) {
                    font-size: 13px;
                    line-height: 17px;
                }
            }

            .explore {
                span {
                    color: #ff9933;
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: normal;
                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                    cursor: pointer;
                    transition: all 0.3s ease;

                    &:hover {
                        text-decoration: underline;
                        font-size: 16px;
                        line-height: 20px;
                        letter-spacing: 1px;
                    }
                }
            }
        }
    }
}

.registrationSection {
    width: 100%;
    margin-bottom: 80px;

    @media screen and (max-width: 991px) {
        margin-bottom: 150px;
    }

    @media screen and (max-width: 767px) {
        margin-bottom: 90px;
    }

    @media screen and (max-width: 575px) {
        margin-bottom: 130px;
    }

    .container {
        max-width: 1640px;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;

        @media screen and (max-width:1699px) {
            max-width: 1400px;
        }
    }

    .user-registered-section {
        text-align: center;

        h4 {
            font-size: 42px;
            line-height: 56px;
            color: #000080;
            font-weight: bold;
            margin: 25px 0px 25px;
            font-family: "Mulish", Arial, Helvetica, sans-serif;
            text-align: center;

            @media screen and (max-width: 991px) {
                font-size: 28px;
                line-height: 30px;
                line-height: normal;
                margin: 20px 0px 20px;
            }

            @media screen and (max-width: 575px) {}
        }

        img {
            text-align: center;
        }
    }
}

/* Total Registered Section Ends */

/* Asked Questions Section Ends Here  */

@media screen and (min-width: 1700px) {

    /* Dynamic Dashboard Start Here  */
    .useful-feature .feature {
        margin: 70px 0px 143px;
    }

    .feature h2 {
        font-family: "Raleway", "San Sarif";
        font-size: 100px;
        font-weight: bold;
        line-height: 117px;
        color: #000000;
        margin-bottom: 25px;
        padding-right: 40%;
    }

    .feature .dynamicDashboard {
        display: flex;
        width: 971px;
    }

    .dynamicDashboard .dashboardImg {
        display: inline-block;
    }

    .dynamicDashboard .dynamic-content {
        display: inline-block;
    }

    .dynamicDashboard .dashboardImg img {
        max-width: 543px;
        box-shadow: 0px 30px 90px #00000029;
    }

    .dynamicDashboard .dynamic-content {
        margin-left: 16px;
    }

    .dynamic-content h4 {
        font-size: 42px;
        line-height: 53px;
        color: #000080;
        font-weight: bold;
        margin: 53px 0px 24px;
    }

    .dynamic-content p {
        font-size: 18px;
        line-height: 23px;
        color: #545454;
        font-weight: normal;
        padding-right: 10px;
    }

    /* Dynamic Dashboard End Here  */
    /* Select Plan Section Start Here  */
    .select-plan {
        display: block;
        margin-bottom: 179px;
    }

    .plan .inner-container {
        width: 1480px;
        margin: 0px auto;
    }

    .plan .flex-plan {
        display: flex;
        justify-content: space-between;
    }

    .flex-plan .monthly-plan {
        width: 655px;
        box-shadow: 0px 3px 60px #00000029;
        border-radius: 8px;
        background-color: #0a2540;
        position: relative;
        z-index: 1;
        padding: 18px 23px 28px 17px;
        max-height: 402px;
    }

    .flex-plan .monthly-plan::before {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 55%;
        content: "";
        background-color: #edf1f5;
        z-index: -1;
        border-radius: 8px 8px 0px 0px;
    }

    .flex-plan .plan-details {
        width: 674px;
        text-align: right;
    }

    .monthly-plan .package {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 13px;
    }

    .package h4 {
        font-size: 28px;
        font-weight: 900;
        color: #ff9933;
    }

    .package p {
        font-size: 16px;
        font-weight: normal;
    }

    .monthly-title {
        text-align: center;
    }

    .monthly-title p {
        font-size: 22px;
        font-weight: 600;
        color: #000000;
    }

    .monthly-title h4 {
        color: #f89938;
        font-size: 45px;
        font-weight: 700;
    }

    .monthly-feature {
        background: #ffffff;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 8px;
        width: 560px;
        margin: 0px auto;
        padding: 21px 30px;
        margin-bottom: 18px;
    }

    .monthly-feature ul {
        display: flex;
        /* justify-content: space-between; */
        width: 100%;
        flex-wrap: wrap;
        list-style: none;
    }

    .monthly-feature ul li {
        font-size: 16px;
        font-weight: bold;
        width: 33.33%;
        margin-bottom: 9px;
        line-height: 20px;
        position: relative;
    }

    .monthly-feature ul li::before {
        content: "•";
        position: absolute;
        margin-left: -7px;
        color: #138808;
    }

    .monthly-plan .explore {
        display: block;
        text-align: center;
    }

    .monthly-plan .explore span {
        font-size: 18px;
        font-weight: 600;
        color: #f89938;
    }

    .plan-details h4 {
        color: #000080;
        font-size: 50px;
        line-height: 60px;
        font-weight: bold;
        margin-bottom: 47px;
    }

    .plan-details p {
        color: #000000;
        font-size: 18px;
        font-weight: normal;
        margin-bottom: 15px;
    }

    /* Select Plan Section Ends Here  */

    /* Asked Questions Section Start Here  */
    .AskedQuestion {
        display: block;
        width: 100%;
        margin-bottom: 50px;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        margin-top: 100px;
    }

    .AskedQuestion .FlexQuestion {
        display: flex;
    }

    .FlexQuestion .frequently-asked {
        width: 60%;
        max-width: 912px;
        padding: 47px 0px 44px 130px;
        position: relative;
        z-index: 1;
    }

    .FlexQuestion .frequentlyContent {
        width: 52%;
    }

    .frequently-asked::before {
        background-color: #0a2540;
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: -152px;
        transform: skew(-34deg);
        -webkit-transform: skew(-34deg);
        -moz-transform: skew(-34deg);
        -o-transform: skew(-34deg);
        overflow: hidden;
        z-index: -1;
    }

    .frequently-asked h6 {
        font-size: 18px;
        font-weight: bold;
        color: #ff9933;
    }

    .frequently-asked h4 {
        font-size: 50px;
        font-weight: bold;
        color: #ffffff;
        margin-bottom: 25px;
    }

    .frequently-asked p {
        font-size: 18px;
        font-weight: normal;
        color: #ffffff;
        margin-bottom: 8px;
        padding-right: 245px;
    }

    .frequently-asked .explore span {
        color: #ff9933;
        font-size: 16px;
        font-weight: normal;
    }

    .frequentlyContent p {
        width: 75%;
        padding: 25px 45px;
        background: #ffffff;
        box-shadow: 0px 3px 60px #00000029;
        border-radius: 8px;
        margin-bottom: 25px;
        font-size: 18px;
        font-weight: 600;
        color: #121212;
    }

    .frequentlyContent p.current {
        width: 98%;
        padding: 32px 57px;
        background-color: #ff9933;
        color: #ffffff;
    }

    /* Asked Questions Section Ends Here  */
}

@media screen and (max-width: 1199px) {

    /* Select Plan Section Start Here  */
    .flex-plan .monthly-plan {
        max-height: 473px;
    }

    .plan .inner-container {
        width: 900px;
    }

    .monthly-feature {
        width: 405px;
    }

    .monthly-feature ul li {
        width: 30.33%;
    }

    .monthly-feature ul {
        justify-content: space-between;
    }

    /* Select Plan Section End Here  */

    /* Asked Questions Section Start Here  */
    .frequentlyContent p {
        width: 75%;
        padding: 27px 30px;
    }

    .frequently-asked::before {
        left: -93px;
        transform: skew(-13deg);
        -webkit-transform: skew(-13deg);
        -moz-transform: skew(-13deg);
        -o-transform: skew(-13deg);
    }

    .frequently-asked p {
        padding-right: 150px;
    }

    .frequently-asked h4 {
        font-size: 42px;
        padding-right: 60px;
    }

    .AskedQuestion {
        margin-bottom: 50px;
    }

    /* Asked Questions Section Ends Here  */
}

@media screen and (max-width: 991px) {

    /* Select Plan Section Start Here  */
    .plan .flex-plan {
        flex-direction: column;
    }

    .flex-plan .monthly-plan {
        width: 100%;
        max-height: 100%;
        margin: 0px auto;
        order: 2;
    }

    .plan .inner-container {
        width: 96%;
    }

    .flex-plan .plan-details {
        width: 100%;
        margin-bottom: 15px;
        padding-left: 50px;
    }

    .monthly-feature {
        width: 500px;
    }

    .monthly-title p {
        font-size: 20px;
    }

    /* Select Plan Section Ends Here  */
}

@media screen and (max-width: 767px) {

    /* Dynamic Dashboard Start Here  */
    .useful-feature .feature {
        margin: 60px 0px 90px;
    }

    .feature .dynamicDashboard {
        flex-direction: column;
    }

    .dynamicDashboard .dashboardImg img {
        max-width: 100%;
    }

    .dynamicDashboard .dynamic-content {
        margin-left: 0px;
    }

    /* Dynamic Dashboard End Here  */
    /* Select Plan Section Start Here  */
    .monthly-feature {
        width: 100%;
        margin-bottom: 10px;
    }

    .monthly-feature ul li {
        width: 31.33%;
        font-size: 14px;
    }

    /* Select Plan Section End Here  */
}

@media screen and (max-width: 575px) {

    /* Dynamic Dashboard Start Here  */
    .useful-feature .feature {
        margin: 30px 0px 10px;
    }

    /* Dynamic Dashboard End Here  */
    /* Select Plan Section Start Here  */
    .flex-plan .plan-details {
        padding-left: 0px;
    }

    .plan .inner-container {
        width: 98%;
    }

    .flex-plan .monthly-plan {
        width: 100%;
        padding: 18px 23px 15px 23px;
    }

    .monthly-title p {
        font-size: 16px;
    }

    .monthly-title h4 {
        font-size: 35px;
    }

    .monthly-feature {
        padding: 21px 15px;
    }

    .monthly-plan .explore a {
        font-size: 14px;
    }

    .monthly-feature ul li {
        width: 28.33%;
        font-size: 12px;
    }

    /* Asked Questions Section Start Here  */
    .frequentlyContent p.current {
        padding: 20px 20px;
    }

    .frequently-asked .explore span {
        font-size: 13px;
    }

    /* Asked Questions Section Ends Here  */
}

.PricingPlanSection {
    display: block;
    background-color: #EFF6F9;
    padding: 70px 0px;
    margin-bottom: 115px;
    background-repeat: no-repeat;
    position: relative;
    z-index: 2;

    @media screen and (max-width: 991px) {
        display: block;
        padding: 55px 0px;
    }

    @media screen and (max-width: 1199px) {
        padding: 55px 0px;
    }

    @media screen and (max-width: 575px) {
        padding: 30px 0px;
        margin-bottom: 30px;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0px;
        left: -30px;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        z-index: -1;
        background-image: url(../../assets/images/v2/landing/pricingCurve.svg);

        @media screen and (max-width: 1600px) {
            left: -190px;
        }


        @media screen and (max-width: 1199px) {
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            width: 50%;
            height: 100%;
            background-image: url(../../assets/images/v2/landing/pricingCurve.svg);
            background-size: cover;
            background-repeat: no-repeat;
            z-index: -1;
        }

        @media screen and (max-width: 991px) {
            display: block;
            background: none;
        }

        /*    @media screen and (max-width: 1440px){
            left: -372px;
            background-position: 1% 0%;
        } */

    }


    .container {
        max-width: 1640px;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;

        @media screen and (max-width:1699px) {
            max-width: 1400px;
        }

        @media screen and (max-width: 1199px) {
            max-width: 960px;
        }

    }

    .PricingPlanSectionFlex {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 991px) {
            flex-direction: column;
        }

        .left-content-area {
            width: 50%;
            margin-top: 66px;

            @media screen and (max-width: 991px) {
                width: 100%;
                margin: 0px;
                padding: 20px;
            }

            @media screen and (max-width: 575px) {
                padding: 0px 0px 20px 0px;
            }

            &::before {
                @media screen and (max-width: 575px) {
                    height: 44%;
                }
            }

            h3 {
                font-family: 'Mulish', sans-serif;
                font-size: 50px;
                font-weight: bold;
                line-height: 63px;
                color: #ffffff;
                padding-right: 140px;
                margin-bottom: 57px;

                @media screen and (max-width: 1440px) {
                    padding-right: 56px;
                }

                @media screen and (max-width: 1199px) {
                    font-size: 40px;
                    line-height: 50px;
                    padding-right: 50px;
                    margin-bottom: 35px;
                }

                @media screen and (max-width: 991px) {
                    padding-right: 0px;
                    color: #0A2540;
                }

                @media screen and (max-width: 767px) {
                    font-size: 35px;
                    margin-bottom: 35px;
                    line-height: 40px;
                }
            }

            p {
                font-family: 'Mulish', sans-serif;
                font-size: 18px;
                font-weight: 400;
                line-height: 23px;
                color: #ffffff;
                padding-right: 270px;
                margin-bottom: 57px;

                @media screen and (min-width: 1700px) {
                    padding-right: 285px;
                }

                @media screen and (max-width: 1440px) {
                    padding-right: 245px;
                }

                @media screen and (max-width: 1199px) {
                    font-size: 16px;
                    line-height: 20px;
                    padding-right: 70px;
                }

                @media screen and (max-width: 991px) {
                    padding-right: 0px;
                    color: #0A2540;
                }

                &:not(:last-child) {
                    margin-bottom: 20px;
                }
            }

            .explore {
                margin-top: 57px;

                @media screen and (max-width: 1199px) {
                    margin-top: 35px;
                }
            }

            span {
                color: #ff9933;
                font-size: 16px;
                line-height: 20px;
                font-weight: normal;
                font-family: "Mulish", Arial, Helvetica, sans-serif;
                cursor: pointer;
                transition: all 0.3s ease;

                &:hover {
                    text-decoration: underline;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: 1px;
                }
            }
        }

        .right-main-content-area {
            width: 480px;
            box-shadow: 0px 3px 60px #00000029;

            @media screen and (max-width: 1440px) {
                max-width: 415px;
            }

            @media screen and (max-width: 991px) {
                width: 100%;
                margin: 0px auto;
            }

            @media screen and (max-width: 767px) {
                max-width: 460px;
            }

            .right-content-area {
                background-color: #ffffff;

                border-radius: 8px;
                opacity: 1;
                max-width: 480px;
                margin-left: auto;

                @media screen and (max-width: 1440px) {
                    max-width: 415px;
                }

                @media screen and (max-width: 991px) {
                    width: 100%;
                    margin: 0px auto;
                }

                @media screen and (max-width: 767px) {
                    max-width: 460px;
                }

                .right-plan-area {
                    .right-title-area {
                        background-color: #0A2540;
                        padding: 15px 15px 15px 15px;

                        .right-title-area-price-content {
                            display: flex;
                            justify-content: space-between;

                            .packagePrice {
                                position: relative;
                                width: max-content;

                                &::before {
                                    position: absolute;
                                    content: "";
                                    left: 0;
                                    top: 18%;
                                    right: 0;
                                    border-top: 3px solid #fd7e14;

                                    -webkit-transform: rotate(-5deg);
                                    -moz-transform: rotate(-5deg);
                                    -ms-transform: rotate(-5deg);
                                    -o-transform: rotate(-5deg);
                                    transform: rotate(-5deg);
                                }
                            }

                            p {
                                font-family: 'Mulish', sans-serif;
                                letter-spacing: 0px;
                                color: #E7E7E7;
                                opacity: 1;
                                font-weight: 400;
                                font-size: 16px;
                                margin: 0;

                                &:first-child {
                                    span {
                                        font-weight: lighter;
                                    }
                                }

                                &:last-child {
                                    text-align: right;
                                    font-weight: lighter;

                                    span {
                                        display: block;
                                        font-weight: bold;
                                    }
                                }
                            }
                        }

                        h3 {
                            font-family: 'Mulish', sans-serif;
                            font-size: 36px;
                            font-weight: bold;
                            line-height: 63px;
                            color: #ffffff;
                            margin-bottom: 19px;

                            @media screen and (max-width: 1199px) {
                                font-size: 28px;
                                line-height: 50px;
                                margin-bottom: 10px;
                            }
                        }

                        p {
                            font-family: 'Mulish', sans-serif;
                            font-size: 18px;
                            font-weight: bold;
                            line-height: 23px;
                            color: #FF9933;
                            margin: 0;

                            @media screen and (max-width: 1199px) {
                                font-size: 16px;
                                line-height: 20px;
                            }

                            span {
                                padding-left: 15px;
                                font-size: 22px;
                                cursor: pointer;
                            }
                        }
                    }

                    .right-feature-area {
                        padding: 36px 13px 15px 30px;
                        position: relative;

                        @media screen and (max-width: 1199px) {
                            padding: 30px 13px 15px 25px;
                        }

                        .plan-feature {
                            min-height: 350px;

                            @media screen and (max-width: 575px) {
                                min-height: 270px;
                            }

                            ul {
                                list-style-type: none;
                                margin: 0px;
                                padding: 0px;

                                li {
                                    display: flex;
                                    margin-bottom: 24px;

                                    @media screen and (max-width: 767px) {
                                        margin-bottom: 13px;
                                    }

                                    p {
                                        font-family: 'Mulish', sans-serif;
                                        font-size: 16px;
                                        line-height: 20px;
                                        color: #000000;
                                        font-weight: bold;
                                        margin: 0px;
                                        padding: 0px;
                                        margin-left: 20px;

                                    }
                                }
                            }
                        }

                        .right-feature-subscribe {
                            padding: 8px 0px 7px;
                            margin-bottom: 20px;

                            span.subscribe {
                                font-family: 'Mulish', sans-serif;
                                padding: 8px 0px 7px;
                                background-color: #FF9933;
                                color: #ffffff;
                                font-weight: 400;
                                font-size: 18px;
                                display: block;
                                text-align: center;
                                border-radius: 4px;
                                cursor: pointer;
                            }
                        }

                        p {
                            display: block;
                            text-align: right;
                            font-size: 8px;
                            line-height: 10px;
                            font-weight: 600;
                            color: #000000;
                            margin: 0;
                        }

                        .right-price-tag {
                            position: absolute;
                            top: -28px;
                            right: 15px;

                            @media screen and (max-width: 575px) {
                                top: -16px;
                                right: 15px;
                            }

                            .price-tag {
                                position: relative;

                                .plan-price {
                                    position: absolute;
                                    top: 55%;
                                    left: 60%;
                                    transform: translate(-50%, -50%);
                                    text-align: center;

                                    h3 {
                                        font-family: 'Mulish', sans-serif;
                                        color: #fff;
                                        font-size: 37px;
                                        font-weight: bold;
                                        line-height: 20px;

                                        @media screen and (max-width: 1199px) {
                                            font-size: 30px;
                                        }

                                        @media screen and (max-width: 575px) {
                                            font-size: 18px;
                                        }

                                        span {
                                            font-weight: 400;
                                            font-size: 16px;

                                            @media screen and (max-width: 575px) {
                                                font-size: 14px;
                                            }
                                        }

                                    }
                                }

                                img {
                                    @media screen and (max-width: 575px) {
                                        width: 80px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }
}

.cardsBoxWrapper {
    display: block;
    width: 100%;
    min-height: 600px;
    padding-top: 130px;

    @media screen and (max-width: 991px) {
        min-height: 930px;
    }

    @media screen and (max-width: 575px) {
        min-height: 980px;
    }

    .homeCardsBox {
        position: relative;

        .notifCard {
            width: calc(100%);
            max-width: 100%;
            border-radius: 14px;
            cursor: pointer;

            &:not(.hide) {
                position: absolute;
                top: 0;
                left: 0;
                transition: all 0.8s cubic-bezier(0.18, 0.98, 0.45, 1);
                box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.23);
            }

            &:not(.hide)[data-slide='0'] {
                transform: translate(0px, 0px) scale(1);
                z-index: 7;
                opacity: 1;
            }

            &:not(.hide)[data-slide='1'] {
                background-color: #0A2540;
                transform: translate(0px, -50px) scale(0.975);
                z-index: 6;
                opacity: 0.9;
                opacity: 1;
            }

            &:not(.hide)[data-slide='2'] {
                background-color: #0A2540;
                transform: translate(0px, -100px) scale(0.95);
                z-index: 5;
                opacity: 0.8;
                opacity: 1;
            }

            &:not(.hide)[data-slide='3'] {
                background-color: #0A2540;
                transform: translate(0px, -100px) scale(0.95);
                z-index: 4;
                opacity: 0.7;
                opacity: 1;
            }

            &:not(.hide)[data-slide='4'] {
                background-color: #0A2540;
                transform: translate(0px, -100px) scale(0.95);
                z-index: 3;
                opacity: 0.7;
                opacity: 1;
            }

            &:not(.hide)[data-slide='5'] {
                background-color: #0A2540;
                transform: translate(0px, -100px) scale(0.95);
                z-index: 2;
                opacity: 0.7;
                opacity: 1;
            }
 
            &:not(.hide)[data-slide='0'] {
                transition: all 0.32s cubic-bezier(0.18, 0.98, 0.45, 1);
            }

            &.hide {
                visibility: hidden;
            }
        }
    }
}