.pageScreenContainer {
    .myPage {
        .subscriptionNavigationWrapper {
            display: block;
            width: 100%;

            @media only screen and (max-width: 575px) {
                display: none;
            }

            ul.subscriptionNavigation {
                display: inline-block;
                font-size: 0;
                width: 100%;
                text-align: center;

                li.item {
                    cursor: pointer;
                    list-style: none;
                    list-style-type: none;
                    margin: 0px 30px;
                    display: inline-block;

                    @media only screen and (max-width: 767px) {
                        margin: 0px 20px;
                    }

                    @media only screen and (max-width: 660px) {
                        margin: 0px 13px;
                    }

                    @media only screen and (max-width: 575px) {
                        margin: 0px 10px;
                    }

                    &::after {
                        content: '';
                        display: block;
                        width: 0;
                        height: 4px;
                        background: #138808;
                        transition: all ease .3s;
                        opacity: 0;
                    }

                    &:hover {
                        &::after {
                            /* width: 100%; */
                        }
                    }

                    &.active {
                        &::after {
                            width: 100%;
                            opacity: 1;
                        }
                    }

                    span {
                        display: inline-block;
                        text-decoration: none;
                        color: #000080;
                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                        font-weight: 400;
                        font-style: normal;
                        font-size: 24px;
                        line-height: 60px;
                        padding: 0px 15px;

                        @media only screen and (max-width: 991px) {
                            font-size: 20px;
                            line-height: 35px;
                        }

                        @media only screen and (max-width: 767px) {
                            font-size: 19px;
                            line-height: 35px;
                        }

                        @media only screen and (max-width: 650px) {
                            font-size: 17px;
                            line-height: 30px;
                        }

                        @media only screen and (max-width: 575px) {
                            font-size: 18px;
                            line-height: 50px;
                        }
                    }
                }
            }
        }

        .mobileCategoryListings {
            padding: 0px 20px 5px;
            display: none;

            @media only screen and (max-width: 575px) {
                display: block;
                width: 100%;
                padding: 0px;
            }

            .categoryTitle {
                color: #000080;
                font-family: "Mulish", Arial, Helvetica, sans-serif;
                font-weight: 500;
                font-style: normal;
                font-size: 20px;
                line-height: 40px;
                margin: 0px 0px 5px 0px;

                @media only screen and (max-width: 1199px) {
                    font-size: 18px;
                    line-height: 30px;
                }
            }
        }

        .subscriptionListings {
            display: block;
            width: 100%;
            padding: 20px 0px;

            .courseItemWrapper {
                padding-bottom: 15px;
                padding-top: 15px;

                .courseItem {
                    background: #ffffff;
                    border: 1px solid rgba(255, 255, 255, 1);
                    border-radius: 10px;
                    -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
                    -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
                    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
                    padding: 20px 15px;

                    .courseHeading {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .courseTitle {
                            color: #000080;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 500;
                            font-style: normal;
                            font-size: 30px;
                            line-height: 50px;

                            @media only screen and (max-width: 767px) {
                                font-size: 24px;
                                line-height: normal;
                            }
                        }

                        .coursePackage {
                            text-align: right;
                            color: #000080;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 400;
                            font-style: normal;
                            font-size: 14px;
                            line-height: 50px;
                        }
                    }

                    .courseDescription {
                        padding: 10px 0px;

                        p {
                            color: #707070;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 400;
                            font-style: normal;
                            font-size: 14px;
                            line-height: 16px;
                        }
                    }

                    .courseLinks {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &.center {
                            text-align: center;
                            justify-content: center;
                        }

                        .exploreLink, .subscribeLink {
                            padding: 0px 15px;
                            width: 115px;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 400;
                            font-style: normal;
                            font-size: 16px;
                            line-height: 40px;
                            text-align: center;
                            border-radius: 20px;
                            cursor: pointer;
                            @media only screen and (max-width: 767px) {
                                width: 108px;
                            }

                            @media only screen and (max-width: 575px) {
                                width: 115px;
                            }
                        }

                        .exploreLink {
                            background-color: #FF9933;
                            border: 1px solid #FF9933;
                            color: #FFFFFF;

                            &:hover {
                                background-color: #FFFFFF;
                                color: #FF9933;
                            }
                        }

                        .subscribeLink {
                            color: #FF9933;
                            background-color: #FFFFFF;
                            border: 1px solid #FF9933;
                            margin-left: 5px;
                           
                            @media only screen and (max-width: 575px) {
                                display: block;
                            }

                            &:hover {
                                color: #FFFFFF;
                                background-color: #FF9933;
                            }
                        }
                    }
                }
            }

            .loadMore {
                padding-top: 25px;
                padding-bottom: 25px;
                text-align: center;

                .noCourse {
                    color: #8A8A8A;
                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                    font-weight: 400;
                    font-style: normal;
                    font-size: 20px;
                    line-height: 40px;
                    display: block;

                    @media only screen and (max-width: 767px) {
                        font-size: 18px;
                        line-height: 40px;
                    }

                    @media only screen and (max-width: 639px) {
                        font-size: 16px;
                        line-height: 36px;
                    }

                    @media only screen and (max-width: 575px) {
                        font-size: 16px;
                        line-height: 34px;
                    }
                }
            }
        }
    }
}