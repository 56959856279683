.pageScreenContainer {
    .paymentStatus {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .paymentStatusWrapper {
            width: 100%;
            padding: 20px;

            .sectionHeading {
                color: #000080;
                text-align: center;
            }

            .paymentStatusView {
                padding: 20px 0px;
                text-align: center;

                .InvoiceInfo {
                    text-align: center;
                    display: block;
                    width: 100%;

                    .successIcon {
                        max-width: 100px;
                        width: auto;
                        margin: 20px auto;
                        display: block;
                    }

                    .message {
                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                        font-weight: 500;
                        font-style: normal;
                        font-size: 20px;
                        line-height: 40px;
                        text-align: center;
                        color: #3D3D3D;
                        display: block;
                    }

                    .courseView {
                        display: block;
                        margin: 20px auto;
                    }
                }
            }
        }
    }
}