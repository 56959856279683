.packageScreenContainer {
 height: 100%;
    -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
    .myPage {
        .subscriptionNavigationWrapper {
            display: block;
            width: 100%;
           
            .courseTitle {
                cursor: pointer;
                font-family: "Mulish", Arial, Helvetica, sans-serif;
                font-size: 21px;
                padding: 10px 10px 0px 20px;
            }
            ul.subscriptionNavigation {
                display: flex !important;
                font-size: 0;
                width: 100%;
                text-align: center;
                margin-bottom: 0rem;

                li.item {
                    cursor: pointer;
                    list-style: none;
                    list-style-type: none;
                    margin: 10px 15px;
                    display: inline-block;

                    @media only screen and (max-width: 767px) {
                        margin: 0px 20px;
                    }

                    @media only screen and (max-width: 660px) {
                        margin: 0px 13px;
                    }

                    @media only screen and (max-width: 575px) {
                        margin: 0px 10px;
                    }

                    &::after {
                        content: '';
                        display: block;
                        width: 0;
                        height: 2px;
                        background: #ff9933;
                        transition: all ease .3s;
                        opacity: 0;
                    }

                   
                    &.active {
                        &::after {
                            width: 100%;
                            opacity: 1;
                        }
                    }

                    span {
                        display: inline-block;
                        text-decoration: none;
                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                        font-weight: 400;
                        font-size: 17px;
                        padding: 0px 10px;
                        
                        @media only screen and (max-width: 991px) {
                            font-size: 20px;
                            line-height: 35px;
                        }

                        @media only screen and (max-width: 767px) {
                            font-size: 19px;
                            line-height: 35px;
                        }

                        @media only screen and (max-width: 650px) {
                            font-size: 17px;
                            line-height: 30px;
                        }

                        @media only screen and (max-width: 575px) {
                            font-size: 18px;
                            line-height: 50px;
                        }
                    }
                }
            }
        }

        

        // .mobileCategoryListings {
        //     padding: 0px 20px 5px;
        //     display: none;

        //     @media only screen and (max-width: 575px) {
        //         display: block;
        //         width: 100%;
        //         padding: 0px;
        //     }

        //     .categoryTitle {
        //         color: #000080;
        //         font-family: "Mulish", Arial, Helvetica, sans-serif;
        //         font-weight: 500;
        //         font-style: normal;
        //         font-size: 20px;
        //         line-height: 40px;
        //         margin: 0px 0px 5px 0px;

        //         @media only screen and (max-width: 1199px) {
        //             font-size: 18px;
        //             line-height: 30px;
        //         }
        //     }
        // }

        .subscriptionListings {
           width: 100%;
    
           
            .courseItemWrapper {
                padding-bottom: 15px;
                padding-top: 15px;

                .courseItem {
                    background: #ffffff;
                    border: 1px solid rgba(255, 255, 255, 1);
                    border-radius: 10px;
                    -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
                    -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
                    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
                    padding: 20px 15px;

                    .courseHeading {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .courseTitle {
                            color: #000080;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 500;
                            font-style: normal;
                            font-size: 30px;
                            line-height: 50px;
                           
                            @media only screen and (max-width: 767px) {
                                font-size: 24px;
                                line-height: normal;
                            }
                        }

                        .coursePackage {
                            text-align: right;
                            color: #000080;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 400;
                            font-style: normal;
                            font-size: 14px;
                            line-height: 50px;
                        }
                    }

                    .courseDescription {
                        padding: 10px 0px;

                        p {
                            color: #707070;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 400;
                            font-style: normal;
                            font-size: 14px;
                            line-height: 16px;
                        }
                    }

                    .courseLinks {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &.center {
                            text-align: center;
                            justify-content: center;
                        }

                        .exploreLink, .subscribeLink {
                            padding: 0px 15px;
                            width: 115px;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 400;
                            font-style: normal;
                            font-size: 16px;
                            line-height: 40px;
                            text-align: center;
                            border-radius: 20px;
                            cursor: pointer;
                            @media only screen and (max-width: 767px) {
                                width: 108px;
                            }

                            @media only screen and (max-width: 575px) {
                                width: 115px;
                            }
                        }

                        .exploreLink {
                            background-color: #FF9933;
                            border: 1px solid #FF9933;
                            color: #FFFFFF;

                            &:hover {
                                background-color: #FFFFFF;
                                color: #FF9933;
                            }
                        }

                        .subscribeLink {
                            color: #FF9933;
                            background-color: #FFFFFF;
                            border: 1px solid #FF9933;
                            margin-left: 5px;
                           
                            @media only screen and (max-width: 575px) {
                                display: block;
                            }

                            &:hover {
                                color: #FFFFFF;
                                background-color: #FF9933;
                            }
                        }
                    }
                }
            }

            .loadMore {
                // padding-top: 25px;
                // padding-bottom: 25px;
                // text-align: center;
                text-transform: uppercase;
               
                .examwise {
                    cursor: pointer;
                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    padding: 5px 40px;
                    display: block;
                    
                    @media only screen and (max-width: 767px) {
                        font-size: 18px;
                        line-height: 40px;
                    }

                    @media only screen and (max-width: 639px) {
                        font-size: 16px;
                        line-height: 36px;
                    }

                    @media only screen and (max-width: 575px) {
                        font-size: 16px;
                        line-height: 34px;
                    
               
                        
                      
                        
                      }
                    
                }
            }
        }
    }
}

.packageScreenContainer .myPage .subscriptionListings .loadMore .active {
    background: #F6F9FC 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
}

.full_pack{
    padding: 10px 0px 9px 14px;
    border-radius: 5px;
   margin: 30px 0px;
    box-shadow: 0px 1px 6px 0px rgba(130,121,121,0.75);
    -webkit-box-shadow: 0px 1px 6px 0px rgba(130,121,121,0.75);
    -moz-box-shadow: 0px 1px 6px 0px rgba(130,121,121,0.75);
   
 }
 .full_pack .item{
    flex: 1 1 133px;
 }
 .full_pack h6{
    color: green;
    text-transform: uppercase;
}
 .full_pack .item span{
    color: #010177;
}

.full_pack p{
    font-size: 14px;
    text-transform: capitalize;
    margin-bottom: 0px !important;
}
.full_pack button{
    background-color: #ff9933;
    padding: 0px 15px;
    color: white;
    font-weight: 600;
}

@media only screen and (max-width: 480px) {
    .imgpsh {
        width: 100%;
        height: 100px;
       
    }
  }