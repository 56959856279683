.pageScreenContainer {
    &.PageNotFound {
        min-height: -o-calc(100vh);
        min-height: calc(100vh);
        height: auto;
        padding: 20px;

        .pageScreen {
            min-height: -o-calc(100vh - 40px);
            min-height: calc(100vh - 40px);
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .nfLogo {
                max-width: 200px;
            }
            .nfTitle {
                color: #FF9933;
                font-family: "Mulish", Arial, Helvetica, sans-serif;
                font-weight: 500;
                font-style: normal;
                font-size: 60px;
                line-height: 90px;
                margin: 15px 0px 0px 0px;
            }
            .nfSubTitle {
                color: #000080;
                font-family: "Mulish", Arial, Helvetica, sans-serif;
                font-weight: 500;
                font-style: normal;
                font-size: 30px;
                line-height: 40px;
                margin: 15px 0px 0px 0px;
            }
            .nfDesc {
                color: #414141;
                font-family: "Mulish", Arial, Helvetica, sans-serif;
                font-weight: 400;
                font-style: normal;
                font-size: 20px;
                line-height: 40px;
                margin: 15px 0px 0px 0px;
            }
            .nfLink {
                background-color: #FF9933;
                color: #FFFFFF;
                font-family: "Mulish", Arial, Helvetica, sans-serif;
                font-weight: 500;
                font-style: normal;
                font-size: 20px;
                line-height: 40px;
                padding: 0px 20px;
                border: 1px solid #ff9933;
                border-radius: 20px;
                cursor: pointer;
                &:hover {
                    border: 1px solid #d8832e;
                    background-color: #d8832e;
                }
            }
        }
    }
}