.AskedQuestionFAQs {
    display: block;
    width: 100%;
    margin-bottom: 80px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    margin-top: 50px;

    @media screen and (max-width: 1699px) {
        display: block;
        width: 100%;
        margin-bottom: 50px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        margin-top: 50px;
    }

    @media screen and (max-width: 1199px) {
        margin-bottom: 200px;
    }

    @media screen and (max-width: 767px) {
        margin-bottom: 170px;
    }

    @media screen and (max-width: 575px) {
        margin-bottom: 110px;
        margin-top: 20px;
    }

    .container {
        max-width: 1640px;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;

        @media screen and (max-width:1699px) {
            max-width: 1400px;
        }
    }

    .FlexQuestion {
        display: block;

        @media screen and (max-width: 991px) {
            flex-direction: column;
        }
    }

    .FrequentlyAsked {

        @media screen and (max-width: 991px) {
            width: 100%;
            margin-bottom: 100px;
            max-width: 100%;
        }

        @media screen and (max-width: 767px) {
            max-width: 100%;
            padding: 10px 0px 10px;
            margin-bottom: 0px;
        }

        h6 {
            font-size: 18px;
            font-weight: bold;
            color: #FF9933;
            font-family: "Mulish", Arial, Helvetica, sans-serif;

            @media screen and (max-width: 1199px) {}
        }

        h4 {
            font-size: 50px;
            line-height: 60px;
            color: #000080;
            font-weight: bold;
            font-family: "Mulish", Arial, Helvetica, sans-serif;
            margin-bottom: 40px;

            @media screen and (max-width: 1199px) {
                font-size: 42px;
                line-height: 50px;
            }

            @media screen and (max-width: 767px) {
                font-size: 36px;
                line-height: 44px;
            }

            @media screen and (max-width: 575px) {
                font-size: 30px;
                line-height: 40px;
                margin-bottom: 10px;
            }
        }
    }

    .FrequentlyAskedContent {
        @media screen and (max-width: 991px) {
            width: 100%;
        }

        .flex-content {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            @media screen and (max-width: 575px) {
                display: block;
            }

            p {
                font-size: 16px;
                line-height: 20px;
                font-weight: 600;
                color: #121212;
                font-family: "Mulish", Arial, Helvetica, sans-serif;

                @media screen and (max-width: 767px) {
                    font-size: 14px;
                    line-height: 20px;
                }

                &.current {
                    width: 98%;
                    padding: 32px 57px;
                    background-color: #FF9933;
                    color: #FFFFFF;

                    @media screen and (max-width: 1699px) {
                        width: 96%;
                        padding: 32px 57px;
                        background-color: #FF9933;
                        color: #FFFFFF;
                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                    }

                    @media screen and (max-width: 575px) {
                        padding: 20px 20px;
                    }
                }
            }

            h4 {
                color: #000;
                font-size: 24px;
                line-height: 30px;
                font-weight: bold;
                margin-bottom: 10px;
                font-family: "Mulish", Arial, Helvetica, sans-serif;
            }

            p {
                margin: 0px;

                @media screen and (max-width: 575px) {
                    font-size: 13px;
                }
            }

            .content {
                width: 49%;
                padding: 25px;
                background: #FFFFFF;
                box-shadow: 0px 3px 60px #00000029;
                border-radius: 8px;
                margin-bottom: 28px;

                @media screen and (max-width: 767px) {
                    padding: 20px;
                }

                @media screen and (max-width: 575px) {
                    width: 100%;
                    padding: 20px;
                }
            }
        }
    }
}
