.Subsc_heading{
    border-bottom: 2px solid #d3d3d3;
}

.subscription h1{
    text-transform: capitalize;
    margin-top: 111px;
}
.subscription h5{
    margin-bottom: 0px;
    text-transform: capitalize;
}
.subscription a{
    color: #ff9933;
    font-weight: 800;
}
.prents1{
   
    height: auto;
    flex-wrap: wrap;
    display: flex;
}
.child1{
    width: 210px;
    height: 80px;
  
}
.hrlines{
    border-bottom: 2px solid #d3d3d3;
    width: 100%;
}