.popupWrapper {
    &.policyModal {
        height: 550px;

        .popUpInner {

            .modal-heading {
                color: #000080;
                font-family: 'Mulish', sans-serif;
                font-size: 22px;
                font-weight: 500;
                padding: 25px 0px;
                margin: 0px;
                text-align: center;
            }

            .modalContent {
                text-align: left;

                p {
                    margin: 0px 0px 10px 0px;
                    font-family: 'Mulish', sans-serif;
                    font-weight: 400;
                    font-style: normal;
                    font-size: 16px;
                    line-height: 22px;
                    color: #505050;
                }

                h3 {
                    margin: 0px 0px 10px 0px;
                    font-family: 'Mulish', sans-serif;
                    font-size: 18px;
                    line-height: 30px;
                    font-weight: 500;
                }
            }


            .buttonContainer {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }
        }
    }
}
