.SmallButton {
    cursor: pointer;
    height: 42px;
    font-family: "Mulish", Arial, Helvetica, sans-serif;
    width: auto;
    opacity: 1;
    border-radius: 30px;
    font-size: 18px;
    line-height: 42px;
    outline: none;
    font-weight: 500;
    border: none;
    padding: 0px 20px;
    vertical-align: middle;
    min-width: 100px;

    &.h-30 {
        height: 30px;
        border-radius: 30px 30px;
        font-size: 16px;
        line-height: 30px;
        padding: 0px 25px;
    }

    .ButtonRingLoader {
        margin-left: 5px;
        vertical-align: middle;
    }

    &:disabled {
        cursor: not-allowed;
    }

    &:disabled:hover {
        cursor: not-allowed;
    }

    &.Success {
        color: #138808;
    }

    &.Danger {
        color: #C61A1A;
    }

    &.Orange {
        background-color: #FF9933;
        color: #ffffff;

        &:hover {
            background-color: #f77c00;
            color: #FFFFFF;
        }
    }

    &.PaymentVerify {
        background-color: #FF9933;
        color: #ffffff;

        &:hover {
            background-color: #f77c00;
            color: #FFFFFF;
        }
        @media only screen and (max-width: 425px) {
            margin: 15px auto 0px;
            display: block;
        }
    }

    &.Transparent {
        background-color: transparent;
        color: #FF9933;
        border: 1px solid #FF9933;

        &:hover {
            background-color: #FF9933;
            color: #FFFFFF;
        }
    }
}

.ButtonRingLoader {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
}

.ButtonRingLoader div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    margin: 2px;
    border: 2px solid #fff;
    border-radius: 50%;
    animation: ButtonRingLoader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}

.ButtonRingLoader div:nth-child(1) {
    animation-delay: -0.45s;
}

.ButtonRingLoader div:nth-child(2) {
    animation-delay: -0.3s;
}

.ButtonRingLoader div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes ButtonRingLoader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}