.popupWrapper {
    &.Auth {
        .popUpInner {
            .lTab {
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }

                &.Active {
                    text-decoration: underline;
                }
            }

            .nav-link-container {
                margin-top: 40px;

                &.noMargin {
                    margin-top: 0px;
                }

                .createAccountLink,
                .resetPasswordLink {
                    font-family: 'Mulish', sans-serif;
                    color: #313131;
                    display: block;
                    margin-bottom: 10px;
                    text-align: center;
                    font-size: 16px;
                    line-height: 30px;

                    .Link {
                        cursor: pointer;
                        text-decoration: underline;

                        &:hover {
                            color: #FF9933;
                        }
                    }
                }
            }

            .socialLinkContainer {
                margin-top: 15px;

                .createAccountLink {
                font-family: "Mulish", Arial, Helvetica, sans-serif;
                color: #313131;
                display: block;
                margin-bottom: 10px;
                text-align: center;
                font-size: 16px;
                line-height: 30px;
                font-weight: 600;

                .SocialLink {
                    cursor: pointer;
                    text-decoration: underline;
                    margin-left: 15px;

                    &:hover {
                        color: #FF9933;
                    }
                }
            }
            }

            .buttonContainer {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }

            .successSubmitWrapper {
                margin: 20px auto;
                max-width: 100px;
                text-align: center;

                .successSubmit {
                    margin: 20px auto;
                    max-width: 100px;
                    text-align: center;
                }
            }
        }
    }
}
