.pageScreenContainer {
    .myPage {
        .pageTitleBox {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;

            @media only screen and (max-width: 767px) {
                flex-direction: column;
            }

            .titleBox {
                @media only screen and (max-width: 767px) {
                    width: 100%;
                }

                h1 {
                    display: block;
                    color: #000080;
                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                    font-weight: 500;
                    font-style: normal;
                    font-size: 30px;
                    line-height: 40px;
                    margin-bottom: 10px;

                    @media only screen and (max-width: 767px) {
                        font-size: 24px;
                    }

                    @media only screen and (max-width: 639px) {
                        font-size: 22px;
                    }

                    @media only screen and (max-width: 575px) {
                        font-size: 20px;
                    }
                }
            }
        }

        .myPageWrapper {
            &.ACEGameLists {
                .referralSectionTitle {
                    h2 {
                        color: #000080;
                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                        font-weight: 400;
                        font-style: normal;
                        font-size: 22px;
                        line-height: 30px;
                    }
                }

                .referralSectionHistory {
                    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
                    -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
                    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
                    padding: 8px 18px 8px 18px;
                    border-radius: 15px;

                    table {
                        thead {
                            tr {
                                th {
                                    color: #000080;
                                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                                    font-weight: 400;
                                    font-style: normal;
                                    font-size: 18px;
                                    line-height: 30px;
                                }
                            }
                        }
                    }

                    .referAction {
                        color: #FFFFFF;
                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                        font-weight: 400;
                        font-style: normal;
                        font-size: 16px;
                        line-height: 30px;
                        text-transform: capitalize;
                        padding: 7px 14px;
                        border-radius: 15px;
                        outline: none;

                        &.remind {
                            background: #FF9933;
                            cursor: pointer;

                            &:hover {
                                background: #bc5e00;
                            }
                        }

                        &.earned {
                            background: #1F9B3C;
                        }
                    }

                    .actionWrapper {
                        .gameActionLink {
                            color: #FF9933;
                            cursor: pointer;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 400;
                            font-style: normal;
                            font-size: 16px;
                            line-height: 30px;
                            text-transform: capitalize;
                            padding: 7px 14px;
                            border-radius: 15px;
                            border: 1px solid #FF9933;
                            outline: none;

                            &:hover {
                                background-color: #FF9933;
                                color: #FFFFFF;
                                border: 1px solid #FF9933;
                            }
                        }
                    }
                }
            }
        }
    }
}