.popupWrapper {
    .popUpInner {
        text-align: center;

        .successActionIcon {
            max-width: 150px;
            padding: 30px 0px;
        }
    }

}
