.pageHeader {
    width: -moz-calc(100% - 70px);
    width: -webkit-calc(100% - 70px);
    width: -o-calc(100% - 70px);
    width: calc(100% - 70px);
    z-index: 20;
    overflow: visible;
    position: fixed;
    height: 90px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;

    &.WithoutAuth {
        width: 100%;
    }

    @media only screen and (max-width: 1330px) {
        height: 90px;
    }

    @media only screen and (max-width: 1199px) {
        height: 90px;
    }

    @media only screen and (max-width: 991px) {
        height: 60px;
        width: -moz-calc(100% - 0px);
        width: -webkit-calc(100% - 0px);
        width: -o-calc(100% - 0px);
        width: calc(100% - 0px);
    }

    .pageHeaderInner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @media only screen and (max-width: 991px) {
            padding: 10px 0px;
            justify-content: flex-start;
        }

        .mobileView {
            padding: 10px 0px;
            display: none;

            @media only screen and (max-width: 991px) {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 0px;
                width: 100%;
            }

            .hamburgerIcon {
                display: block;
                padding: 5px 10px 0px 0px;
                height: 40px;
                width: 40px;
                text-align: center;
                cursor: pointer;

                &.landing {
                    @media only screen and (max-width: 991px) {
                        display: block;
                    }
                }

                .hamburgerImage {
                    height: 30px;
                    width: 30px;
                    cursor: pointer;
                }
            }

            .headerLogoWrapper {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;

                @media only screen and (max-width: 991px) {
                    margin-left: 15px;
                }

                .headerLogo {
                    width: 85px;
                    line-height: 0px;
                    position: relative;

                    @media only screen and (max-width: 991px) {
                        max-height: 40px;
                        width: auto;
                    }

                    .portalLogo {
                        max-width: 76px;
                        cursor: pointer;

                        @media only screen and (max-width: 991px) {
                            max-height: 40px;
                            width: auto;
                        }
                    }
                }
            }

            .settingsIcon {
                display: block;
                padding: 5px 0px 0px 0px;
                height: 40px;
                width: 40px;
                text-align: center;
                cursor: pointer;

                &.moveLeft {
                    margin-left: auto;
                }

                &.notificationBubble {
                    position: relative;

                    .bubbleIcon {
                        position: absolute;
                        top: 0px;
                        right: -3px;
                        background: #FC0000;
                        border: 1px solid #FC0000;
                        width: 20px;
                        height: 20px;
                        font-size: 12px;
                        line-height: 12px;
                        font-family: 'Mulish';
                        padding: 0px;
                        margin: 0px;
                        color: #FFFFFF;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-weight: 500;
                    }
                }

                .settingsImage {
                    height: 30px;
                    width: 30px;
                    cursor: pointer;
                }

                .userProfileImage {
                    border: 1px solid #cdcdcd;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }

            .HeaderMenuItems {

                .nav {
                    @media only screen and (max-width: 991px) {
                        margin: 0px 0px !important;
                    }

                    li {
                        &.userInfo {
                            @media only screen and (max-width: 991px) {
                                display: none !important;
                            }
                        }

                        span {
                            @media only screen and (max-width: 991px) {
                                padding: 0px 0px !important;
                            }
                        }
                    }
                }
            }

            .languageSection {
                &.close {
                    display: none;
                }

                &.open {
                    flex-direction: column !important;
                    display: flex !important;
                }

                width: 360px;
                position: fixed;
                top: 90px;

                @media only screen and (max-width: 991px) {
                    top: 60px;
                }

                bottom: 0;
                right: 0;
                flex: 0 0 auto;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                -webkit-box-shadow: -2px 1px 6px 0px rgba(0, 0, 0, 0.19);
                -moz-box-shadow: -2px 1px 6px 0px rgba(0, 0, 0, 0.19);
                box-shadow: -2px 1px 6px 0px rgba(0, 0, 0, 0.19);
                z-index: 25;
                outline: none;
                height: 120px;
                padding: 15px 15px;
                overflow: auto;

                .languageSectionMenu {
                    li {
                        list-style: none;
                        list-style-type: none;
                        margin: 0px 0px 5px 0px;
                        display: block;

                        span {
                            cursor: pointer;
                            position: relative;
                            text-decoration: none;
                            -webkit-transition: all .1s linear;
                            transition: all .1s linear;
                            color: #444444;
                            font-family: 'Mulish', Arial, Helvetica, sans-serif;
                            font-size: 18px;
                            line-height: 40px;

                            &:hover {
                                color: #FF9933;
                            }

                            &.orange {
                                color: #FF9933;

                                &:hover {
                                    color: #444444;
                                }
                            }

                            &.date {
                                color: #FF9933;
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }

            .searchSection {
                &.close {
                    display: none;
                }

                &.open {
                    flex-direction: column !important;
                    display: flex !important;
                }

                width: 100%;
                position: fixed;
                top: 90px;

                @media only screen and (max-width: 991px) {
                    top: 60px;
                }

                bottom: 0;
                right: 0;
                flex: 0 0 auto;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                -webkit-box-shadow: -2px 1px 6px 0px rgba(0, 0, 0, 0.19);
                -moz-box-shadow: -2px 1px 6px 0px rgba(0, 0, 0, 0.19);
                box-shadow: -2px 1px 6px 0px rgba(0, 0, 0, 0.19);
                z-index: 25;
                outline: none;
                height: 80px;
                padding: 10px 15px;
                overflow: auto;

                .searchInputBox {
                    position: relative;
                    margin: 0;
                    transition: all 0.5s ease;
                    display: block;
                    padding: 10px 15px;
                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                    font-weight: 400;
                    font-style: normal;
                    font-size: 16px;
                    line-height: 26px;
                    color: #464752;
                    cursor: pointer;

                    .searchInput {
                        height: 40px;
                        padding: 5px 15px 5px 15px;
                        width: 100%;
                        min-width: 100%;
                        background: #FFFFFF 0% 0% no-repeat padding-box;
                        /*
                        -webkit-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.15);
                        -moz-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.15);
                        box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.15);
                        */
                        border-radius: 20px;
                        border: 1px solid rgba(0, 0, 0, 0.15);
                        opacity: 1;
                    }
                }
            }
        }

        .deskTopView {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 15px 0px;

            @media only screen and (max-width: 991px) {
                display: none;
            }

            .headerLogoWrapper {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;

                @media only screen and (max-width: 991px) {
                    margin-left: 15px;
                }

                .headerLogo {
                    width: 85px;
                    line-height: 0px;
                    position: relative;

                    @media only screen and (max-width: 991px) {
                        max-height: 40px;
                        width: auto;
                    }

                    .portalLogo {
                        max-width: 76px;
                        cursor: pointer;

                        @media only screen and (max-width: 991px) {
                            max-height: 40px;
                            width: auto;
                        }
                    }
                }

                .headerTime {
                    text-align: left;
                    margin-left: 10px;

                    @media only screen and (max-width: 1199px) {
                        display: none;
                    }

                    .heading {
                        display: block;
                        color: #000080;
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 34px;
                    }

                    .time {
                        display: block;
                        color: #888888;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 22px;
                    }
                }
            }

            .HeaderMenuItems {
                .nav {
                    list-style: none;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-pack: justify;
                    -ms-flex-pack: justify;
                    justify-content: space-between;
                    margin: 0 auto;
                    padding: 0;
                    align-items: center;

                    li {
                        display: inline-block;
                        vertical-align: top;
                        position: relative;
                        margin-bottom: 0px;

                        .menu-item-has-children {
                            span {
                                &:after {
                                    content: '';
                                    display: inline-block;
                                    width: 0;
                                    height: 0;
                                    margin: 0px 0px -2px 15px;
                                    vertical-align: middle;
                                    border: solid #464752;
                                    border-width: 0 2px 2px 0;
                                    padding: 3px;
                                    transform: rotate(45deg);
                                    -webkit-transform: rotate(45deg);
                                    vertical-align: super;
                                }
                            }
                        }

                        span {
                            -webkit-transition: all 0.5s ease;
                            -moz-transition: all 0.5s ease;
                            -o-transition: all 0.5s ease;
                            transition: all 0.5s ease;
                            display: block;
                            padding: 10px 15px;

                            @media only screen and (max-width: 991px) {
                                padding: 10px 10px;
                            }

                            font-family: "Mulish",
                            Arial,
                            Helvetica,
                            sans-serif;
                            font-weight: 400;
                            font-style: normal;
                            font-size: 16px;
                            line-height: 26px;
                            color: #464752;
                            cursor: pointer;

                            .menuIcon {
                                width: 30px;
                                height: 30px;
                                cursor: pointer;
                            }

                            &.notificationBubble {
                                position: relative;

                                .bubbleIcon {
                                    position: absolute;
                                    top: 5px;
                                    right: 5px;
                                    background: #FC0000;
                                    border: 1px solid #FC0000;
                                    width: 20px;
                                    height: 20px;
                                    font-size: 12px;
                                    line-height: 12px;
                                    font-family: 'Mulish';
                                    padding: 0px;
                                    margin: 0px;
                                    color: #FFFFFF;
                                    border-radius: 50%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    font-weight: 500;
                                }
                            }
                        }

                        .searchInputBox {
                            position: relative;
                            margin: 0;
                            transition: all 0.5s ease;
                            display: block;
                            padding: 10px 15px;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 400;
                            font-style: normal;
                            font-size: 16px;
                            line-height: 26px;
                            color: #464752;
                            cursor: pointer;

                            .searchInput {
                                height: 40px;
                                margin: 0;
                                padding: 5px 55px 5px 15px;
                                min-width: 400px;

                                @media only screen and (max-width: 991px) {
                                    min-width: 260px;
                                }

                                background: #FFFFFF 0% 0% no-repeat padding-box;
                                /*
                                -webkit-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.15);
                                -moz-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.15);
                                box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.15);
                                */
                                border-radius: 20px;
                                border: 1px solid rgba(0, 0, 0, 0.15);
                                opacity: 1;
                            }

                            .menuIcon {
                                width: 30px;
                                height: 30px;
                                cursor: pointer;
                                position: absolute;
                                top: 15px;
                                right: 20px;
                            }
                        }

                        ul {
                            position: absolute;
                            display: none;
                            border: none;
                            border-radius: 0px;
                            min-width: 180px;
                            top: 60px;
                            left: 0px;
                            padding: 0;
                            margin: 0px;
                            background: #FFFFFF;
                            -webkit-box-shadow: 0px 0px 15px 5px rgba(205, 205, 205, 0.5);
                            -moz-box-shadow: 0px 0px 15px 5px rgba(205, 205, 205, 0.5);
                            box-shadow: 0px 0px 15px 5px rgba(205, 205, 205, 0.5);
                            list-style: none;
                            z-index: 30;

                            &.close {
                                display: none;
                            }

                            &.open {
                                display: inline-block;
                            }
                        }
                    }

                    .languageSelection {
                        ul.open {
                            li {
                                border-bottom: 1px solid #DCDCDC;
                                margin: 0px;
                                display: block;

                                span {
                                    .fa {
                                        font-size: 24px;
                                    }

                                    &.orange, &:hover {
                                        color: #fff;
                                        background: #FF9933;
                                    }                                   
                                }
                            }
                        }
                    }

                    .userInfo {
                        span.userInfoSectionWrapper {
                            background: #FFFFFF 0% 0% no-repeat padding-box;
                            -webkit-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.15);
                            -moz-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.15);
                            box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.15);
                            box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.15);
                            border-radius: 10px;

                            .userInfoSection {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: center;

                                &:after {
                                    content: '';
                                    display: inline-block;
                                    width: 0;
                                    height: 0;
                                    margin: 0px 0px -2px 15px;
                                    vertical-align: middle;
                                    border: solid #FF9933;
                                    border-width: 0 2px 2px 0;
                                    padding: 3px;
                                    transform: rotate(45deg);
                                    -webkit-transform: rotate(45deg);
                                    vertical-align: super;
                                }

                                .userImageWrapper {
                                    .userImage {
                                        width: 30px;
                                        height: 30px;
                                        border: 1px solid #dcdcdc;
                                        border-radius: 50%;
                                        object-fit: cover;
                                    }
                                }

                                .userDetails {
                                    margin-left: 10px;

                                    .name {
                                        margin: 0px;
                                        padding: 0px;
                                        color: #000080;
                                        font-size: 16px;
                                        line-height: 18px;
                                        font-weight: 400;

                                        .salute {
                                            display: inline-block;
                                            padding: 0px;
                                            min-width: 35px;
                                            text-align: right;
                                            color: #000080;
                                            font-size: 16px;
                                            line-height: 18px;
                                            font-weight: 400;
                                        }
                                    }

                                    .role {
                                        margin: 0px;
                                        padding: 0px;
                                        color: #000080;
                                        font-size: 14px;
                                        line-height: 16px;
                                        font-weight: 400;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .accountSection {
            &.close {
                display: none;
            }

            &.open {
                flex-direction: column !important;
                display: flex !important;
            }

            width: 360px;
            position: fixed;
            top: 90px;

            @media only screen and (max-width: 991px) {
                top: 60px;
            }

            @media only screen and (max-width: 479px) {
                width: 280px;
            }

            bottom: 0;
            right: 0;
            flex: 0 0 auto;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            -webkit-box-shadow: -2px 1px 6px 0px rgba(0, 0, 0, 0.19);
            -moz-box-shadow: -2px 1px 6px 0px rgba(0, 0, 0, 0.19);
            box-shadow: -2px 1px 6px 0px rgba(0, 0, 0, 0.19);
            z-index: 30;
            outline: none;
            height: auto;
            min-height: -moz-calc(100% - 90px);
            min-height: -webkit-calc(100% - 90px);
            min-height: -o-calc(100% - 90px);
            min-height: calc(100% - 90px);
            padding: 20px 15px;
            overflow: auto;

            .divider {
                margin-bottom: 15px;
                margin-top: 15px;
                width: 100%;
                color: #cdcdcd;
                background: #cdcdcd;
                height: 1px;
                display: block;
            }

            .userDetails {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;

                .userImageWrapper {
                    max-width: 80px;

                    @media only screen and (max-width: 479px) {
                        max-width: 60px;
                    }

                    .userImage {
                        width: 80px;
                        height: 80px;
                        border: 1px solid #cdcdcd;
                        border-radius: 50%;
                        object-fit: cover;

                        @media only screen and (max-width: 479px) {
                            width: 60px;
                            height: 60px;
                        }
                    }
                }

                .userInfo {
                    margin-left: 15px;

                    .name {
                        display: block;
                        color: #000080;
                        font-size: 20px;
                        line-height: 28px;
                        font-weight: 500;

                        @media only screen and (max-width: 479px) {
                            font-size: 18px;
                            line-height: 28px;
                        }
                    }

                    .email {
                        display: block;
                        color: #777777;
                        font-size: 16px;
                        line-height: 26px;
                        font-weight: 400;

                        @media only screen and (max-width: 479px) {
                            font-size: 14px;
                            line-height: 24px;
                        }
                    }

                    .package {
                        display: block;
                        color: #999999;
                        font-size: 14px;
                        line-height: 22px;
                        font-weight: 400;

                        @media only screen and (max-width: 479px) {
                        }
                    }
                }
            }

            .accountSectionMenu {
                margin: 0px;
                padding: 0px;

                .divider {
                    margin-bottom: 15px;
                    margin-top: 15px;
                    width: 100%;
                    color: #cdcdcd;
                    background: #cdcdcd;
                    height: 1px;
                    display: block;
                }

                li {
                    list-style: none;
                    list-style-type: none;
                    margin: 0px 0px 5px 0px;

                    span {
                        cursor: pointer;
                        position: relative;
                        text-decoration: none;
                        -webkit-transition: all .1s linear;
                        transition: all .1s linear;
                        color: #444444;
                        font-family: 'Mulish', Arial, Helvetica, sans-serif;
                        font-size: 18px;
                        line-height: 40px;

                        @media only screen and (max-width: 479px) {
                            font-size: 16px;
                            line-height: 36px;
                        }

                        &:hover {
                            color: #FF9933;
                        }

                        &.orange {
                            color: #FF9933;

                            &:hover {
                                color: #444444;
                            }
                        }
                    }
                }
            }
        }

        .notificationSection {
            &.close {
                display: none;
            }

            &.open {
                flex-direction: column !important;
                display: flex !important;
            }

            width: 360px;
            position: fixed;
            top: 90px;

            @media only screen and (max-width: 991px) {
                top: 60px;
            }

            @media only screen and (max-width: 479px) {
                width: 280px;
            }

            bottom: 0;
            right: 0;
            flex: 0 0 auto;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            -webkit-box-shadow: -2px 1px 6px 0px rgba(0, 0, 0, 0.19);
            -moz-box-shadow: -2px 1px 6px 0px rgba(0, 0, 0, 0.19);
            box-shadow: -2px 1px 6px 0px rgba(0, 0, 0, 0.19);
            z-index: 25;
            outline: none;
            height: auto;
            min-height: -moz-calc(100% - 90px);
            min-height: -webkit-calc(100% - 90px);
            min-height: -o-calc(100% - 90px);
            min-height: calc(100% - 90px);
            padding: 20px 15px;
            overflow: auto;

            h4 {
                color: #000080;
                font-family: 'Mulish', Arial, Helvetica, sans-serif;
                font-size: 18px;
                line-height: 28px;
            }

            .notificationSectionMenu {
                padding: 0px;

                li {
                    list-style: none;
                    list-style-type: none;
                    margin: 0px 0px 5px 0px;
                    display: block;
                    
                    &.border-line {
                        box-shadow: 0px 2px 0px #e7d9d9;
                    }
                    span {
                        cursor: pointer;
                        position: relative;
                        text-decoration: none;
                        -webkit-transition: all .1s linear;
                        transition: all .1s linear;
                        color: #414141;
                        font-family: 'Mulish', Arial, Helvetica, sans-serif;
                        font-size: 14px;
                        line-height: 26px;
                        display: inline;

                        @media only screen and (max-width: 575px) {
                            font-size: 14px;
                            line-height: 24px;
                        }

                        &.date {
                            color: #000080;
                            margin-right: 10px;
                            display: inline;
                            font-weight: 500;

                            @media only screen and (max-width: 575px) {
                                font-size: 14px;
                                line-height: 24px;
                            }
                        }

                        &.content {
                            color: #414141;
                            display: inline;

                            @media only screen and (max-width: 575px) {
                                font-size: 14px;
                                line-height: 24px;
                            }

                            p {
                                color: #414141;
                                margin: 0px;
                                display: inline;

                                @media only screen and (max-width: 575px) {
                                    font-size: 14px;
                                    line-height: 24px;
                                }
                            }
                        }
                    }
                }
            }

            .readAll{
                text-align: center;
                cursor: pointer;
                display: inline-block;
                font-family: 'Mulish', Arial, Helvetica, sans-serif;
                font-size: 18px;
                line-height: 28px;
                background-color: #FFFFFF;
                color: #FF9933;
                border: 1px solid #FF9933;
                border-radius: 10px;

                &:hover {
                    border: 1px solid #FF9933;
                    background-color: #FF9933;
                    color: #FFFFFF;
                }
            }
        }
    }
}
