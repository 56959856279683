.pageScreenContainer {
    .myPage {
        .pageTitleBox {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;

            @media only screen and (max-width: 767px) {
                flex-direction: column;
            }

            .titleBox {
                @media only screen and (max-width: 767px) {
                    width: 100%;
                }

                h1 {
                    display: block;
                    color: #000080;
                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                    font-weight: 500;
                    font-style: normal;
                    font-size: 30px;
                    line-height: 40px;
                    margin-bottom: 10px;

                    @media only screen and (max-width: 767px) {
                        font-size: 24px;
                    }

                    @media only screen and (max-width: 639px) {
                        font-size: 22px;
                    }

                    @media only screen and (max-width: 575px) {
                        font-size: 18px;
                    }
                }

                .subHeading {
                    margin: 0px 0px 10px 0px;
                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                    font-weight: 400;
                    font-style: normal;
                    font-size: 20px;
                    line-height: 30px;
                    color: #000000;

                    @media only screen and (max-width: 767px) {
                        font-size: 18px;
                        line-height: 28px;
                    }

                    @media only screen and (max-width: 576px) {
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
            }

            .codeBox {
                text-align: right;

                @media only screen and (max-width: 767px) {
                    text-align: left;
                    width: 100%;
                    display: inline-block;
                }

                .codeTitle {
                    margin: 0px 0px 10px 0px;
                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                    font-weight: 400;
                    font-style: normal;
                    font-size: 20px;
                    line-height: 30px;
                    color: #707070;

                    @media only screen and (max-width: 575px) {
                        font-size: 14px;
                    }
                }

                .codeText {
                    display: block;
                    color: #000080;
                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                    font-weight: 500;
                    font-style: normal;
                    font-size: 30px;
                    line-height: 40px;

                    @media only screen and (max-width: 767px) {
                        font-size: 24px;
                        display: inline-block;
                        margin-left: 15px;
                    }

                    @media only screen and (max-width: 639px) {
                        font-size: 22px;
                    }

                    @media only screen and (max-width: 575px) {
                        font-size: 16px;
                        margin-left: 10px;
                    }
                }

                .codeCopy {
                    color: #FF9933;
                    background-color: #ffffff;
                    padding: 5px;
                    border: 1px solid #FF9933;
                    border-radius: 5px;
                    cursor: pointer;
                    -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
                    -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
                    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);

                    @media only screen and (max-width: 767px) {
                        margin-left: 15px;
                    }

                    @media only screen and (max-width: 575px) {
                        margin-left: 10px;
                    }

                    &:hover {
                        color: #ffffff;
                        background-color: #FF9933;
                    }

                    .codeCopyIcon {
                        font-size: 20px;
                        line-height: 24px;
                    }
                }
            }
        }

        .inviteBox {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 30px 0px;

            @media only screen and (max-width: 1199px) {
                display: block;
            }

            @media only screen and (max-width: 575px) {
                padding: 20px 0px;
            }

            .inviteUser {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;

                @media only screen and (max-width: 576px) {
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                }

                .inviteUserInputTitle {
                    span {
                        color: #000080;
                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                        font-weight: 400;
                        font-style: normal;
                        font-size: 20px;
                        line-height: 30px;

                        @media only screen and (max-width: 576px) {
                            font-size: 18px;
                        }
                    }
                }

                .inviteUserInputBox {
                    -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
                    -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
                    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
                    padding: 8px 18px 8px 18px;
                    border-radius: 15px;
                    margin-left: 25px;

                    @media only screen and (max-width: 575px) {
                        margin-left: 0px;
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                    }

                    // @media only screen and (max-width: 425px) {
                    //     display: block;
                    // }

                    .inputElement {
                        border: none;
                        margin: 0px;
                        min-width: 320px;
                        width: auto;

                        @media only screen and (max-width: 575px) {
                            min-width: auto;
                            font-size: 14px;
                            max-width: 150px;
                            margin-right: 10px;
                        }
                        // @media only screen and (max-width: 425px) {
                        //     margin-bottom: 10px;
                        // }

                    }

                    .SmallButton {
                        padding: 0px 22px;
                        font-size: 14px;
                    }

                    .inputButton {
                        background-color: #FF9933;
                        color: #ffffff;
                        height: 34px;
                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                        width: auto;
                        opacity: 1;
                        border-radius: 34px 34px;
                        font-size: 16px;
                        line-height: 34px;
                        outline: none;
                        font-weight: 400;
                        border: none;
                        padding: 0px 20px;
                        vertical-align: middle;
                        cursor: pointer;
                    }
                }

            }

            .availableRewards {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                @media only screen and (max-width: 1199px) {
                    justify-content: flex-start;
                    margin-top: 15px;
                }

                @media only screen and (max-width: 575px) {
                    display: block;
                }

                .rewardsTitle {
                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                    font-weight: 400;
                    font-style: normal;
                    font-size: 20px;
                    line-height: 40px;
                    color: #707070;

                    @media only screen and (max-width: 575px) {
                        font-size: 18px;
                        display: block;
                    }
                }

                .rewardsAmount {
                    color: #000080;
                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                    font-weight: 500;
                    font-style: normal;
                    font-size: 30px;
                    line-height: 40px;
                    margin-left: 20px;

                    @media only screen and (max-width: 575px) {
                        font-size: 24px;
                        line-height: 30px;
                        margin-left: 0px;
                    }
                }

                .rewardsEncashButton {
                    background-color: #FFFFFF;
                    color: #FF9933;
                    border: 1px solid #FF9933;
                    height: 40px;
                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                    width: auto;
                    opacity: 1;
                    border-radius: 40px 40px;
                    font-size: 16px;
                    line-height: 40px;
                    outline: none;
                    font-weight: 400;
                    padding: 0px 20px;
                    vertical-align: middle;
                    cursor: pointer;
                    margin-left: 20px;

                    &:hover {
                        background-color: #FF9933;
                        color: #FFFFFF;
                    }

                  
                    
                }
            }
        }

        .myPageWrapper {
            &.myReferralHistory {
                .referralSectionTitle {
                    h2 {
                        color: #000080;
                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                        font-weight: 400;
                        font-style: normal;
                        font-size: 22px;
                        line-height: 30px;

                        @media only screen and (max-width: 576px) {
                            font-size: 20px;
                        }
                    }
                }

                .referralSectionHistory {
                    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
                    -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
                    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
                    padding: 8px 18px 8px 18px;
                    border-radius: 15px;

                    table {
                        thead {
                            tr {
                                th {
                                    color: #000080;
                                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                                    font-weight: 400;
                                    font-style: normal;
                                    font-size: 18px;
                                    line-height: 30px;

                                    @media only screen and (max-width: 576px) {
                                        font-size: 16px;
                                    }
                                }
                            }
                        }
                    }

                    .referAction {
                        color: #FFFFFF;
                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                        font-weight: 400;
                        font-style: normal;
                        font-size: 16px;
                        line-height: 30px;
                        text-transform: capitalize;
                        padding: 7px 14px;
                        border-radius: 15px;
                        outline: none;

                        &.remind {
                            background: #FF9933;
                            cursor: pointer;

                            &:hover {
                                background: #bc5e00;
                            }
                        }

                        &.earned {
                            background: #1F9B3C;
                        }
                    }

                }
            }
        }
    }
}