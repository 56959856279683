.pageScreenContainer {
    .myPage {
        .pageTitle {
            .goBackViewFile {
                font-size: 20px;
                line-height: 40px;
                margin-right: 15px;
                cursor: pointer;
                vertical-align: middle;
            }
        }

        .myPageWrapper {
            &.gameRulesPage {

                .custom-container {
                    width: 100%;
                }

                .ruleRow {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-pack: justify;
                    justify-content: space-between;
                    margin: 0px 25px;

                    @media only screen and (max-width:991px) {
                        margin: 0px 20px;
                    }

                    @media only screen and (max-width:767px) {
                        flex-direction: column;
                    }

                    @media only screen and (max-width:576px) {
                        margin: 0px 15px;
                    }

                    .leftText {
                        width: 46%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        @media only screen and (max-width:767px) {
                            width: 100%;
                        }

                        .number {
                            height: 36px;
                            width: 36px;
                            background-color: #ffffff;
                            border-radius: 3px;
                            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.20);
                            text-align: center;
                            font-weight: 500;
                            font-size: 22px;
                            line-height: 37px;
                            color: #000080;

                            @media only screen and (max-width:1440px) {
                                height: 30px;
                                width: 30px;
                                font-size: 20px;
                                line-height: 30px;
                            }

                            @media only screen and (max-width:991px) {
                                height: 28px;
                                width: 28px;
                                font-size: 19px;
                                line-height: 29px;
                            }
                        }

                        p {
                            width: 92%;
                            padding: 20px 0px 20px 13px;
                            border-bottom: 1px dashed #707070;
                            font-size: 16px;
                            line-height: 19px;
                            color: #707070;
                            margin-bottom: 0px;

                            @media only screen and (max-width:1440px) {
                                font-size: 15px;
                                padding: 17px 0px 17px 13px;
                            }

                            @media only screen and (max-width:991px) {
                                font-size: 14px;
                            }
                        }
                    }

                    .rightText {
                        width: 46%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        @media only screen and (max-width:767px) {
                            width: 100%;
                        }

                        .number {
                            height: 36px;
                            width: 36px;
                            background-color: #ffffff;
                            border-radius: 3px;
                            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.20);
                            text-align: center;
                            font-weight: 500;
                            font-size: 22px;
                            line-height: 37px;
                            color: #000080;

                            @media only screen and (max-width:1440px) {
                                height: 30px;
                                width: 30px;
                                font-size: 20px;
                                line-height: 30px;
                            }

                            @media only screen and (max-width:991px) {
                                height: 28px;
                                width: 28px;
                                font-size: 19px;
                                line-height: 29px;
                            }
                        }

                        p {
                            width: 92%;
                            padding: 20px 0px 20px 13px;
                            border-bottom: 1px dashed #707070;
                            font-size: 16px;
                            line-height: 19px;
                            color: #707070;
                            margin-bottom: 0px;

                            @media only screen and (max-width:1440px) {
                                font-size: 15px;
                                padding: 17px 0px 17px 13px;
                            }

                            @media only screen and (max-width:991px) {
                                font-size: 14px;
                            }
                        }
                    }
                }

                .playBtn {
                    text-align: center;
                    padding: 40px 0px 20px;

                    label {
                        margin-bottom: 15px;

                        @media only screen and (max-width:576px) {
                            font-size: 14px;
                            line-height: 16px;
                        }
                    }

                    .playNow {
                        font-size: 26px;
                        line-height: 37px;
                        font-weight: 500;
                        color: #FFFFFF;
                        background-color: #FF9933;
                        border-radius: 38px;
                        padding: 10px 58px;
                        text-decoration: none;
                        outline: unset;
                        border: none;

                        @media only screen and (max-width:1440px) {
                            font-size: 20px;
                            line-height: 30px;
                            padding: 8px 52px;
                        }

                        @media only screen and (max-width:991px) {
                            font-size: 18px;
                        }

                        @media only screen and (max-width:767px) {
                            font-size: 17px;
                            line-height: 26px;
                            padding: 8px 46px;
                        }

                        @media only screen and (max-width:576px) {
                            font-size: 16px;
                            line-height: 24px;
                            padding: 8px 44px;
                        }
                    }

                    .policyApprove {
                        .checkboxContainer {
                            display: inline-block;
                        }
                    }

                    p {
                        input {
                            -webkit-appearance: auto;
                        }
                    }
                }
            }
        }
    }
}