.myPageWrapper {
    &.myPayment {

        h3 {
            color: #000080;
            font-family: "Mulish", Arial, Helvetica, sans-serif;
            font-weight: 500;
            font-style: normal;
            font-size: 22px;
            line-height: 28px;

            @media only screen and (max-width: 639px) {
                font-size: 20px;
                line-height: 28px;
            }
        }

        h4 {
            margin-bottom: 5px;

            @media only screen and (max-width: 639px) {
                font-size: 18px;
                line-height: 28px;
                font-weight: 500;
            }
        }

        p {
            margin-bottom: 5px;

            @media only screen and (max-width: 639px) {
                font-size: 16px;
                line-height: 28px;
                word-break: break-word;
            }
        }

        .openLink {
            text-decoration: underline;
            cursor: pointer;

            &:hover {
                color: #FF9933;
            }
        }

        .PayNow {
            cursor: pointer;
            font-family: "Mulish", Arial, Helvetica, sans-serif;
            font-weight: 500;
            font-style: normal;
            line-height: 34px;
            font-size: 18px;
            background: #FF9933;
            border: none;
            color: #FFF;
            padding: 10px 20px;
            transition: 0.5s ease-in-out all;
            -webkit-transition: 0.5s ease-in-out all;
            -ms-transition: 0.5s ease-in-out all;
            -moz-transition: 0.5s ease-in-out all;
            -o-transition: 0.5s ease-in-out all;

            &:hover {
                background-color: #138808;
                color: #FFF;
            }

            &:disabled {
                cursor: not-allowed;
            }
        }

        .referralInputElement {
            background: #ffffff;
            padding: 5px 15px;
            border: 1px solid rgba(230, 230, 230, 1);
            border-radius: 10px;
            -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
            -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
            box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
            margin-right: 10px;

            @media only screen and (max-width: 425px) {
                margin: 0px auto;
                display: block;
            }
        }

        .UserBillingDetails {
            background: #ffffff;
            padding: 15px;
            border: 1px solid rgba(255, 255, 255, 1);
            border-radius: 10px;
            -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
            -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
            box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
            margin-bottom: 20px;
        }

        .ReferralCodeDetails {
            background: #ffffff;
            padding: 15px;
            border: 1px solid rgba(255, 255, 255, 1);
            border-radius: 10px;
            -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
            -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
            box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
            margin-bottom: 20px;

            .couponCode {
                border: 1px solid #cdcdcd;
                padding: 10px 10px;
                font-size: 18px;
                line-height: 20px;
                font-family: "Mulish", Arial, Helvetica, sans-serif;
                border-radius: 5px;
                margin-left: 10px;
                font-weight: 400;
                color: #414141;
                cursor: pointer;
                outline: none;

                .closeIcon {
                    cursor: pointer;
                    margin-left: 5px;
                    color: #414141;
                    outline: none;

                    .closeCouponIcon {
                        cursor: pointer;
                        font-weight: 400;
                        color: #414141;
                        outline: none;

                        &:hover {
                            color: #FF9933;
                        }
                    }
                }
            }
        }

        .PaymentDetails {
            background: #ffffff;
            padding: 15px;
            border: 1px solid rgba(255, 255, 255, 1);
            border-radius: 10px;
            -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
            -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
            box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
            margin-bottom: 20px;
        }

        .SubmitDetails {
            .checkboxContainer {
                margin: 20px 0px;
            }

            .PayNow {
                border-radius: 27px;
                padding: 10px 30px;
            }

            .appleButton {
                display: none;
                -webkit-appearance: -apple-pay-button;
                width: 100%;
                max-width: 400px;
                height: 50px;
                cursor: pointer;
                margin: 5px 0;

                &.buy {
                    -apple-pay-button-type: buy;
                    display: block;
                }

                &.setup {
                    -apple-pay-button-type: set-up;
                    display: block;
                }
            }
        }

    }
}