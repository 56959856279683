.pageScreenContainer {
    .myPage {
        .myPageWrapper {
            &.myLibrary {
                .fileItemWrapper {
                    padding-bottom: 15px;
                    padding-top: 15px;
                }

                .loadMore {
                    padding-top: 25px;
                    padding-bottom: 25px;
                    text-align: center;
                }

                .fileItem {
                    background: #ffffff;
                    border: 1px solid rgba(220, 220, 220, 1);
                    border-radius: 10px;

                    .fileThumb {
                        height: 150px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        background: #ffffff;
                        border-bottom: 1px solid rgba(220, 220, 220, 1);
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;

                        img {
                            height: auto;
                            width: 85px;
                        }
                    }

                    .fileDesc {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        padding: 5px 10px;

                        .titleName {
                            color: #000080;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 400;
                            font-style: normal;
                            font-size: 14px;
                            line-height: 20px;
                            cursor: pointer;

                            &:hover {
                                color: #c5902e;
                            }
                        }

                        .icon {
                            cursor: pointer;
                            color: #000080;
                            margin-left: 10px;

                            .download {
                                cursor: pointer;
                                color: #000080;

                                &:hover {
                                    color: #c5902e;
                                }

                            }
                        }
                    }
                }
            }
        }
    }
}