.pageScreenContainer {
    .myPage {
        .myPageWrapper {
            &.myProfile {

                .pageTitle {
                    display: block;
                    width: 100%;
                    color: #000080;
                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                    font-weight: 500;
                    font-style: normal;
                    font-size: 30px;
                    line-height: 40px;

                    h1 {
                        display: block;
                        color: #000080;
                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                        font-weight: 500;
                        font-style: normal;
                        font-size: 30px;
                        line-height: 40px;

                        @media only screen and (max-width: 767px) {
                            font-size: 24px;
                        }

                        @media only screen and (max-width: 639px) {
                            font-size: 22px;
                        }

                        @media only screen and (max-width: 575px) {
                            font-size: 20px;
                        }
                    }
                }

                .userIntroduction {
                    text-align: center;

                    .defaultImage {
                        font-size: 14px;
                        line-height: 30px;
                        color: #000080;
                        font-weight: 500;
                        text-decoration: underline;
                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                        font-style: normal;
                        cursor: pointer;
                        &:hover {
                            color: #FF9933;
                        }
                    }

                    .greetings {
                        display: block;
                        color: #707070;
                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                        font-weight: 400;
                        font-style: normal;
                        font-size: 18px;
                        line-height: 30px;
                    }

                    .firstName {
                        display: block;
                        color: #000080;
                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                        font-weight: 500;
                        font-style: normal;
                        font-size: 30px;
                        line-height: 40px;
                    }
                }

                .userProfileImage {
                    position: relative;
                    width: 200px;
                    height: 200px;
                    margin: 20px auto 30px;

                    .profileImage {
                        border: 1px solid #efefef;
                        border-radius: 50%;
                        width: 200px;
                        height: 200px;
                        object-fit: cover;
                    }

                    .photoSelectWrapper {
                        position: absolute;
                        bottom: 15px;
                        right: 15px;
                        width: 40px;
                        height: 40px;
                        background: #FF9933;
                        border: 1px solid #FF9933;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        .photoSelect {
                            width: 20px;
                            height: 20px;
                            color: #ffffff;
                            cursor: pointer;
                        }

                        &:hover {
                            background: #FFFFFF;
                            border: 1px solid #FF9933;

                            .photoSelect {
                                color: #FF9933;
                                ;
                            }
                        }
                    }

                    .removeIconWrapper {
                        position: absolute;
                        top: 15px;
                        right: 15px;
                        width: 40px;
                        height: 40px;
                        background: #FF9933;
                        border: 1px solid #FF9933;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        .removeIcon {
                            width: 20px;
                            height: 20px;
                            color: #ffffff;
                            cursor: pointer;
                        }

                        &:hover {
                            background: #FFFFFF;
                            border: 1px solid #FF9933;

                            .removeIcon {
                                color: #FF9933;
                                ;
                            }
                        }
                    }
                }

                .formGroup {
                    padding-bottom: 15px;

                    .inputTitle {
                        display: block;
                        color: #707070;
                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                        font-weight: 500;
                        font-style: normal;
                        font-size: 18px;
                        line-height: 26px;
                        margin-bottom: 5px;
                    }

                    .inputField {
                        position: relative;
                        height: 52px;
                        width: 100%;
                        opacity: 1;
                        background-color: #FFFFFF;
                        border: 1px solid #CDCDCD;
                        border-radius: 50px 50px;
                        -webkit-border-radius: 50px 50px;
                        -moz-border-radius: 50px 50px;
                        -o-border-radius: 50px 50px;
                        width: 100%;
                        margin-top: 0px;
                        padding: 0px 20px;
                        font-size: 15px;
                        line-height: 15px;
                        color: #0d1624;
                        font-weight: 400;
                        font-family: "Mulish", Arial, Helvetica, sans-serif;

                        &.Disabled {
                            border: 1px solid#E5E5E5;
                        }

                        &::placeholder {
                            font-size: 15px;
                            line-height: 20px;
                            color: #5f5f83;
                            font-weight: 400;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                        }

                        &::-moz-placeholder {
                            /* Firefox 19+ */
                            line-height: 40px;
                        }
                    }

                    .inputSelect {
                        position: relative;
                        height: 52px;
                        width: 100%;
                        opacity: 1;
                        background-color: #FFFFFF;
                        border: 1px solid #CDCDCD;
                        border-radius: 50px 50px;
                        -webkit-border-radius: 50px 50px;
                        -moz-border-radius: 50px 50px;
                        -o-border-radius: 50px 50px;
                        width: 100%;
                        margin-top: 0px;
                        padding: 0px 20px;
                        font-size: 15px;
                        line-height: 15px;
                        color: #0d1624;
                        font-weight: 400;
                        font-family: "Mulish", Arial, Helvetica, sans-serif;

                        &.Disabled {
                            border: 1px solid#E5E5E5;
                        }

                        &::placeholder {
                            font-size: 15px;
                            line-height: 20px;
                            color: #5f5f83;
                            font-weight: 400;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                        }

                        &::-moz-placeholder {
                            /* Firefox 19+ */
                            line-height: 40px;
                        }
                    }

                    .inputDate {
                        position: relative;
                        height: 52px;
                        width: 100%;
                        opacity: 1;
                        background-color: #FFFFFF;
                        border: 1px solid #CDCDCD;
                        border-radius: 50px 50px;
                        -webkit-border-radius: 50px 50px;
                        -moz-border-radius: 50px 50px;
                        -o-border-radius: 50px 50px;
                        width: 100%;
                        margin-top: 0px;
                        padding: 0px 20px;
                        font-size: 15px;
                        line-height: 15px;
                        color: #0d1624;
                        font-weight: 400;
                        font-family: "Mulish", Arial, Helvetica, sans-serif;

                        &.Disabled {
                            border: 1px solid#E5E5E5;
                        }

                        &::placeholder {
                            font-size: 15px;
                            line-height: 20px;
                            color: #5f5f83;
                            font-weight: 400;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                        }

                        &::-moz-placeholder {
                            /* Firefox 19+ */
                            line-height: 40px;
                        }
                    }

                    .error {
                        color: red;
                    }

                    .checkBoxInline {
                        height: 52px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                    }

                    &.formSubmit {
                        padding-top: 15px;

                        @media only screen and (max-width: 575px) {
                            text-align: center;
                        }
                    }
                }

            }
        }
    }
}