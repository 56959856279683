.pageScreenContainer {
    &.gamePage {
        min-height: -o-calc(100vh - 0px);
        min-height: calc(100vh - 0px);
        padding: 30px;

        @media only screen and (max-width:576px) {
            padding: 15px;
        }

        .myPage {
            min-height: -o-calc(100vh - 60px);
            min-height: calc(100vh - 60px);
            align-items: center;
            justify-content: center;
        }
    }

    .myPage {
        .myPageWrapper {
            &.gameScreen {

                .custom-container {
                    width: 100%;
                }

                .contentRow {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-pack: justify;
                    justify-content: space-between;
                    padding: 20px 0px;
                    position: relative;

                    @media only screen and (max-width:991px) {
                        flex-direction: column;
                        padding: 30px 0px;
                    }

                    .leftCnt {
                        width: 30%;
                        /* background: #FFFFFF 0% 0% no-repeat padding-box; */
                        box-shadow: 0px 4px 8px #00000029;
                        border-radius: 20px;
                        padding: 20px;
                        position: relative;

                        @media only screen and (max-width:991px) {
                            width: 100%;
                        }

                        @media only screen and (max-width:576px) {
                            padding: 15px;
                        }

                        &.directCenter {
                            width: 100%;
                            box-shadow: unset;
                            border-radius: unset;

                            .loadingGame {
                                font-weight: 500;
                                font-size: 23px;
                                line-height: 28px;
                                color: #484848;
                            }

                            .loaderLogo {
                                max-width: 140px;
                                text-align: center;
                                padding: 20px 0px;
                            }
                        }

                        .leftCntHeader {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;

                            .counter {
                                text-align: right;

                                div {
                                    display: inline-block;
                                    text-align: right;
                                }

                                span {
                                    font-weight: bold;
                                    font-size: 23px;
                                    line-height: 28px;
                                    color: #484848;
                                    display: flex;
                                    text-align: center;
                                    border-radius: 100%;
                                    justify-content: center;
                                    align-items: center;

                                    @media only screen and (max-width:1440px) {
                                        font-size: 21px;
                                    }

                                    @media only screen and (max-width:767px) {
                                        width: 56px;
                                        height: 56px;
                                        font-size: 20px;
                                    }

                                    @media only screen and (max-width:576px) {
                                        width: 48px;
                                        height: 48px;
                                        font-size: 16px;
                                    }
                                }
                            }
                        }

                        .pointsSec {
                            width: 100%;
                            padding: 15px 0px;

                            @media only screen and (max-width:576px) {
                                padding: 5px 0px;
                            }

                            p {
                                font-weight: 500;
                                font-size: 22px;
                                line-height: 30px;
                                color: #707070;
                                text-align: center;
                                margin: 0px 0px 10px 0px;
                            }

                            .acePoint {
                                font-weight: 500;
                                font-size: 90px;
                                line-height: 100px;
                                text-align: center;
                                color: white;
                                -webkit-text-stroke-width: 3px;
                                -webkit-text-stroke-color: #000080;

                                p {
                                    font-weight: 500;
                                    font-size: 90px;
                                    line-height: 100px;
                                    text-align: center;
                                    margin: 0px;
                                    color: white;
                                    -webkit-text-stroke-width: 3px;
                                    -webkit-text-stroke-color: #000080;

                                    @media only screen and (max-width:1440px) {
                                        font-size: 100px;
                                        line-height: 110px;
                                    }

                                    @media only screen and (max-width:1199px) {
                                        font-size: 80px;
                                        line-height: 90px;
                                    }

                                    @media only screen and (max-width:576px) {
                                        font-size: 60px;
                                        line-height: 70px;
                                    }
                                }

                                @media only screen and (max-width:1440px) {
                                    font-size: 100px;
                                    line-height: 110px;
                                }

                                @media only screen and (max-width:1199px) {
                                    font-size: 80px;
                                    line-height: 90px;
                                }

                                @media only screen and (max-width:576px) {
                                    font-size: 60px;
                                    line-height: 70px;
                                }
                            }
                        }

                        .questions {
                            display: grid;
                            grid-template-columns: 65px 65px 65px 65px 65px;
                            grid-template-rows: 65px 65px 65px 65px;
                            justify-content: center;
                            align-content: center;
                            text-align: center;
                            padding-top: 0px;

                            @media only screen and (max-width:1440px) {
                                grid-template-columns: 50px 50px 50px 50px 50px;
                                grid-template-rows: 50px 50px 50px 50px;
                                padding-top: 1.5em;
                            }

                            @media only screen and (max-width:1199px) {
                                grid-template-columns: 50px 50px 50px 50px 50px;
                                grid-template-rows: 50px 50px 50px 50px;
                            }

                            @media only screen and (max-width:767px) {
                                grid-template-columns: 45px 45px 45px 45px 45px;
                                grid-template-rows: 45px 45px 45px 45px;
                            }

                            @media only screen and (max-width:576px) {
                                padding-top: 5px;
                            }

                            span {
                                height: 48px;
                                width: 48px;
                                border-radius: 100%;
                                border: 1px solid #707070;
                                background: #FFFFFF 0% 0% no-repeat padding-box;
                                color: #707070;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-weight: 500;
                                font-size: 24px;
                                line-height: 37px;
                                cursor: pointer;
                                align-self: center;
                                justify-self: center;

                                @media only screen and (max-width:1440px) {
                                    height: 36px;
                                    width: 36px;
                                    font-size: 20px;
                                    line-height: 30px;
                                }

                                @media only screen and (max-width:1199px) {
                                    height: 34px;
                                    width: 34px;
                                    font-size: 20px;
                                    line-height: 28px;
                                }

                                @media only screen and (max-width:767px) {
                                    height: 30px;
                                    width: 30px;
                                    font-size: 18px;
                                    line-height: 26px;
                                }

                                &.yellow {
                                    background-color: #FF9933;
                                    color: #FFFFFF;
                                    border: 1px solid #FF9933;
                                    background: #FF9933 0% 0% no-repeat padding-box;
                                }

                                &.blue {
                                    background-color: #000080;
                                    color: #FFFFFF;
                                    border: 1px solid #000080;
                                    background: #000080 0% 0% no-repeat padding-box;
                                }

                            }
                        }

                        .endGameButton {
                            text-decoration: none;
                            text-align: center;
                            padding: 25px 0px 10px;
                        }
                    }

                    .leftArrow {
                        position: absolute;
                        top: 50%;
                        left: 0px;
                        transform: translateY(-50%);

                        @media only screen and (max-width:1440px) {}

                        @media only screen and (max-width:991px) {
                            top: 20%;
                        }

                        @media only screen and (max-width:576px) {
                            top: 18%;
                            left: -5px;
                        }

                        .leftArrowIcon {
                            font-size: 70px;
                            color: #FF9933;
                            cursor: pointer;

                            &:hover {
                                opacity: 0.70;
                            }

                            @media only screen and (max-width:1440px) {
                                font-size: 60px;
                            }

                            @media only screen and (max-width:767px) {
                                font-size: 50px;
                            }

                            @media only screen and (max-width:576px) {
                                font-size: 30px;
                            }
                        }
                    }

                    .rightCnt {
                        width: 68%;
                        /* background: #FFFFFF 0% 0% no-repeat padding-box; */
                        box-shadow: 0px 4px 8px #00000029;
                        border-radius: 20px;
                        padding: 20px;
                        position: relative;

                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -ms-flex-pack: justify;
                        justify-content: space-between;

                        @media only screen and (max-width:991px) {
                            margin-top: 25px;
                            width: 100%;
                        }

                        @media only screen and (max-width:576px) {
                            padding: 15px;
                            flex-direction: column-reverse;
                        }

                        .quesSec {
                            width: 100%;
                            padding: 0px 30px;
                            position: relative;

                            @media only screen and (max-width:576px) {
                                padding: 0px 10px;
                            }

                            .saveQuestion {
                                position: absolute;
                                top: 0px;
                                left: 0px;
                                right: 0px;
                                bottom: 0px;
                                width: 100%;
                                height: 100%;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                text-align: center;

                                .saveQuestionStatus {
                                    .saveQuestionStatusIcon {
                                        display: block;
                                        margin-bottom: 5px;
                                        text-align: center;
                                        width: 100%;
                                        color: #000080;
                                    }

                                    text-align: center;
                                    border-radius: 10px;
                                    padding: 15px 20px;
                                    font-family: "Mulish",
                                    Arial,
                                    Helvetica,
                                    sans-serif;
                                    color: #000080;
                                    background: #FFFFFF;
                                    -webkit-box-shadow: 0px 0px 8px 1px rgba(170, 170, 170, 0.7);
                                    -moz-box-shadow: 0px 0px 8px 1px rgba(170, 170, 170, 0.7);
                                    box-shadow: 0px 0px 8px 1px rgba(170, 170, 170, 0.7);
                                    -webkit-transition: 0.5s ease-in-out;
                                    -moz-transition: 0.5s ease-in-out;
                                    -o-transition: 0.5s ease-in-out;
                                    transition: 0.5s ease-in-out;
                                }
                            }

                            .questionDescription {
                                padding: 0px 0px 0px;
                                margin-bottom: 20px;
                                font-size: 22px;
                                line-height: 32px;
                                font-weight: normal;
                                color: #101010;
                                text-align: left;

                                p {
                                    font-weight: normal;
                                    font-size: 22px;
                                    line-height: 32px;
                                    color: #101010;
                                    text-align: left;
                                    margin: 0px;

                                    @media only screen and (max-width:576px) {
                                        font-size: 16px;
                                        line-height: 26px;
                                    }
                                }

                                @media only screen and (max-width:1440px) {
                                    padding: 0px 15px 0px;
                                    font-size: 20px;
                                    line-height: 30px;
                                }

                                @media only screen and (max-width:1199px) {
                                    font-size: 20px;
                                }

                                @media only screen and (max-width:767px) {
                                    font-size: 18px;
                                }

                                @media only screen and (max-width:576px) {
                                    font-size: 14px;
                                    padding: 0px 0px 0px;
                                }
                            }

                            .questionName {
                                font-weight: normal;
                                font-size: 22px;
                                line-height: 32px;
                                color: #000080;
                                text-align: left;
                                margin-bottom: 40px;

                                img {
                                    width: auto;
                                    max-width: 100%;
                                    height: auto;
                                    text-align: left;
                                    display: block;
                                }

                                p {
                                    font-weight: normal;
                                    font-size: 22px;
                                    line-height: 32px;
                                    color: #000080;
                                    text-align: left;
                                    margin: 0px;

                                    @media only screen and (max-width:576px) {
                                        font-size: 16px;
                                        line-height: 26px;
                                    }
                                }

                                @media only screen and (max-width:1440px) {
                                    font-size: 20px;
                                    line-height: 30px;
                                }

                                @media only screen and (max-width:767px) {
                                    font-size: 20px;
                                    line-height: 30px;
                                    margin-bottom: 35px;
                                }

                                @media only screen and (max-width:576px) {
                                    font-size: 16px;
                                    line-height: 26px;
                                    margin-bottom: 20px;
                                    margin-top: 15px;
                                }
                            }

                            .options {
                                background: #FFFFFF 0% 0% no-repeat padding-box;
                                box-shadow: 0px 2px 4px #00000029;
                                border-radius: 16px;
                                width: 98%;
                                margin: 0 auto 15px;
                                padding: 13px 15px;
                                display: flex;
                                align-items: center;
                                cursor: pointer;

                                @media only screen and (max-width:767px) {
                                    width: 90%;
                                }

                                @media only screen and (max-width:576px) {
                                    width: 100%;
                                    margin: 0 auto 20px;
                                }

                                .letter {
                                    width: 40px;
                                    height: 40px;
                                    background: #FFFFFF 0% 0% no-repeat padding-box;
                                    border-radius: 100%;
                                    color: #000000;
                                    text-align: center;
                                    font-size: 30px;
                                    line-height: 40px;

                                    @media only screen and (max-width:1440px) {
                                        width: 36px;
                                        height: 36px;
                                        font-size: 24px;
                                        line-height: 35px;
                                    }

                                    @media only screen and (max-width:576px) {
                                        width: 30px;
                                        height: 30px;
                                        font-size: 20px;
                                        line-height: 30px;
                                    }
                                }

                                .text {
                                    width: 87%;
                                    font-size: 18px;
                                    line-height: 24px;
                                    color: #000000;
                                    padding-left: 15px;

                                    @media only screen and (max-width:1440px) {
                                        font-size: 16px;
                                        line-height: 22px;
                                    }

                                    @media only screen and (max-width:1199px) {
                                        font-size: 17px;
                                        line-height: 21px;
                                    }

                                    @media only screen and (max-width:576px) {
                                        font-size: 16px;
                                        line-height: 26px;
                                    }

                                    p {
                                        font-size: 18px;
                                        line-height: 24px;
                                        color: #000000;
                                        margin: 0px;

                                        @media only screen and (max-width:1440px) {
                                            font-size: 16px;
                                            line-height: 22px;
                                        }
    
                                        @media only screen and (max-width:1199px) {
                                            font-size: 17px;
                                            line-height: 21px;
                                        }

                                        @media only screen and (max-width:576px) {
                                            font-size: 16px;
                                            line-height: 26px;
                                        }
                                    }
                                }

                                &.green {
                                    background: #138808 0% 0% no-repeat padding-box;

                                    .letter {
                                        color: #4EA545;
                                    }

                                    .text {
                                        color: #FFFFFF;

                                        p {
                                            color: #FFFFFF;
                                        }
                                    }
                                }


                            }
                        }



                    }

                    .rightArrow {
                        position: absolute;
                        top: 50%;
                        right: 0px;
                        transform: translateY(-50%);

                        @media only screen and (max-width:991px) {
                            top: 20%;
                        }

                        @media only screen and (max-width:576px) {
                            top: 18%;
                            right: -5px;
                        }

                        .rightArrowIcon {
                            font-size: 70px;
                            color: #FF9933;
                            cursor: pointer;

                            &:hover {
                                opacity: 0.70;
                            }

                            @media only screen and (max-width:1440px) {
                                font-size: 60px;
                            }

                            @media only screen and (max-width:767px) {
                                font-size: 50px;
                            }

                            @media only screen and (max-width:576px) {
                                font-size: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
}