.WithoutAuthFooter {
    position: relative;
    padding: 278px 0px 0px;
    background-color: transparent;
    font-family: 'Mulish', Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 23px;
    width: 100%;

    @media screen and (max-width: 767px) {
        margin-top: 90px;
    }

    @media screen and (max-width:639px) {
        padding-bottom: 50px;
    }

    @media screen and (max-width:575px) {
        padding: 139px 0px 50px;
    }

    &::before {
        width: 100%;
        content: ' ';
        height: 100%;
        background-color: #0A2540;
        clip-path: polygon(0 50%, 100% 0, 100% 100%, 0 100%);
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 0;


        @media screen and (max-width:1199px) {
            clip-path: polygon(0 25%, 100% 0, 100% 100%, 0 100%);
        }

        @media screen and (max-width:991px) {
            clip-path: polygon(0 25%, 100% 0, 100% 100%, 0 100%);
        }

        @media screen and (max-width:767px) {
            clip-path: polygon(0 25%, 100% 0, 100% 100%, 0 100%);
        }

        @media screen and (max-width:575px) {
            clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 100%);
        }
    }

    .container {
        max-width: 1640px;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;

        @media screen and (max-width:1699px) {
            max-width: 1400px;
        }

        @media screen and (max-width: 1199px) {
            max-width: 920px;
        }

        @media screen and (max-width: 991px) {
            max-width: 720px;
        }

        @media screen and (max-width:767px) {
            max-width: 540px;
            padding-right: 10px;
            padding-left: 10px;
        }        
    }

    .flex-footer {
        position: relative;

        .footer-mob-img {
            position: absolute;
            top: -285px;
            left: -71px;
            display: inline-block;
            width: 540px;
            cursor: pointer;
            
            @media screen and (max-width:1199px) {
                margin-left: 280px;
            }

            @media screen and (max-width:991px) {
                margin-left: 175px;
            }
          
            @media screen and (max-width:767px) {
                margin-left: 100px;
            }

            @media screen and (max-width:575px) {
                max-width: 100%;
                top: -195px;
                position: static;
                text-align: center;
                margin-left: 0px;
                width: 100%;
            }

            img {
                width: 235px;
                margin-top: 15px;
                margin-left: 145px;
                cursor: pointer;

                
                @media screen and (max-width:1199px) {
                    width: 183px;
                    margin-top: -40px;
                }

                @media screen and (max-width:991px) {
                    width: 180px;
                    margin-top: -40px;
                }

                @media screen and (max-width:767px) {
                    width: 165px;
                    margin-top: -63px;
                }

                @media screen and (max-width:575px) {
                    width: 150px;
                    margin-left: auto;
                    padding-right: 0px;
                    margin-top: -211px;
                    margin-right: auto;
                }
            }
        }
        
        .footer-inner {
            display: flex;
            justify-content: flex-end;

            @media screen and (max-width:575px) {
                display: block;
                text-align: center;
                width: 100%;
                padding: 0px;
            }

            .foot-menu {
                margin-left: 71px;
                
                @media screen and (max-width:767px) {
                    margin-left: 28px;
                }

                @media screen and (max-width:575px) {
                    margin-left: 0;
                }
            }
            
            .getMoreDetails {
                margin-left: 70px;
                
                @media screen and (max-width:767px) {
                    margin-left: 28px;
                }

                @media screen and (max-width:575px) {
                    margin-left: 0px;
                }
            }

            .footer-col {
                h4 {
                    font-size: 21px;
                    line-height: 26px;
                    color: #FF9933;
                    margin-bottom: 30px;
                    font-weight: 600;
                    font-family: 'Mulish', Arial, Helvetica, sans-serif;

                    @media screen and (max-width:767px) {
                        font-size: 18px;
                        margin-bottom: 10px;
                    }
                }
                ul {
                    list-style: none;
                    list-style-type: none;
                    padding: 0px;

                    li {
                        padding-top: 15px;

                        @media screen and (max-width:767px) {
                            padding-top: 10px;
                        }
                        span {
                            font-size: 18px;
                            color: #FFFFFF;
                            line-height: 23px;
                            font-family: 'Mulish', Arial, Helvetica, sans-serif;
                            cursor: pointer;

                            @media screen and (max-width:767px) {
                                font-size: 16px;
                                line-height: 23px;
                            }
                        }
                    }
                }
            }

            .courses-menu {
                .footer-col {
                    ul {
                        list-style: none;
                        list-style-type: none;
                        padding: 0px;

                        li {
                            span {
                                font-size: 21px;
                                line-height: 26px;
                                font-family: 'Mulish', Arial, Helvetica, sans-serif;
                                cursor: pointer;

                                @media screen and (max-width:767px) {
                                    font-size: 18px;
                                }
                                
                            }
                        }
                    }
                }
            }

        }
    }
    
    .footer-col {
        position: relative;

        .playStore {
            display: inline-block;
            position: absolute;
            top: -21px;
            right: -77px;

            @media screen and (max-width:575px) {
                top: -58px;
                right: 7px;
                /*
                top: -110px;
                right: -12px;
                */
            }

            img {
                width: 160px;
                height: auto;

                @media screen and (max-width:575px) {
                    width: 88px;
                }
            }
        }
    }

    .sendWelcomeEmail {
        display: flex;
        width: 273px;

        @media screen and (max-width:767px) {
            width: 230px;
        }

        @media screen and (max-width:575px) {
            margin: 0px auto;
            display: flex;
            width: 75%;
            max-width: 100%;
        }

        .subscribe-email {
            width: 60%;
            background: #FFFFFF;
            border-radius: 10px 0 0 10px;
            border: 0;
            font-size: 12px;
            line-height: 13px;
            color: #141414;
            font-weight: normal;
            text-align: left;
            padding: 0px 10px;
        }

        .submit {
            width: 40%;
            background: #FF9933;
            border-radius: 0px 10px 10px 0px;
            color: #fff;
            border: 0;
            line-height: 25px;
            font-size: 18px;
            vertical-align: middle;
            text-align: center;
            padding: 4px 0px;
            cursor: pointer;
        }
    }

    .footerSocialIconWrapper {
        margin-top: 20px;

        @media screen and (max-width:575px) {
            width: 100%;
            max-width: 320px;
            margin: 0px auto;
        }

        ul {
            display: flex;
            justify-content: space-between;
            padding: 0px;
            list-style: none;
            list-style-type: none;

            @media screen and (max-width:575px) {
                margin-top: 10px;
            }

            li {
                padding-top: 0;
                display: inline-block;
                margin: 0 12px;
                span {
                    .fa {
                        color: #FF9933;
                        font-size: 32px;
                        cursor: pointer;

                        @media screen and (max-width:767px) {
                            font-size: 25px;
                        }
                    }
                }
            }
        }
    }

    .footer-sub-copyright {
        font-family: 'Mulish', Arial, Helvetica, sans-serif;
        text-align: left;
        font-size: 10px;
        color: #FFFFFF;
        line-height: 20px;
        margin-top: 20px;
        padding-bottom: 10px;
        width: 250px;

        @media only screen and (max-width:767px){
            width: 230px;
        }

        @media only screen and (max-width:575px) {
            margin-top: 10px;
            width: 100%;
            max-width: 100%;
            text-align: center;
            padding: 0px;
        }

        p {
            font-family: 'Mulish', Arial, Helvetica, sans-serif;
            text-align: left;
            font-size: 10px;
            color: #FFFFFF;
            line-height: 20px;
            margin: 0px;
            padding: 0px;

            @media only screen and (max-width:575px) {
                text-align: center;
            }
        }

        span {
            color: #FF9933;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .footer-copyright {
        font-family: 'Mulish', Arial, Helvetica, sans-serif;
        text-align: center;
        font-size: 15px;
        color: #FFFFFF;
        line-height: 19px;
        padding-top: 40px;
        padding-bottom: 20px;

        @media screen and (max-width:767px) {
            padding-top: 0px;
            margin-top: 20px;
        }

        @media screen and (max-width:575px) {
            padding-top: 10px;
            margin-top: 0px;
            padding-bottom: 10px;
        }

        p {
            font-family: 'Mulish', Arial, Helvetica, sans-serif;
            text-align: center;
            font-size: 15px;
            color: #FFFFFF;
            line-height: 19px;
            margin: 0px;
            padding: 0px;
        }
    }
}

@media screen and (min-width:1700px) {
    /*  footer section start here */
    .getMoreDetails {
        margin-left: 116px;
    }
    .foot-menu {
        margin-left: 116px;
    }
    .footer-col .playstore {
        display: inline-block;
        position: absolute;
        top: -21px;
        right: -77px;
    }
    .download-area {
        padding-bottom: 37px;
    }
    
     .footer-mob-img {
        left: -24px;

    }
    .footer-mob-img img {
        width: 275px;
        margin-top: -35px;
        margin-left: 145px;
    }
    /**
    /*  footer section ends here */
}

@media screen and (max-width: 1199px) {
    /* Start footer css */
    .footer-inner {
        padding: 70px 0 20px;
    }
    .footer-col img {
        padding-right: 20px;
        margin-top: -210px;
    }
    .footerSocialIconWrapper ul li {
        margin: 0 7px;
    }
    footer.footer-area::before {
        clip-path: polygon(0 25%, 100% 0, 100% 100%, 0 100%);
    }
    .footer-mob-img {
        top: -240px;
        left: 25%;
        width: 480px;
    }
    .footer-col .playstore {
        top: -95px;
        right: -75px;
    }
    .footer-inner {
        padding: 54px 0 20px;
        margin-right: 105px;
    }
    .download-area {
        padding-bottom: 145px;
    }
    /* End footer css */
}

@media screen and (max-width: 991px) {
    /* start footer css */
    .footer-inner .footer-col ul li a {
        font-size: 16px;
    }
    .footer-inner .footer-col h4, .footer-inner .courses-menu .footer-col ul li a {
        font-size: 18px;
    }
    .footer-mob-img {
        left: 12%;
    }
    .footer-col .playstore {
        top: -32px;
        right: -84px;
    }
    .footer-inner {
        margin-right: 0px;
    }
    .footer-copyright {
        margin-top: 25px;
    }

    .footerSocialIconWrapper {
        margin-top: 20px;
    }
    /* End footer css */

}

@media screen and (max-width: 767px) {
    .footer-col {
        margin-bottom: 30px;
    }
    .footer-inner .footer-col h4 {
        margin-bottom: 10px;
    }
    .footer-inner .footer-col ul li {
        padding-top: 10px;
    }
    .footer-inner {
        padding: 40px 0 20px;
    }
    .footerSocialIconWrapper {
        margin-top: 20px;
    }
    .footer-inner {
        padding: 0px 0 20px;
    }
    .footer-col .playstore {
        top: -95px;
        right: -59px;
    }
    .footer-col .playstore img {
        width: 134px;
    }
    .getMoreDetails {
        margin-left: 28px;
    }
    .foot-menu {
        margin-left: 28px;
    }
    .footer-mob-img {
        max-width: 379px;
        top: -195px;
    }
    .footer-col form {
        display: flex;
        width: 211px;
    }
    .download-area {
        padding-bottom: 45px;
    }
    .footerSocialIconWrapper ul li a i {
        font-size: 25px;
    }
    .footerSocialIconWrapper {
        margin-top: 14px;
    }
    
    .footer-copyright{
        font-size: 13px;
    }
    /* End footer css */
}

/*
.footerMenuContainer {
    background-color: #eff6f9;
    padding: 60px 0 176px;

    @media only screen and (max-width:1440px) {
        padding: 50px 0 100px;
    }
}

.footerMenuContainer .footer-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%
}

.footerMenuContainer .footer-row .footer-column {
    display: inline-block;
    width: 25%
}

@media only screen and (max-width:1330px) {

    .footerMenuContainer .footer-row .footer-column:first-child,
    .footerMenuContainer .footer-row .footer-column:last-child {
        width: 30%
    }

    .footerMenuContainer .footer-row .footer-column:nth-child(2),
    .footerMenuContainer .footer-row .footer-column:nth-child(3) {
        width: 20%
    }
}

.footerMenuContainer .footer-row .footer-column .footer-logo {
    display: inline-block;
    margin-bottom: 30px;
    cursor: pointer;
    max-width: 200px;

    @media only screen and (max-width:1600px) {
        max-width: 160px;
    }

    @media only screen and (max-width:1440px) {
        margin-bottom: 24px;
        max-width: 108px;
    }
}

.footerMenuContainer .footer-row .footer-column p {
    width: 336px;
    font-family: Mulish, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    color: #6d6d6d;
    text-align: left;

    @media only screen and (max-width:1600px) {
        width: 320px
    }

    @media only screen and (max-width:1440px) {
        width: 90%;
        font-size: 16px;
        line-height: 22px
    }

    @media only screen and (max-width:576px) {
        width: 100%;
        text-align: center;
    }
}

.footerMenuContainer .footer-row .footer-column h4 {
    font-family: Mulish, sans-serif;
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    color: navy;
    margin-bottom: 50px
}

@media only screen and (max-width:1440px) {
    .footerMenuContainer .footer-row .footer-column h4 {
        font-size: 20px;
        margin-bottom: 40px
    }
}

.footerMenuContainer .footer-row .footer-column .subscription-form {
    display: inline-block;
    margin-bottom: 50px
}

@media only screen and (max-width:1440px) {
    .footerMenuContainer .footer-row .footer-column .subscription-form {
        margin-bottom: 40px
    }
}

.footerMenuContainer .footer-row .footer-column .subscription-form input.subscribe-email {
    display: inline-block;
    width: 275px;
    background-color: #fff;
    font-family: Mulish, sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 27px;
    color: #b2b2b2;
    border: 1px solid #7d7d7d;
    border-radius: 20px 0 0 20px;
    padding: 18px 20px;
    vertical-align: top;

    @media only screen and (max-width:1600px) {
        font-size: 18px;
        width: 200px;
    }

    @media only screen and (max-width:1440px) {
        font-size: 16px;
        border-radius: 10px 0 0 10px;
        padding: 12px 16px;
    }

    @media only screen and (max-width:1199px) {
        width: 200px;
    }
}

.footerMenuContainer .footer-row .footer-column .subscription-form button.subscribe-btn {
    display: inline-block;
    width: 110px;
    text-align: center;
    background-color: #f93;
    font-family: Mulish, sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 37px;
    color: #fff;
    border: 1px solid #f93;
    border-radius: 0 20px 20px 0;
    padding: 13px 0;
    margin-left: -6px;
    vertical-align: top;

    @media only screen and (max-width:1600px) {
        width: 90px;
        font-size: 26px;
    }

    @media only screen and (max-width:1440px) {
        width: 64px;
        font-size: 24px;
        border-radius: 0 10px 10px 0;
        padding: 7px 0;
    }
}

.footerMenuContainer .footer-row .footer-column ul li:not(:last-child) {
    margin-bottom: 35px
}

@media only screen and (max-width:1440px) {
    .footerMenuContainer .footer-row .footer-column ul li:not(:last-child) {
        margin-bottom: 25px
    }
}

@media only screen and (max-width:576px) {
    .footerMenuContainer .footer-row .footer-column ul li,
    .footerMenuContainer .footer-row .footer-column ul li:not(:last-child) {
        margin-bottom: 10px
    }
}

.footerMenuContainer .footer-row .footer-column ul li span {
    font-family: Mulish, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    color: #6d6d6d;
    cursor: pointer;

    @media only screen and (max-width:1440px) {
        font-size: 16px;
        line-height: 22px;
    }

    &:hover {
        text-decoration: none;
        color: navy
    }
}

.footerMenuContainer .footer-row .footer-column .social-links {
    display: inline-block;
    margin: 0px;
    padding: 0px;

    li {
        display: inline-block;
        margin-bottom: 0 !important;

        span {
            display: inline-block;

            .fa {
                font-size: 30px;
                color: navy;
                cursor: pointer;

                &:hover {
                    color: #f93
                }

                @media only screen and (max-width:1440px) {
                    font-size: 26px
                }
            }
        }
    }
}



.footerMenuContainer .footer-row .footer-column .social-links li:not(:last-child) {
    margin-right: 25px;

    @media only screen and (max-width:1440px) {
        margin-right: 25px
    }

    @media only screen and (max-width:1199px) {
        margin-right: 32px;
    }
}


.footerCopyright {
    background-color: #04045c;
    padding: 32px 0;

    @media only screen and (max-width:1440px) {
        padding: 20px 0;
    }
}



.footerCopyright p {
    font-family: Mulish, sans-serif;
    font-weight: 400;
    font-size: 30px;
    color: #fff;
    line-height: 36px;
    margin-bottom: 0px;

    @media only screen and (max-width:1440px) {
        font-size: 20px;
    }
}

.footerMenuContainer .footer-row {
    width: 100%;
    margin: 0;
}

@media only screen and (max-width:1199px) {

    .footerMenuContainer .footer-row .footer-column .social-links li:not(:last-child) {
        margin-right: 32px;
    }

}

@media only screen and (max-width:991px) {

    .footerMenuContainer .footer-row .footer-column:first-child,
    .footerMenuContainer .footer-row .footer-column:last-child {
        width: 50%;
    }

    .footerMenuContainer .footer-row .footer-column:nth-child(2),
    .footerMenuContainer .footer-row .footer-column:nth-child(3) {
        width: 50%;
    }

    .footerMenuContainer .footer-row .footer-column:first-child,
    .footerMenuContainer .footer-row .footer-column:nth-child(2) {
        margin-bottom: 20px;
    }
}

@media only screen and (max-width:576px) {

    .footerMenuContainer .footer-row .footer-column:first-child,
    .footerMenuContainer .footer-row .footer-column:last-child {
        width: 100%;
    }

    .footerMenuContainer .footer-row .footer-column:nth-child(2),
    .footerMenuContainer .footer-row .footer-column:nth-child(3) {
        width: 100%;
    }

    .footerMenuContainer .footer-row .footer-column .subscription-form {
        display: block;
        margin-bottom: 20px;
    }

    .footerCopyright p {
        font-size: 16px;
    }

    .footerMenuContainer .footer-row .footer-column .subscription-form form p {
        position: relative;
    }

    .footerMenuContainer .footer-row .footer-column .subscription-form form .ajax-loader {
        position: absolute;
    }
}
*/