.pointsView {
    .pointBoxWrapper {
        padding-bottom: 25px;

        &.noPadding {
            padding-bottom: 0px;
        }

        .pointBox {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            min-height: 160px;
            -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
            -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);

            &.White {
                background-color: #FFFFFF;
            }

            &.LightBlue {
                background-color: #C2E4EF;
            }

            &.LightOrange {
                background-color: #EAD0B3;
            }

            &.LightGreen {
                background-color: #A7BBA8;
            }

            &.LightYellow {
                background-color: #FFF59D;
            }

            &.LightBrown {
                background-color: #B37F6F;
            }

            h3 {
                display: block;
                margin: 0px;
                font-family: "Mulish", Arial, Helvetica, sans-serif;
                font-weight: 700;
                font-style: normal;
                font-size: 40px;
                line-height: 54px;

                @media only screen and (max-width: 1600px) {
                    font-size: 32px;
                    line-height: 40px;
                }

                @media only screen and (max-width: 1199px) {
                    font-size: 24px;
                    line-height: 40px;
                }
            }

            .pbInfo {
                display: block;
                margin: 0px;
                font-family: "Mulish", Arial, Helvetica, sans-serif;
                font-weight: 500;
                font-style: normal;
                font-size: 26px;
                line-height: 36px;
                text-align: center;

                @media only screen and (max-width: 1600px) {
                    font-size: 18px;
                    line-height: 30px;
                }

                @media only screen and (max-width: 1199px) {
                    font-size: 16px;
                    line-height: 30px;
                }

                @media only screen and (max-width: 991px) {
                    font-size: 14px;
                    line-height: 24px;
                }
            }

            .pbSubInfo {
                display: block;
                margin: 0px;
                font-family: "Mulish", Arial, Helvetica, sans-serif;
                font-weight: 400;
                font-style: normal;
                font-size: 16px;
                line-height: 20px;
                position: absolute;
                left: 10px;
                bottom: 5px;

                @media only screen and (max-width: 1600px) {
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }
    }

    .accuracyChartView {
        -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        padding: 10px 15px;
        position: relative;

        .chartLayOver {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 15px 25px;
            border-radius: 10px;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #FFFFFF;
                opacity: 0.95;
                z-index: 1;
                border-radius: 10px;
            }

            h3 {
                z-index: 2;
                color: #000080;
                margin: 0px;
                font-family: "Mulish", Arial, Helvetica, sans-serif;
                font-weight: 400;
                font-style: normal;
                font-size: 26px;
                line-height: 46px;
            }

            p {
                z-index: 2;
                color: #000080;
                margin: 0px;
                font-family: "Mulish", Arial, Helvetica, sans-serif;
                font-weight: 400;
                font-style: normal;
                font-size: 18px;
                line-height: 26px;
                text-align: center;
            }
        }

        .chartTitle {
            color: #000080;
            margin: 0px;
            font-family: "Mulish", Arial, Helvetica, sans-serif;
            font-weight: 500;
            font-style: normal;
            font-size: 26px;
            line-height: 34px;
        }
    }
}

.categoryView {
    margin-top: 30px;

    .categoryItemWrapper {
        @media only screen and (max-width: 576px) {
            padding-bottom: 15px;
        }

        .categoryItemView {
            -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
            -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
            padding: 10px;
            border-radius: 10px;
            color: #FFFFFF;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            cursor: pointer;

            .categoryChartView {
                text-align: center;
                display: flex;
                justify-content: center;
            }

            .categoryTitleView {
                text-align: center;
                color: #000000;
                margin: 0px;
                font-family: "Mulish", Arial, Helvetica, sans-serif;
                font-weight: 500;
                font-style: normal;
                font-size: 22px;
                line-height: 34px;
                cursor: pointer;

                span {
                    text-align: center;
                    color: #000000;
                    margin: 0px;
                    font-size: 22px;
                    line-height: 34px;
                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                    font-weight: 500;
                    font-style: normal;
                    cursor: pointer;

                    @media only screen and (max-width: 1199px) {
                        font-size: 18px;
                        line-height: 34px;
                    }
                }
            }

            .categoryLinksView {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                padding: 25px 0px 10px;

                .categoryLinkItem {
                    cursor: pointer;

                    img {
                        height: 35px;

                        @media only screen and (max-width: 1199px) {
                            height: 25px;
                        }
                    }
                }
            }
        }
    }
}

.userLevelView {
    margin-top: 30px;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 15px;

    .userLevelHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 0px;

        @media only screen and (max-width: 576px) {
            flex-direction: column;
        }

        .ulLogo {
            display: flex;
            align-items: center;
            justify-content: center;

            @media only screen and (max-width: 576px) {
                padding-bottom: 5px;
            }

            .ulLogoImage {
                max-width: 35px;
                max-height: 34px;
            }

            .ulLogoTitle {
                margin-left: 10px;
                color: #000080;
                font-size: 22px;
                line-height: 34px;
                font-family: "Mulish", Arial, Helvetica, sans-serif;
                font-weight: 500;
                font-style: normal;
            }
        }

        .ulInfo {
            @media only screen and (max-width: 576px) {
                padding-bottom: 5px;
                text-align: center;
            }

            span {
                color: #000000;
                margin: 0px;
                font-size: 18px;
                line-height: 34px;
                font-family: "Mulish", Arial, Helvetica, sans-serif;
                font-weight: 500;
                @media only screen and (max-width: 576px) {
                    text-align: center;
                }
            }
        }

        .ulPlay {
            @media only screen and (max-width: 576px) {
                padding-bottom: 5px;
            }

            .ulPlayNow {
                background-color: #FF9933;
                color: #FFFFFF;
                font-size: 22px;
                line-height: 50px;
                font-family: "Mulish", Arial, Helvetica, sans-serif;
                font-weight: 500;
                display: block;
                padding: 0px 25px;
                border: 1px solid #FF9933;
                border-radius: 25px;
                cursor: pointer;
                opacity: 1;

                @media only screen and (max-width: 1199px) {
                    font-size: 18px;
                    line-height: 40px;
                }

                &:hover {
                    opacity: 0.95;
                }
            }
        }
    }

    .userLevelContent {
        padding: 15px 0px;

        .userLevelWrapper {
            @media only screen and (max-width: 1199px) {
                padding-bottom: 20px;
            }

            .userLevelBox {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                min-height: 200px;
                -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);

                &.Beginner {
                    background-color: #C3BB9B;
                }

                &.Basic {
                    background-color: #62A6BF;
                }

                &.Intermediate {
                    background-color: #E9B851;
                }

                &.Advance {
                    background-color: #E98D2B;
                }

                &.Expert {
                    background-color: #E5493A;
                }

                &.GrandMaster {
                    background-color: #B23227;
                }

                .ulcTitle {
                    color: #FFFFFF;
                    font-size: 22px;
                    line-height: 30px;
                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                    font-weight: 500;
                    margin-bottom: 0px;

                    @media only screen and (max-width: 1199px) {
                        font-size: 18px;
                        line-height: 30px;
                    }
                }

                .ulcLogo {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #FFFFFF;
                    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                    -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                    width: 50px;
                    height: 50px;
                    margin: 10px 0px;
                    border-radius: 10px;

                    .ulcLogoImage {
                        max-height: 30px;
                    }
                }

                .ulcPending {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #FFFFFF;
                    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                    -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                    width: 100%;
                    height: 75px;
                    margin: 10px 0px;
                    border-radius: 0px;
                    text-align: center;
                    padding: 0px 5px;

                    .ulcPendingDesc {
                        color: #000000;
                        text-align: center;
                        font-size: 14px;
                        line-height: 18px;
                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                        font-weight: 400;

                        @media only screen and (max-width: 1199px) {
                            font-size: 12px;
                            line-height: 18px;
                        }
                    }
                }

                .ulcInfo {
                    color: #FFFFFF;
                    font-size: 14px;
                    line-height: 24px;
                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                    font-weight: 400;
                }
            }
        }
    }

    .userLevelNote {
        .userLevelNoteText {
            font-size: 12px;
            line-height: 22px;
            font-family: "Mulish", Arial, Helvetica, sans-serif;
            font-weight: 400;

            .userLevelNoteLink {
                cursor: pointer;
                font-weight: 500;
                font-size: 14px;

                &:hover {
                    text-decoration: underline;
                    color: #FF9933;
                }
            }
        }
    }
}