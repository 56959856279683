.notification-area {
    background-color: #EFF6F9;
    box-shadow: 0px 3px 6px #00000029;
    padding: 10px 76px 74px;
    margin-top: 0px;

    @media screen and (max-width: 1699px) {
        background-color: #EFF6F9;
        box-shadow: 0px 3px 6px #00000029;
        padding: 10px 40px 50px;
        margin-top: 0px;
    }

    @media screen and (max-width: 1199px) {
        padding: 10px 55px 74px;
        margin-top: 0px;
    }

    @media screen and (max-width: 991px) {
        padding: 10px 40px 74px;
    }

    @media screen and (max-width: 767px) {
        margin-top: 0px;
    }

    @media screen and (max-width: 575px) {
        padding: 10px 20px 40px;
        margin-top: 0px;
    }

    h5 {
        text-align: right;
        font-size: 20px;
        line-height: 24px;
        font-weight: bold;
        font-family: "Mulish", Arial, Helvetica, sans-serif;
        margin: 0px;

        @media screen and (max-width: 767px) {
            font-size: 18px;
            line-height: 22px;
        }
    }

    &:not(.hide)[data-slide='5'],
    &:not(.hide)[data-slide='4'],
    &:not(.hide)[data-slide='3'],
    &:not(.hide)[data-slide='2'],
    &:not(.hide)[data-slide='1'] {
        color: #FFFFFF;
        h5 {
            color: #FFFFFF;
        }
    }

    .flex-notification {
        margin-top: 45px;
        display: flex;
        justify-content: space-around;

        @media screen and (max-width: 1199px) {
            justify-content: space-between;
        }

        @media screen and (max-width: 991px) {
            flex-wrap: wrap;
        }

        @media screen and (max-width: 767px) {
            flex-wrap: wrap;
            display: block;
            margin-top: 30px;
        }

        .notificationItem {
            box-shadow: 0px 30px 60px #00000029;
            border-radius: 8px;
            background-color: #ffffff;
            width: 353px;
            padding-bottom: 20px;

            @media screen and (max-width: 1699px) {
                box-shadow: 0px 30px 60px #00000029;
                border-radius: 8px;
                background-color: #ffffff;
                width: 320px;
            }

            @media screen and (max-width: 1199px) {
                width: 32%;
            }

            @media screen and (max-width: 991px) {
                width: 48%;
            }

            @media screen and (max-width: 767px) {
                width: 100%;
            }

            h4 {
                font-size: 22px;
                line-height: 30px;
                font-weight: bold;
                color: #FF9933;
                font-family: "Mulish", Arial, Helvetica, sans-serif;
                padding: 20px 25px;

                @media screen and (max-width: 767px) {
                    font-size: 18px;
                    line-height: 24px;
                }
            }

            h3 {
                font-size: 32px;
                line-height: 40px;
                font-weight: bold;
                color: #000080;
                margin-bottom: 17px;
                min-height: 40px;
                font-family: "Mulish", Arial, Helvetica, sans-serif;

                @media screen and (max-width: 767px) {
                    font-size: 24px;
                    line-height: 30px;
                }
            }

            &:last-child {

                @media screen and (max-width: 991px) {
                    margin: 0px auto;
                    margin-top: 20px;
                }

                @media screen and (max-width: 767px) {
                    margin-top: 0px;
                }

                h4 {
                    color: #138808;
                }
            }

            &:not(:first-child) {
                @media screen and (max-width: 767px) {
                    margin-top: 20px;
                }
            }

            .notification-content {
                overflow: auto;
                height: 220px;
                max-height: 260px;
                padding: 0 25px;

                @media screen and (max-width: 767px) {
                    overflow: auto;
                    height: auto;
                    max-height: 260px;
                }

                p {
                    color: #1F1F1F;
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 20px;
                    line-height: 20px;
                    font-family: "Mulish", Arial, Helvetica, sans-serif;

                    @media screen and (max-width: 767px) {
                        font-size: 13px;
                        line-height: 17px;
                        margin-bottom: 13px;
                        line-height: normal;
                    }

                    &:last-child {
                        margin-bottom: 0px;
                    }

                    span {
                        display: block;
                        font-style: italic;
                        font-size: 14px;
                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                    }
                }
            }
        }
    }
}