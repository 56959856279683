.chatPage {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: -moz-calc(100vh - 130px);
    min-height: -webkit-calc(100vh - 130px);
    min-height: -o-calc(100vh - 130px);
    min-height: calc(100vh - 130px);
    height: auto;

    .pageTitle {
        display: block;
        width: 100%;
        color: #000080;
        font-family: "Mulish", Arial, Helvetica, sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 30px;
        line-height: 40px;
        padding: 20px;
        text-align: center;

        h1 {
            display: block;
            color: #000080;
            font-family: "Mulish", Arial, Helvetica, sans-serif;
            font-weight: 500;
            font-style: normal;
            font-size: 30px;
            line-height: 40px;
            margin-bottom: 0px;
            text-align: center;

            @media only screen and (max-width: 767px) {
                font-size: 24px;
            }

            @media only screen and (max-width: 639px) {
                font-size: 22px;
            }

            @media only screen and (max-width: 575px) {
                font-size: 20px;
            }
        }
    }


    .chatContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 56%;
        height: calc(100vh - 170px);
        margin: 0px 22%;
        border: 1px solid #DDDDDD;
        border-radius: 10px;

        @media only screen and (max-width: 1139px) {
            width: 76%;
            margin: 0px 12%;
            height: calc(100vh - 140px);
        }

        @media only screen and (max-width: 575px) {
            width: 100%;
            margin: 0px auto;
            height: calc(100vh - 140px);
        }

        .chatMessages {
            flex: 1;
            padding-left: 15px;
            padding-right: 15px;
            padding-bottom: 15px;
            padding-top: 5px;
            display: flex;
            flex-direction: column;
            background-color: transparent;
            overflow-y: auto;

            .messageItem {
                padding: 10px 0px 0px 0px;
                margin-top: 15px;
                width: 100%;
                background: rgba(235, 244, 248, 0.50);
                border-radius: 10px;
                -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.31);
                -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.31);
                box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.31);

                &.myItem {
                    background: rgba(255, 153, 51, 0.25);
                }

                .messageItemHeader {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0px 10px;

                    .mIUserName {
                        span {
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 500;
                            font-style: normal;
                            font-size: 16px;
                            line-height: 16px;
                        }
                    }

                    .mILikes {
                        display: flex;
                        align-items: flex-end;
                        justify-content: flex-end;

                        .mICount {
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 400;
                            font-style: normal;
                            font-size: 16px;
                            line-height: 16px;
                            margin-right: 10px;
                            color: #414141;
                        }

                        .mILikeIcon {
                            font-size: 12px;
                            line-height: 12px;
                            cursor: pointer;
                            color: #414141;

                            .likeIcon {
                                color: #414141;
                            }

                            &.Liked {
                                color: #138808;

                                .likeIcon {
                                    color: #138808;
                                }
                            }
                        }

                        .mIUserBanIcon {
                            font-size: 12px;
                            line-height: 12px;
                            cursor: pointer;
                            color: #414141;
                            margin-right: 10px;

                            &:hover {
                                color: #ff0000;
                            }

                            .userBanIcon {
                                color: #414141;

                                &:hover {
                                    color: #ff0000;
                                }
                            }

                            &.Liked {
                                color: #138808;

                                .likeIcon {
                                    color: #138808;
                                }
                            }
                        }

                        .mIFlagIcon {
                            font-size: 12px;
                            line-height: 12px;
                            cursor: pointer;
                            color: #414141;
                            margin-right: 10px;

                            &:hover {
                                color: #ff0000;
                            }

                            .flagIcon {
                                color: #414141;

                                &:hover {
                                    color: #ff0000;
                                }
                            }

                            &.Liked {
                                color: #138808;

                                .likeIcon {
                                    color: #138808;
                                }
                            }
                        }
                    }
                }

                .messageItemContent {
                    padding: 5px 10px;

                    p {
                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                        font-weight: 400;
                        font-style: normal;
                        font-size: 14px;
                        line-height: 16px;
                        margin: 0px;
                    }

                    img {
                        max-width: 75%;

                        @media only screen and (max-width: 575px) {
                            max-width: 100%;
                        }
                    }
                }

                .messageResponses {
                    padding: 0px 10px 10px 10px;

                    ul {
                        margin: 0px;
                        padding: 0px;
                        list-style: none;
                        list-style-type: none;

                        li {
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 400;
                            font-style: normal;
                            font-size: 14px;
                            line-height: 20px;
                            margin: 5px 0px;
                            padding: 5px 0px;
                            color: #414141;
                            cursor: pointer;

                            &:hover {
                                background-color:#EFEFEF;
                            }

                            &.commentItem {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                width: 100%;
                                flex: 1;

                                .mICommentContent {
                                    flex: 1;

                                    span {
                                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                                        font-weight: 500;
                                        font-size: 14px;
                                        line-height: 20px;
                                        color: #141414;
                                        margin-right: 10px;
                                        min-width: 100px;
                                        display: inline-block;
                                    }
        
                                    img {
                                        max-width: 75%;

                                        @media only screen and (max-width: 575px) {
                                            max-width: 100%;
                                        }
                                    }
                                }

                                .mIReportContent {
                                    display: inline-block;

                                    .mIUserBanIcon {
                                        font-size: 12px;
                                        line-height: 12px;
                                        cursor: pointer;
                                        color: #414141;
                                        margin-right: 10px;
            
                                        &:hover {
                                            color: #ff0000;
                                        }
            
                                        .userBanIcon {
                                            color: #414141;
            
                                            &:hover {
                                                color: #ff0000;
                                            }
                                        }
                                    }
            
                                    .mIFlagIcon {
                                        font-size: 12px;
                                        line-height: 12px;
                                        cursor: pointer;
                                        color: #414141;
                                        margin-right: 10px;
            
                                        &:hover {
                                            color: #ff0000;
                                        }
            
                                        .flagIcon {
                                            color: #414141;
            
                                            &:hover {
                                                color: #ff0000;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .messageReplyInput {
                    display: flex;
                    padding: 5px;
                    background: rgba(255, 255, 255, 0.30);
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;

                    .chatInputElement {
                        flex: 1;
                        background: rgba(255, 255, 255, 0.30);
                        padding: 5px 15px;
                        border: none;
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;
                        font-size: 14px;
                        line-height: 20px;
                        margin-bottom: 0px;
                    }

                    .SelectImage {
                        margin-left: 10px;
                        cursor: pointer;

                        .quillItIcon {
                            width: 32px;
                        }
                    }
                }
            }
        }

        .messageSubmit {
            display: flex;
            padding: 10px;
            border-top: 2px solid #DDDDDD;
            background: #FBFBFE;

            .chatInputElement {
                flex: 1;
                background: #ddd;
                padding: 5px 15px;
                border: none;
                border-radius: 10px;
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 0px;
            }

            .SelectImage {
                margin-left: 10px;
                cursor: pointer;

                .quillItIcon {
                    width: 40px;
                }
            }
        }
    }
}