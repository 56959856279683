.pageScreenContainer {
    .myPage {
        .myPageWrapper {
            &.AceGameReport {

                .pointsView {
                    .pointBoxWrapper {
                        padding-bottom: 25px;

                        &.noPadding {
                            padding-bottom: 0px;
                        }

                        .pointBox {
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            border-radius: 10px;
                            min-height: 160px;
                            -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                            -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                            box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);

                            &.White {
                                background-color: #FFFFFF;
                            }

                            &.LightBlue {
                                background-color: #C2E4EF;
                            }

                            &.LightOrange {
                                background-color: #EAD0B3;
                            }

                            &.LightGreen {
                                background-color: #A7BBA8;
                            }

                            &.LightYellow {
                                background-color: #FFF59D;
                            }

                            &.LightBrown {
                                background-color: #B37F6F;
                            }

                            h3 {
                                display: block;
                                margin: 0px;
                                font-family: "Mulish", Arial, Helvetica, sans-serif;
                                font-weight: 600;
                                font-style: normal;
                                font-size: 40px;
                                line-height: 54px;

                                @media only screen and (max-width: 1600px) {
                                    font-size: 32px;
                                    line-height: 40px;
                                }

                                @media only screen and (max-width: 1199px) {
                                    font-size: 24px;
                                    line-height: 40px;
                                }
                            }

                            .pbInfo {
                                display: block;
                                margin: 0px;
                                font-family: "Mulish", Arial, Helvetica, sans-serif;
                                font-weight: 400;
                                font-style: normal;
                                font-size: 26px;
                                line-height: 36px;

                                @media only screen and (max-width: 1600px) {
                                    font-size: 20px;
                                    line-height: 30px;
                                }

                                @media only screen and (max-width: 1199px) {
                                    font-size: 16px;
                                    line-height: 30px;
                                }
                            }

                            .pbSubInfo {
                                display: block;
                                margin: 0px;
                                font-family: "Mulish", Arial, Helvetica, sans-serif;
                                font-weight: 400;
                                font-style: normal;
                                font-size: 16px;
                                line-height: 20px;
                                position: absolute;
                                left: 10px;
                                bottom: 5px;

                                @media only screen and (max-width: 1600px) {
                                    font-size: 12px;
                                    line-height: 16px;
                                }
                            }
                        }
                    }

                    .accuracyChartView {
                        -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                        -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                        border-radius: 10px;
                        padding: 10px 15px;
                        position: relative;
                        text-align: center;

                        .gameName {
                            color: #000080;
                            margin: 0px;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 500;
                            font-style: normal;
                            font-size: 44px;
                            line-height: 70px;
                        }
                        .userName {
                            color: #000080;
                            margin: 0px;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 500;
                            font-style: normal;
                            font-size: 36px;
                            line-height: 60px;
                        }
                        .userCode {
                            color: #000080;
                            margin: 0px;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 500;
                            font-style: normal;
                            font-size: 26px;
                            line-height: 50px;
                        }
                        .address {
                            color: #414141;
                            margin: 0px;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 500;
                            font-style: normal;
                            font-size: 24px;
                            line-height: 40px;
                        }
                        .currentLevel {
                            color: #414141;
                            margin: 0px;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 500;
                            font-style: normal;
                            font-size: 24px;
                            line-height: 40px;
                        }
                        .totalEarnedPoints {
                            color: #414141;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 500;
                            font-style: normal;
                            font-size: 24px;
                            line-height: 40px;
                            margin: 0px;
                            margin-top: 20px;
                        }
                        .totalACEPoints {
                            color: #414141;
                            margin: 0px;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 500;
                            font-style: normal;
                            font-size: 24px;
                            line-height: 40px;
                        }
                    }
                }

                .questionsView {
                    
                    .questions {
                        display: grid;
                        grid-template-columns: 60px 60px 60px 60px 60px;
                        grid-template-rows: 60px 60px 60px 60px 60px;
                        justify-content: center;
                        align-content: center;
                        text-align: center;
                        padding-top: 1em;

                        @media only screen and (max-width:1440px) {
                            grid-template-columns: 50px 50px 50px 50px 50px;
                            grid-template-rows: 50px 50px 50px 50px 50px;
                            padding-top: 1em;
                        }

                        @media only screen and (max-width:1199px) {
                            grid-template-columns: 50px 50px 50px 50px 50px;
                            grid-template-rows: 50px 50px 50px 50px 50px;
                        }

                        @media only screen and (max-width:767px) {
                            grid-template-columns: 45px 45px 45px 45px 45px;
                            grid-template-rows: 45px 45px 45px 45px 45px;
                        }

                        span {
                            height: 44px;
                            width: 44px;
                            border-radius: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-weight: 500;
                            font-size: 26px;
                            line-height: 34px;
                            color: #FFFFFF;
                            align-self: center;
                            justify-self: center;

                            @media only screen and (max-width:1440px) {
                                height: 36px;
                                width: 36px;
                                font-size: 20px;
                                line-height: 30px;
                            }

                            @media only screen and (max-width:1199px) {
                                height: 34px;
                                width: 34px;
                                font-size: 20px;
                                line-height: 28px;
                            }

                            @media only screen and (max-width:767px) {
                                height: 30px;
                                width: 30px;
                                font-size: 18px;
                                line-height: 26px;
                            }

                            &.yellow {
                                background-color: #FF0000;
                            }

                            &.green {
                                background-color: #138808;
                            }

                            &.blue {
                                background-color: #FFBF00;
                            }

                            &.black {
                                background-color: #FFFFFF;
                                color: #000000;
                                border: 1px solid #000000
                            }
                        }
                    }

                    .accuracyChartView {
                        -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                        -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
                        border-radius: 10px;
                        padding: 10px 15px;
                        position: relative;
                        text-align: left;

                        .gameName {
                            color: #000080;
                            margin: 0px;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 500;
                            font-style: normal;
                            font-size: 30px;
                            line-height: 40px;
                        }
                        .yTLinks {
                            color: #000080;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 500;
                            cursor: pointer;
                            text-decoration: underline;
                        }
                    }
                }

                .examQuestionsListing {
                    .questionItem {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: flex-start;

                        .sequenceNumber {
                            color: #000080;
                            font-family: "Mulish", Arial, Helvetica, sans-serif;
                            font-weight: 500;
                            font-style: normal;
                            font-size: 20px;
                            line-height: 30px;
                            width: 30px;

                            p {
                                color: #000080;
                                font-family: "Mulish", Arial, Helvetica, sans-serif;
                                font-weight: 500;
                                font-style: normal;
                                font-size: 20px;
                                line-height: 30px;
                                margin: 0px 10px 0px 0px;
                            }
                        }

                        .questionContentWrapper {
                            flex: 1;

                            .questionContentDescription {
                                font-family: "Mulish", Arial, Helvetica, sans-serif;
                                font-weight: 400;
                                font-style: normal;
                                font-size: 20px;
                                line-height: 30px;
                                color: #141414;

                                p {
                                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                                    font-weight: 400;
                                    font-style: normal;
                                    font-size: 20px;
                                    line-height: 30px;
                                    color: #141414;
                                }
                            }

                            .questionContent {
                                font-family: "Mulish", Arial, Helvetica, sans-serif;
                                font-weight: 400;
                                font-style: normal;
                                font-size: 20px;
                                line-height: 30px;
                                color: #141414;

                                p {
                                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                                    font-weight: 400;
                                    font-style: normal;
                                    font-size: 20px;
                                    line-height: 30px;
                                    color: #141414;
                                }
                            }

                            .myAnswerView {
                                color: #414141;
                                font-family: "Mulish", Arial, Helvetica, sans-serif;
                                font-weight: 500;
                                font-style: normal;
                                font-size: 20px;
                                line-height: 30px;
                                display: block;

                                .myContent {
                                    color: #141414;
                                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                                    font-weight: 400;
                                    font-style: normal;
                                    font-size: 20px;
                                    line-height: 30px;
                                    display: inline;

                                    p {
                                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                                        font-weight: 400;
                                        font-style: normal;
                                        font-size: 20px;
                                        line-height: 30px;
                                        color: #141414;
                                    }
                                }

                                &.Wrong {
                                    color: #FF431C;

                                    .myContent {
                                        color: #FF431C;
                                    }
                                }

                                &.Correct {
                                    color: #138808;

                                    .myContent {
                                        color: #138808;
                                    }
                                }
                            }

                            .correctAnswerView {
                                color: #414141;
                                font-family: "Mulish", Arial, Helvetica, sans-serif;
                                font-weight: 500;
                                font-style: normal;
                                font-size: 20px;
                                line-height: 30px;
                                display: block;

                                .myContent {
                                    color: #141414;
                                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                                    font-weight: 400;
                                    font-style: normal;
                                    font-size: 20px;
                                    line-height: 30px;
                                    display: inline;

                                    p {
                                        font-family: "Mulish", Arial, Helvetica, sans-serif;
                                        font-weight: 400;
                                        font-style: normal;
                                        font-size: 20px;
                                        line-height: 30px;
                                        color: #141414;
                                    }
                                }

                                &.Wrong {
                                    color: #FF431C;

                                    .myContent {
                                        color: #FF431C;
                                    }
                                }

                                &.Correct {
                                    color: #138808;

                                    .myContent {
                                        color: #138808;
                                    }
                                }
                            }

                            .justificationView {
                                color: #000080;
                                font-family: "Mulish", Arial, Helvetica, sans-serif;
                                font-weight: 500;
                                font-style: normal;
                                font-size: 20px;
                                line-height: 30px;
                            }

                            .questionContentJustification {
                                font-family: "Mulish", Arial, Helvetica, sans-serif;
                                font-weight: 400;
                                font-style: normal;
                                font-size: 20px;
                                line-height: 30px;
                                color: #141414;

                                p {
                                    font-family: "Mulish", Arial, Helvetica, sans-serif;
                                    font-weight: 400;
                                    font-style: normal;
                                    font-size: 20px;
                                    line-height: 30px;
                                    color: #141414;
                                }
                            }
                        }
                    }
                }

                .accuracyRateCalculations {}
            }
        }
    }
}